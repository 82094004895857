import { auth } from "../../firebase/credenciales";
import { signInWithPopup, signOut } from "firebase/auth";
import { GoogleAuthProvider } from "firebase/auth";

export async function signUpWithGoogle(setShowSignInLoader) {
  try {
    const provider = new GoogleAuthProvider();
    await signInWithPopup(auth, provider);
    setShowSignInLoader(false);
  } catch (error) {
    console.log(error);
  }
}

export async function logOut() {
  //console.log("logOut");
  try {
    await signOut(auth);
    //console.log(auth);
  } catch (error) {
    console.log(error);
  }
}

export const NEWS_STATIC_DATA = {
  quick_access: {
    options: [
      {
        title: "Projects ending soon",
        description: "Crowdfunding Countdown",
        link: "https://boardgamegeek.com/crowdfunding",
      },
      {
        title: "Hot Rankings and Tiers",
        description: "GeekLists",
        link: "https://boardgamegeek.com/geeklists?sort=hot&interval=twodays",
      },
      {
        title: "Reviews and Opinion",
        description: "Hot In-Depth Reviews",
        link: "https://boardgamegeek.com/geeklists?sort=hot&interval=twodays",
      },
      {
        title: "Recent game releases.",
        description: "New Releases",
        link: "https://boardgamegeek.com/geeklists?sort=hot&interval=twodays",
      },
      {
        title: "",
        description: "Hot Videos",
        link: "https://boardgamegeek.com/videos/boardgame/all?sort=hot&interval=last7",
      },
      {
        title: "",
        description: "Hot Images",
        link: "https://boardgamegeek.com/images/boardgame/all?sort=hot&interval=today",
      },
    ],
  },
  forum: {
    threads: [
      {
        title: "Contest Subscription Thread",
        author: "Andrew Tullsen",
        link: "https://boardgamegeek.com/thread/266429/contest-subscription-thread",
      },
      {
        title: "July BGG Store Update - Artist Series 8 on sale NOW!",
        author: "Beth Heile",
        link: "https://boardgamegeek.com/thread/3117641/july-bgg-store-update-artist-series-8-sale-now",
      },
    ],
  },
};

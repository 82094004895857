import { updateDoc, doc } from "firebase/firestore";
import { db } from "../../firebase/credenciales";

export const updateShareLinkWishlistInFirebase = async (userObj) => {

  let docId = userObj.key_firebase;
  const user = doc(db, "users", docId);
  await updateDoc(user, {
    wishlist: userObj.wishlist,
  });

  return true;
};

export const updateShareLinkGamesWishlistInFirebase = async (userObj) => {
  let docId = userObj.key_firebase;
  const user = doc(db, "users", docId);
  await updateDoc(user, {
    wishlist: userObj.wishlist,
  });

  return true;
};

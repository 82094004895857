
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyD3b-LLpgvMDvTizbPWaNNDfNkO1-xifa8",
  authDomain: "citadejuegos-7cdbd.firebaseapp.com",
  projectId: "citadejuegos-7cdbd",
  storageBucket: "citadejuegos-7cdbd.appspot.com",
  messagingSenderId: "511327435980",
  appId: "1:511327435980:web:fead8a09240475e5989669",
};

const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);
export const db = getFirestore(firebaseApp);
export const storage = getStorage(firebaseApp);
// eslint-disable-next-line no-unused-vars
export default firebaseApp;

import { IoHeart } from "react-icons/io5";
import {
  MdDashboard,
  MdExtension,
  MdFilterAlt,
  MdFilterAltOff,
  MdRemoveDone,
} from "react-icons/md";
import Widget from "components/widget/Widget";
import GameCard from "components/card/GameCard";
import { useEffect, useState } from "react";
import Banner from "../marketplace/components/Banner";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { IoIosTimer } from "react-icons/io";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { FiCopy, FiEye } from "react-icons/fi";
import FilterButton from "./components/FilterButton";
import FilterChip from "./components/FilterChip";
import {
  getTimingString,
  getMostRecentDateString,
  getDateString,
} from "utils/functions/utils";
import useTranslation from "utils/hooks/useTranslation";
import {
  DATES_TRANSLATION_ARRAY,
  DEFAULT_USE_AUTO_ANIMATE,
} from "utils/constants/constants";
import { updateShareLinkCollectionInFirebase } from "utils/firebase-services/collectionService";
import { AddGames } from "./components/AddGames";

const Dashboard = () => {
  const { translation } = useTranslation();
  const [animationParent] = useAutoAnimate(DEFAULT_USE_AUTO_ANIMATE);
  const paginationBreakPoint = 40;
  const {
    ownedGames,
    wishListGames,
    horasJugadas,
    juegosJugados,
    ownedExpansions,
  } = useSelector((state: RootState) => state.gamesSlice);
  const { userName } = useSelector((state: RootState) => state.userSlice);
  const {
    collectionMechanics,
    collectionCategories,
    collectionComplexity,
    collectionPlayers,
    collectionTimes,
    collectionDates,
  } = useSelector((state: RootState) => state.collectionSlice);

  const { currentGoogleUser, isSavingWishlistData } = useSelector(
    (state: RootState) => state.userSlice
  );

  const [horasTotales, setHorasTotales] = useState(0);
  const [partidasTotales, setPartidasTotales] = useState(0);
  const [expansionsTotales, setExpansionsTotales] = useState(0);

  const [showMechanics, setShowMechanics] = useState(false);
  const [showCategories, setShowCategories] = useState(false);
  const [showComplexity, setShowComplexity] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [showPlayers, setShowPlayers] = useState(false);
  const [showTimes, setShowTimes] = useState(false);
  const [showDates, setShowDates] = useState(false);

  const [gameCards, setGameCards] = useState(ownedGames);

  const [complexityFilter, setComplexityFilter] = useState([]);
  const [mechanicFilter, setMechanicFilter] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState([]);
  const [playerFilter, setPlayerFilter] = useState([]);
  const [timesFilter, setTimesFilter] = useState([]);
  const [datesFilter, setDatesFilter] = useState([]);
  const [paginationOptions, setPaginationOptions] = useState([]);
  const [pagination, setPagination] = useState(1);
  const [expansionFilter, setExpansionFilter] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(
    translation.getLanguage()
  );
  const [shareLink, setShareLink] = useState("");
  const [currentWishlistGames, setCurrentWishlistGames] = useState([]);

  const [showSavingWishlistLoader, setShowSavingWishlistLoader] =
    useState(isSavingWishlistData);

  useEffect(() => {
    setShowSavingWishlistLoader(isSavingWishlistData);
  }, [isSavingWishlistData]);

  useEffect(() => {
    setCurrentWishlistGames(wishListGames);
  }, [wishListGames]);

  useEffect(() => {
    setCurrentLanguage(translation.getLanguage());
  }, [translation]);

  useEffect(() => {
    if (ownedGames.length > 0) {
      if (currentGoogleUser) {
        const auxGoogleUser = JSON.parse(JSON.stringify(currentGoogleUser));
        if (auxGoogleUser.collection === undefined)
          auxGoogleUser.collection = { games: [], lastModified: 0 };
        auxGoogleUser.collection.games = ownedGames;
        auxGoogleUser.collection.lastModified = new Date().getTime();
        updateShareLinkCollectionInFirebase(auxGoogleUser);
        setShareLink(
          "https://mibox.citadejuegos.com/features/my-collection?key=" +
            auxGoogleUser.key_firebase
        );
      }
      setGameCards(ownedGames);
    }
  }, [currentGoogleUser, ownedGames]);

  useEffect(() => {
    setPaginationOptions(
      [...Array(Math.ceil(gameCards.length / paginationBreakPoint)).keys()].map(
        (i) => i + 1
      )
    );
    setPagination(1);
  }, [gameCards]);
  useEffect(() => {
    setPartidasTotales(juegosJugados);
  }, [partidasTotales, juegosJugados]);
  useEffect(() => {
    setExpansionsTotales(ownedExpansions);
  }, [expansionsTotales, ownedExpansions]);
  useEffect(() => {
    setHorasTotales(horasJugadas);
  }, [horasJugadas, horasTotales]);

  useEffect(() => {
    if (
      mechanicFilter.length === 0 &&
      categoryFilter.length === 0 &&
      complexityFilter.length === 0 &&
      playerFilter.length === 0 &&
      timesFilter.length === 0 &&
      datesFilter.length === 0 &&
      !expansionFilter
    ) {
      setGameCards(ownedGames);
    } else {
      let newMechanicFilter = [...mechanicFilter];
      let newCategoryFilter = [...categoryFilter];
      let newComplexityFilter = [...complexityFilter];
      let newPlayerFilter = [...playerFilter];
      let newTimesFilter = [...timesFilter];
      let newDatesFilter = [...datesFilter];

      let newGameCards = JSON.parse(JSON.stringify(ownedGames));
      if (newMechanicFilter.length > 0) {
        newGameCards = ownedGames.filter((g) =>
          newMechanicFilter.some((r) => g.mechanics.indexOf(r) >= 0)
        );
      }

      if (newCategoryFilter.length > 0)
        newGameCards = newGameCards.filter((g) =>
          newCategoryFilter.some((r) => g.categories.indexOf(r) >= 0)
        );
      if (newComplexityFilter.length > 0)
        newGameCards = newGameCards.filter((g) =>
          newComplexityFilter.includes("" + g.averageweight)
        );
      if (newPlayerFilter.length > 0)
        newGameCards = newGameCards.filter((g) => {
          let found = [];
          newPlayerFilter.forEach((p) => {
            found.push(g.minPlayers / 1 <= p && g.maxPlayers / 1 >= p);
          });
          return !found.includes(false);
        });
      if (newTimesFilter.length > 0)
        newGameCards = newGameCards.filter((g) => {
          return newTimesFilter.includes(getTimingString(g.playingTime));
        });
      if (newDatesFilter.length > 0)
        newGameCards = newGameCards.filter((g) => {
          if (!g.dates) return false;
          if (g.dates.length === 0)
            return newDatesFilter.includes(
              DATES_TRANSLATION_ARRAY[currentLanguage].never
            );
          if (g.dates.length > 0)
            return newDatesFilter.includes(
              getDateString(
                new Date(g.dates[0]),
                DATES_TRANSLATION_ARRAY[currentLanguage].oneWeek,
                DATES_TRANSLATION_ARRAY[currentLanguage].oneMonth,
                DATES_TRANSLATION_ARRAY[currentLanguage].threeMonths,
                DATES_TRANSLATION_ARRAY[currentLanguage].sixMonthsOrMore
              )
            );

          return false;
        });

      if (expansionFilter) {
        newGameCards = newGameCards.filter((g) => {
          return !g.isExpansion;
        });
      }
      setGameCards(newGameCards);
    }
  }, [
    categoryFilter,
    complexityFilter,
    currentLanguage,
    datesFilter,
    expansionFilter,
    mechanicFilter,
    ownedGames,
    playerFilter,
    timesFilter,
  ]);

  const getFilterCollectionFunctionShowToUse = (filter) => {
    let filterToUse,
      collectionToUse,
      setFunctionToUse,
      showToUse,
      setShowFunctionToUse;
    switch (filter) {
      case "complexity":
        filterToUse = complexityFilter;
        collectionToUse = collectionComplexity;
        setFunctionToUse = setComplexityFilter;
        showToUse = showComplexity;
        setShowFunctionToUse = setShowComplexity;
        break;
      case "players":
        filterToUse = playerFilter;
        collectionToUse = collectionPlayers;
        setFunctionToUse = setPlayerFilter;
        showToUse = showPlayers;
        setShowFunctionToUse = setShowPlayers;
        break;
      case "times":
        filterToUse = timesFilter;
        collectionToUse = collectionTimes;
        setFunctionToUse = setTimesFilter;
        showToUse = showTimes;
        setShowFunctionToUse = setShowTimes;
        break;
      case "dates":
        filterToUse = datesFilter;
        collectionToUse = collectionDates;
        setFunctionToUse = setDatesFilter;
        showToUse = showDates;
        setShowFunctionToUse = setShowDates;
        break;
      case "categories":
        filterToUse = categoryFilter;
        collectionToUse = collectionCategories;
        setFunctionToUse = setCategoryFilter;
        showToUse = showCategories;
        setShowFunctionToUse = setShowCategories;
        break;
      case "mechanics":
        filterToUse = mechanicFilter;
        collectionToUse = collectionMechanics;
        setFunctionToUse = setMechanicFilter;
        showToUse = showMechanics;
        setShowFunctionToUse = setShowMechanics;
        break;
      default:
        break;
    }

    return {
      filterToUse,
      collectionToUse,
      setFunctionToUse,
      showToUse,
      setShowFunctionToUse,
    };
  };

  const revealChips = (filter) => {
    hideAll();
    let { showToUse, setShowFunctionToUse } =
      getFilterCollectionFunctionShowToUse(filter);
    setShowFunctionToUse(!showToUse);
  };
  const hideAll = () => {
    setShowMechanics(false);
    setShowCategories(false);
    setShowComplexity(false);
    setShowPlayers(false);
    setShowTimes(false);
    setShowDates(false);
  };
  const cleanFilters = () => {
    hideAll();
    setComplexityFilter([]);
    setMechanicFilter([]);
    setCategoryFilter([]);
    setPlayerFilter([]);
    setTimesFilter([]);
    setDatesFilter([]);
    setGameCards(ownedGames);
    setExpansionFilter(false);
  };

  const editFilter = (event, selectedFilter) => {
    if (selectedFilter === "expansions") {
      setExpansionFilter(!expansionFilter);
    } else {
      let valueToCheck = event.target.name; //complexity = event.target.name;
      let newFilter = [];
      let { filterToUse, setFunctionToUse } =
        getFilterCollectionFunctionShowToUse(selectedFilter);
      filterToUse.includes(valueToCheck)
        ? (newFilter = filterToUse.filter((c: any) => c !== valueToCheck))
        : (newFilter = [...filterToUse, valueToCheck]);
      setFunctionToUse(newFilter);
    }
  };

  const renderChips = (selectedFilter) => {
    let { filterToUse, collectionToUse } =
      getFilterCollectionFunctionShowToUse(selectedFilter);
    const chipsItems = collectionToUse.map((g, index) => (
      <FilterChip
        showCount={selectedFilter === "dates" ? false : true}
        key={selectedFilter + index}
        count={g.count}
        name={g.name}
        id={selectedFilter === "complexity" ? g.complexity : g.name}
        validation={
          selectedFilter === "complexity"
            ? filterToUse.includes(g.complexity + "")
            : filterToUse.includes(g.name + "")
        }
        editFilter={(event) => editFilter(event, selectedFilter)}
        suffix={selectedFilter === "players" ? translation.playerOrEs : ""}
      />
    ));
    return (
      <div
        className="grid grid-cols-2 gap-5 sm:grid-cols-2 md:grid-cols-5 lg:grid-cols-5 xl:grid-cols-5"
        ref={animationParent}
      >
        {chipsItems}
      </div>
    );
  };
  const renderGameCard = () => {
    const auxGameCards = [...gameCards];
    const gameItems = auxGameCards
      .slice(
        (pagination - 1) * paginationBreakPoint,
        paginationBreakPoint + (pagination - 1) * paginationBreakPoint
      )
      .map((g, index) => (
        <GameCard
          gameId={g.gameId}
          custom={g.custom}
          plays={g.plays}
          horas={g.horas}
          dates={g.dates ? getMostRecentDateString(g.dates) : ""}
          linkOnClick={"https://boardgamegeek.com/boardgame/" + g.gameId}
          key={g.gameId + "-" + index}
          minPlayers={g.minPlayers}
          maxPlayers={g.maxPlayers}
          rank={g.rank}
          isExpansion={g.isExpansion}
          rating={g.averageRating}
          myRating={g.rating}
          title={g.name}
          author={g.yearpublished}
          image={g.image}
          estimatedTime={g.playingTime}
        />
      ));
    return <>{gameItems}</>;
  };

  return (
    <div className="flex w-full flex-col gap-5">
      <AddGames />
      <div className=" grid grid-cols-2 gap-2 md:grid-cols-4 lg:grid-cols-4 2xl:grid-cols-4 3xl:grid-cols-4">
        <Widget
          showSavingWishlistLoader={showSavingWishlistLoader}
          icon={<MdDashboard className="h-7 w-7" />}
          title={translation.ownedGames}
          subtitle={ownedGames.length > 0 ? "" + ownedGames.length : "-"}
        />
        <Widget
          showSavingWishlistLoader={showSavingWishlistLoader}
          icon={<IoHeart className="h-6 w-6" />}
          title={translation.wishlist}
          subtitle={
            currentWishlistGames.length > 0
              ? "" + currentWishlistGames.length
              : "-"
          }
        />
        <Widget
          showSavingWishlistLoader={showSavingWishlistLoader}
          icon={<MdExtension className="h-7 w-7" />}
          title={translation.totalPlays}
          subtitle={partidasTotales === 0 ? "-" : partidasTotales + ""}
        />
        <Widget
          showSavingWishlistLoader={showSavingWishlistLoader}
          icon={<IoIosTimer className="h-6 w-6" />}
          title={translation.hoursPlayed}
          subtitle={
            horasTotales === 0 ? "-" : "" + (horasTotales / 60).toFixed(0)
          }
        />
      </div>

      <div className="grid h-full grid-cols-1 gap-5 xl:grid-cols-2 2xl:grid-cols-6">
        <div
          className="col-span-5 h-fit w-full xl:col-span-5 2xl:col-span-6"
          ref={animationParent}
        >
          <div className=" grid grid-cols-7 items-start justify-start">
            <h4
              className="col-span-5 items-center text-2xl font-bold text-navy-700 dark:text-white md:col-span-4"
              ref={animationParent}
            >
              {currentGoogleUser && (
                <>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={shareLink}
                    className="text-base font-medium text-white"
                  >
                    <FiEye className="inline h-5 w-5 text-white hover:text-navy-200" />
                  </a>
                  <FiCopy
                    onClick={() => {
                      navigator.clipboard.writeText(shareLink);
                    }}
                    className="dark:hover:text-grey-400 ml-3 inline h-5 w-5 text-white hover:cursor-pointer hover:text-navy-200 active:bg-brand-700"
                  />
                </>
              )}{" "}
              {(translation.getLanguage() === "es" ||
                translation.getLanguage() === "fr") &&
                translation.collection}{" "}
              {userName}{" "}
              {(translation.getLanguage() === "en" ||
                translation.getLanguage() === "en-can") &&
                translation.collection}{" "}
            </h4>
            <div
              className="col-span-1 h-fit w-full md:col-span-1"
              ref={animationParent}
            >
              <button
                key="showHideFilters"
                ref={animationParent}
                className="ml-6 rounded-full bg-red-500 p-2 text-sm font-bold text-white transition duration-200 hover:bg-red-600"
                onClick={() => setShowFilters(!showFilters)}
              >
                {showFilters ? <MdFilterAltOff /> : <MdFilterAlt />}
              </button>
              {(mechanicFilter.length > 0 ||
                categoryFilter.length > 0 ||
                complexityFilter.length > 0 ||
                playerFilter.length > 0 ||
                timesFilter.length > 0 ||
                datesFilter.length > 0 ||
                expansionFilter) && (
                <button
                  key="cleanFilterButton"
                  ref={animationParent}
                  className="ml-6 rounded-full bg-brand-500 p-2 text-sm font-bold text-white transition duration-200 hover:bg-red-600"
                  onClick={cleanFilters}
                >
                  <MdRemoveDone />
                </button>
              )}
            </div>
            <div
              className="col-span-7 h-fit w-full md:col-span-4"
              ref={animationParent}
            >
              {ownedGames.length > paginationBreakPoint &&
                paginationOptions.map((option, index) => (
                  <button
                    key={index}
                    ref={animationParent}
                    className={
                      "mb-2 ml-6 h-7 w-7 rounded-full  text-sm font-bold text-white transition duration-200 " +
                      (option === pagination ? "bg-brand-500" : "bg-red-500")
                    }
                    onClick={() => setPagination(option)}
                  >
                    {option}
                  </button>
                ))}
            </div>
          </div>

          {showFilters && (
            <div
              className="sticky top-1 z-40 mt-1 flex flex-col items-center justify-center gap-3 rounded-t-lg p-2 backdrop-blur-xl dark:bg-[#0b14374d]"
              ref={animationParent}
            >
              <div
                className="z-40 mt-1 grid w-full grid-cols-2 gap-3 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-7 xl:grid-cols-7"
                ref={animationParent}
              >
                <FilterButton
                  count={playerFilter.length}
                  title={translation.players}
                  collection={collectionPlayers}
                  animationParent={animationParent}
                  revealSection={() => revealChips("players")}
                  filter={playerFilter}
                />
                <FilterButton
                  count={mechanicFilter.length}
                  title={translation.mechanics}
                  collection={collectionMechanics}
                  animationParent={animationParent}
                  revealSection={() => revealChips("mechanics")}
                  filter={mechanicFilter}
                />
                <FilterButton
                  count={categoryFilter.length}
                  title={translation.categories}
                  collection={collectionCategories}
                  animationParent={animationParent}
                  revealSection={() => revealChips("categories")}
                  filter={categoryFilter}
                />
                <FilterButton
                  count={complexityFilter.length}
                  title={translation.complexity}
                  collection={collectionComplexity}
                  animationParent={animationParent}
                  revealSection={() => revealChips("complexity")}
                  filter={complexityFilter}
                />
                <FilterButton
                  count={timesFilter.length}
                  title={translation.playTime}
                  collection={collectionTimes}
                  animationParent={animationParent}
                  revealSection={() => revealChips("times")}
                  filter={timesFilter}
                />
                {ownedGames.length > 0 && (
                  <FilterButton
                    count={datesFilter.length}
                    title={translation.lastPlayed}
                    collection={collectionDates}
                    animationParent={animationParent}
                    revealSection={() => revealChips("dates")}
                    filter={datesFilter}
                  />
                )}
                {ownedGames.length > 0 && (
                  <FilterButton
                    count={expansionsTotales}
                    title={translation.expansions}
                    collection={collectionDates}
                    animationParent={animationParent}
                    revealSection={() => setExpansionFilter(!expansionFilter)}
                    filter={expansionFilter}
                  />
                )}
              </div>

              <div
                className="z-40 mb-2 grid w-full items-center justify-center"
                ref={animationParent}
              >
                {showMechanics && renderChips("mechanics")}
                {showCategories && renderChips("categories")}
                {showComplexity && renderChips("complexity")}
                {showPlayers && renderChips("players")}
                {showTimes && renderChips("times")}
                {showDates && renderChips("dates")}
              </div>
            </div>
          )}

          <div
            className="mt-5 grid grid-cols-2 gap-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 2xl:grid-cols-7"
            ref={animationParent}
          >
            {renderGameCard()}
          </div>
        </div>
      </div>

      <div className="flex h-fit w-full flex-col gap-5 lg:grid lg:grid-cols-6">
        <div className="col-span-6 flex h-fit w-full flex-col gap-5 lg:grid lg:grid-cols-6">
          <div className="col-span-6 lg:!mb-0">
            <Banner showButtons={false} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

import { useAutoAnimate } from "@formkit/auto-animate/react";
import Card from "components/card";
import InputField from "components/fields/InputField";
import React, { useEffect, useState } from "react";
import { FiMinus, FiPlus, FiSearch } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { resetAllGameData } from "slices/collectionSlice";
import { resetAllData } from "slices/gamesSlice";
import { changeUserName, setIsLoadingData } from "slices/userSlice";
import { RootState } from "store/store";
import { DEFAULT_USE_AUTO_ANIMATE } from "utils/constants/constants";
import { updateBggUserName } from "utils/firebase-services/userService";
import { SyncData } from "utils/hooks/syncData";
import useTranslation from "utils/hooks/useTranslation";
import allGamesJsonData from "../../../../catalog/allGames.json";
import { findGamesIncludingSearchName } from "utils/functions/utils";
import { Link } from "react-router-dom";

export const AddGames = () => {
  const { userName, isLoadingData } = useSelector(
    (state: RootState) => state.userSlice
  );
  const { currentGoogleUser } = useSelector(
    (state: RootState) => state.userSlice
  );
  const allGamesResults = allGamesJsonData as Array<any>;
  const dispatch = useDispatch();

  const {
    syncAll,
    setGamesInformationToStoredData,
    addGameToCollectionOrWishlist,
  } = SyncData();

  const { translation } = useTranslation();
  const [animationParent] = useAutoAnimate(DEFAULT_USE_AUTO_ANIMATE);

  const [canSearchGames, setCanSearchGames] = useState(true);
  const [auxUserName, setAuxUserName] = useState(userName);
  const [showLoaderBgg, setShowLoaderBgg] = useState(false);
  const [shouldUpdateGames, setShouldUpdateGames] = useState(true);
  const [showAddGames, setShowAddGames] = useState(false);
  const [gamesFoundForAdding, setGamesFoundForAdding] = useState([]);
  const [addToSelection, setAddToSelection] = useState("collection");
  const [debouncedAddInputValue, setDebouncedAddInputValue] = useState("");
  const [showLoaderAddGames, setShowLoaderAddGames] = useState(false);

  const [searchName, setSearchName] = useState("");

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      setDebouncedAddInputValue(searchName);
    }, 500);
    return () => clearTimeout(delayInputTimeoutId);
  }, [searchName]);
  useEffect(() => {
    if (debouncedAddInputValue.length > 2) {
      setShowLoaderAddGames(true);
      const gamesFound = findGamesIncludingSearchName(
        allGamesResults,
        debouncedAddInputValue
      );
      setTimeout(() => {
        gamesFound.sort((a, b) => {
          return a.name.length - b.name.length;
        });
        setGamesFoundForAdding(gamesFound.slice(0, 6));
        setShowLoaderAddGames(false);
      }, 500);
    } else {
      setGamesFoundForAdding([]);
    }
  }, [allGamesResults, debouncedAddInputValue]);
  const onChangedText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAuxUserName(e.target.value);
  };
  const onChangedSearchName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchName(e.target.value);
  };
  useEffect(() => {
    if (isLoadingData) {
      setShowLoaderBgg(true);
    } else {
      setTimeout(() => {
        setShowLoaderBgg(false);
      }, 2000);
    }
  }, [isLoadingData]);

  useEffect(() => {
    if (shouldUpdateGames) {
      if (
        localStorage.getItem("storedOwnedGames") &&
        localStorage.getItem("storedOwnedGames") !== "[]"
      ) {
        setShouldUpdateGames(false);
        setGamesInformationToStoredData();
        setCanSearchGames(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldUpdateGames]);

  const changeSearchUsername = () => {
    if (auxUserName.length > 3 && auxUserName.length < 21) {
      setShowLoaderBgg(true);
      dispatch(setIsLoadingData(true));
      dispatch(resetAllGameData([]));
      if (auxUserName !== userName) {
        localStorage.setItem("bgg_username", auxUserName);
        if (currentGoogleUser) {
          updateBggUserName(auxUserName, currentGoogleUser);
        }
        dispatch(resetAllData([]));
        dispatch(changeUserName(auxUserName));
        localStorage.setItem("storedOwnedGames", "[]");
        localStorage.setItem("storedWishlistGames", "[]");
      }
      syncAll(
        auxUserName,
        setShowLoaderBgg,
        currentGoogleUser
      );
    } else {
      alert(translation.enterAValidBGGUsername);
    }
  };
  const addSelectedGame = async (game) => {
    const answer = window.confirm(
      translation.areYouSureYouWantToAddThisGame + addToSelection + "?"
    );
    if (answer) {
      setSearchName("");
      setGamesFoundForAdding([]);
      setShowLoaderAddGames(true);

      const resultAddGame = await addGameToCollectionOrWishlist(
        game,
        addToSelection
      );
      setShowLoaderAddGames(false);
      if (resultAddGame) {
        alert(
          "Error adding game to your " +
            addToSelection +
            ". " +
            resultAddGame.message
        );
      } else {
        alert(
          translation.gameAddedToCollection +
            addToSelection +
            translation.successfully
        );
      }
    }
  };
  const renderGamesFoundForAdding = () => {
    return gamesFoundForAdding.map((game) => {
      return (
        <div
          ref={animationParent}
          className="col-span-1 flex w-full flex-col justify-center p-1"
          key={game.id}
        >
          <div className="flex flex-row items-center p-2">
            <button
              className="mr-2 flex justify-center rounded-xl bg-brand-400 p-2 text-base font-medium text-white transition duration-200 hover:bg-brand-300 active:bg-brand-200 disabled:cursor-not-allowed disabled:bg-gray-400"
              onClick={() => addSelectedGame(game)}
            >
              <FiPlus className="h-4 w-4 " />
            </button>
            <p className="text-white">
              {game.name.substring(0, 60)}
              {game.name.length > 60 && "..."} ({game.yearpublished})
            </p>
          </div>
        </div>
      );
    });
  };
  return (
    <div
      className="z-1 static mt-2 grid grid-cols-2 gap-2 md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2 3xl:grid-cols-2"
      ref={animationParent}
    >
      <p className="col-span-2 flex flex-row font-dm text-2xl font-bold text-white">
        {translation.addGamesToYourCollection}{" "}
        {showAddGames && (
          <FiMinus
            className="ml-2 h-8 w-8 cursor-pointer text-brand-500"
            onClick={() => setShowAddGames(!showAddGames)}
          />
        )}
        {!showAddGames && (
          <FiPlus
            className="ml-2 h-8 w-8 cursor-pointer text-brand-500"
            onClick={() => setShowAddGames(!showAddGames)}
          />
        )}
      </p>
      {showAddGames && (
        <>
          <Card extra="!flex-row flex-grow items-center rounded-[20px] col-span-2 p-2 md:col-span-1">
            <div className=" flex w-full flex-col justify-center">
              <div className="grid w-full grid-cols-2 gap-2 md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2 3xl:grid-cols-2">
                <div className="col-span-2 ml-2 flex w-auto flex-col justify-center">
                  <h4 className="text-lg font-bold text-white">
                    {translation.importYour}
                    <a
                      href="https://boardgamegeek.com/"
                      rel="noreferrer"
                      target="_blank"
                      className="cursor-pointer text-branded-900 hover:text-branded-800"
                    >
                      BGG
                    </a>{" "}
                    {translation.collectionAndWishlist}
                  </h4>
                </div>

                <div className="col-span-2 flex w-full flex-col justify-center p-2">
                  <div className="grid grid-cols-12 items-end">
                    <div className="col-span-10">
                      <InputField
                        value={auxUserName}
                        onChange={onChangedText}
                        variant="auth"
                        extra="mb-3"
                        label={translation.enterYourBGGUsername}
                        placeholder="e.g. Citadejuegos"
                        id="bggSearch"
                        type="text"
                      />
                    </div>
                    <div className="col-span-1">
                      <button
                        disabled={!canSearchGames}
                        className="mb-4 ml-2 flex justify-center rounded-xl bg-brand-500 p-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 disabled:cursor-not-allowed disabled:bg-gray-400 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                        onClick={changeSearchUsername}
                      >
                        <FiSearch className="h-5 w-5 " />
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col-span-2 flex w-auto flex-col justify-center">
                  {showLoaderBgg && (
                    <div
                      ref={animationParent}
                      className="flex w-full items-center justify-center"
                    >
                      <l-reuleaux
                        size="20"
                        stroke="5"
                        stroke-length="0.15"
                        bg-opacity="0.1"
                        speed="1.2"
                        color="orange"
                      ></l-reuleaux>
                      <div className="ml-5 h-full font-poppins font-bold dark:text-white">
                        <span className="font-xs">
                          {translation.loadingMessage}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Card>
          <Card extra="!flex-row flex-grow items-center rounded-[20px] col-span-2 p-2 md:col-span-1">
            <div className=" flex w-full flex-col justify-center">
              {currentGoogleUser ? (
                <div
                  ref={animationParent}
                  className="grid w-full grid-cols-2 gap-2 md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2 3xl:grid-cols-2"
                >
                  <div className="col-span-1 ml-2 flex w-auto flex-col justify-center">
                    <h4 className="text-xl font-bold text-white">
                      {translation.addGamesByName}
                    </h4>
                  </div>
                  <div className="col-span-1 ml-2 flex w-auto flex-col justify-center">
                    <select
                      onChange={(e) => {
                        setAddToSelection(e.target.value);
                      }}
                      value={addToSelection}
                      className="text-baseline mr-2 flex items-center justify-center font-bold hover:cursor-pointer dark:!bg-navy-800 dark:text-brand-500"
                    >
                      <option value="collection">
                        {translation.addToMyCollection}
                      </option>
                      <option value="wishlist">
                        {translation.addToMyWishlist}
                      </option>
                    </select>
                  </div>

                  <div className="col-span-2 flex w-full flex-col justify-center p-2">
                    <div className="grid grid-cols-12 items-end">
                      <div className="col-span-10">
                        <InputField
                          value={searchName}
                          onChange={onChangedSearchName}
                          variant="auth"
                          extra="mb-3"
                          label={translation.enterAtleastThreeCharacters}
                          placeholder="e.g. Wyrmspan (2024)"
                          id="librarySearch"
                          type="text"
                        />
                      </div>
                      <div className="col-span-2 mb-2 ml-2">
                        {showLoaderAddGames && (
                          <l-reuleaux
                            size="35"
                            stroke="5"
                            stroke-length="0.15"
                            bg-opacity="0.1"
                            speed="1.2"
                            color="orange"
                          ></l-reuleaux>
                        )}
                      </div>
                    </div>
                  </div>

                  {renderGamesFoundForAdding()}
                </div>
              ) : (
                <div className="col-span-6 mt-5 flex w-full max-w-full flex-col items-center">
                  <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
                    {translation.oops}
                  </h4>
                  <p className="mb-5 ml-1 text-base text-gray-600">
                    {
                      translation.toAddGamesManuallyToYourCollectionYouNeedToBeLoggedIn
                    }
                  </p>
                  <Link
                    className="flex h-[50px] w-[100px] items-center justify-center gap-2 rounded-xl text-white hover:cursor-pointer hover:bg-branded-900 dark:bg-navy-800"
                    to="/mibox/sign-in"
                  >
                    {translation.signIn}
                  </Link>
                </div>
              )}
            </div>
          </Card>
        </>
      )}
    </div>
  );
};

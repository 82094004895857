import { useEffect } from "react";
import useRedirectionHook from "utils/hooks/useRedirectionHook";

export default function Redirect() {
  const { getCountry, getQueryParam, buildAffiliateLink } =
    useRedirectionHook();

  useEffect(() => {
    const redirectUser = async () => {
      const countryCode = await getCountry();
      const affiliateCode = getQueryParam("ASIN");
      if (!affiliateCode) {
        console.error("No product code provided!");
        window.location.href = "/";
        return;
      }

      const affiliateLink = buildAffiliateLink(countryCode, affiliateCode);
      window.location.href = affiliateLink;
    };

    redirectUser();
  }, [buildAffiliateLink, getCountry, getQueryParam]);

  return <div className="h-full w-full text-white">Redirecting...</div>;
}

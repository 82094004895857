import { useAutoAnimate } from "@formkit/auto-animate/react";
import TheHotness from "../default/components/TheHotness";
import Banner from "../marketplace/components/Banner";
import General from "./components/General";
import Project from "./components/Project";
import BlogCard from "components/card/BlogCard";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { AxiosRequests } from "utils/axios/request";

const News = () => {
  const [animationParent] = useAutoAnimate();

  const [currentBlogNews, setCountNewsBlog] = useState([]);
  const [currentPodcast, setPodcast] = useState([]);
  const [shouldSearchNews, setShouldSearchNews] = useState(true);

  const { boardGameGeekBlog, boardGameGeekPodcast } = useSelector(
    (state: RootState) => state.newsSlice
  );

  const { getBoardGameGeedNewsBlog, getTheHotness, getBoardGameGeedPodcast,getNewsForumInformation } =
    AxiosRequests();
  useEffect(() => {
    if (shouldSearchNews) {
      setShouldSearchNews(false);
      setTimeout(() => {
        getBoardGameGeedNewsBlog();
        getTheHotness();
        getBoardGameGeedPodcast();
        /* const forumInfo = getNewsForumInformation();
        console.log("forumInfo", forumInfo); */
      }, 1000);
    }
  }, [
    getBoardGameGeedNewsBlog,
    getBoardGameGeedPodcast,
    getNewsForumInformation,
    getTheHotness,
    shouldSearchNews,
  ]);

  useEffect(() => {
    setCountNewsBlog(boardGameGeekBlog);
    setPodcast(boardGameGeekPodcast);
  }, [boardGameGeekBlog, boardGameGeekPodcast]);

  const renderNewsBlog = () => {
    const objToArr = Object.values(currentBlogNews).slice(0, 9);
    const blogItems = objToArr.map((g, index) => (
      <div className="col-span-1 flex flex-col" key={"newsBlog" + index}>
        <BlogCard
          blog={"BGG News Blog"}
          author={g.author}
          description={g.description}
          title={g.title}
          pubDate={g.pubDate}
          link={g.link}
        />
      </div>
    ));
    return <>{blogItems}</>;
  };
  const renderPodcast = () => {
    const objToArr = Object.values(currentPodcast).slice(0, 3);
    const podcastItems = objToArr.map((g, index) => (
      <div className="col-span-1 flex flex-col" key={"podcast" + index}>
        <BlogCard
          blog={"BGG Podcast"}
          author={g.author}
          description={g.description}
          title={g.title}
          pubDate={g.pubDate}
          link={g.link}
        />
      </div>
    ));
    return <>{podcastItems}</>;
  };

  return (
    <div className="flex w-full flex-col gap-5">
      <div className="flex h-fit w-full flex-col gap-5 lg:grid lg:grid-cols-8">
        <div className="col-span-8 flex h-fit w-full flex-col gap-5 lg:grid lg:grid-cols-8">
          <div className="col-span-8 flex h-fit w-full flex-col gap-5 lg:grid lg:grid-cols-8 ">
            <div className="col-span-6 mt-3 flex h-fit w-full flex-col gap-3 lg:grid lg:grid-cols-5">
              <div className="col-span-3 grid h-fit w-full grid-cols-2 flex-col gap-3 lg:grid-cols-3">
                {renderNewsBlog()}
                {renderPodcast()}
              </div>
              <div className="col-span-2 flex  flex-col gap-2">
                <General />
                <Project />
              </div>
            </div>
            <div
              className="col-span-2 h-full w-full rounded-xl"
              ref={animationParent}
            >
              <TheHotness showCount={10} />
            </div>
          </div>
          <div className="col-span-8 lg:!mb-0">
            <Banner showButtons={false} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default News;

import usaFlag from "assets/img/flags/us.png";
import canFlag from "assets/img/flags/ca.png";
import mxFlag from "assets/img/flags/mx.png";
import frFlag from "assets/img/flags/fr.png";

export const AMAZON_AFFILIATE_TAGS = {
  US: "citadejuego00-20",
  CA: "citadejuego09-20",
  MX: "cronicasvanco-20",
};

export const LANGUAGE_OPTIONS_DATA = [
  {
    lang: "en",
    name: "English",
    flag: usaFlag,
    url: "https://www.amazon.com",
    site: "Amazon.com",
    affiliateLink: "amazon.com",
  },
  {
    lang: "en-can",
    name: "English",
    flag: canFlag,
    url: "https://www.amazon.ca",
    site: "Amazon.ca",
    affiliateLink: "amazon.ca",
  },
  {
    lang: "es",
    name: "Español",
    flag: mxFlag,
    url: "https://www.amazon.com.mx",
    site: "Amazon.com.mx",
    affiliateLink: "amazon.com.mx",
  },
  {
    lang: "fr",
    name: "Français",
    flag: frFlag,
    url: "https://www.amazon.ca",
    site: "Amazon.ca",
    affiliateLink: "amazon.ca",
  },
];

export const DATES_TRANSLATION_ARRAY = {
  en: {
    oneWeek: "1 Week",
    oneMonth: "1 Month",
    threeMonths: "3 Months",
    sixMonthsOrMore: "6 Months or More",
    never: "Never",
  },
  "en-can": {
    oneWeek: "1 Week",
    oneMonth: "1 Month",
    threeMonths: "3 Months",
    sixMonthsOrMore: "6 Months or More",
    never: "Never",
  },
  es: {
    oneWeek: "1 Semana",
    oneMonth: "1 Mes",
    threeMonths: "3 Meses",
    sixMonthsOrMore: "6 Meses ó más",
    never: "Nunca",
  },
  fr: {
    oneWeek: "1 Semaine",
    oneMonth: "1 Mois",
    threeMonths: "3 Mois",
    sixMonthsOrMore: "6 Mois ou plus",
    never: "Jamais",
  },
};

export const AMAZON_SITE_OPTIONS = {
  en: {
    tag: AMAZON_AFFILIATE_TAGS.US,
    site: "Amazon.com",
    flag: usaFlag,
    url: "https://www.amazon.com/s?k=",
  },
  "en-can": {
    tag: AMAZON_AFFILIATE_TAGS.CA,
    site: "Amazon.ca",
    flag: canFlag,
    url: "https://www.amazon.ca/s?k=",
  },
  es: {
    tag: "cronicasvanco-20",
    site: "Amazon.com.mx",
    flag: mxFlag,
    url: "https://www.amazon.com.mx/s?k=",
  },
};

export const DEFAULT_USE_AUTO_ANIMATE = {
  duration: 300,
  easing: "ease-in",
};

export const FEEDBACK_EMAIL = "citadejuegos@gmail.com";
export const DEFULT_USERNAME = "citadejuegos";

export const openai_assistants = {
  Wyrmspan: "asst_F1iZedShhD1sAQrVw1iIvNVY",
  "The Resistance: Avalon": "asst_NC7n2QC8QXe2xkSEcKhmE6ws",
  Skull: "asst_81P9jGTCsu1KC1gj1184h8hXinAQ",
  "Tortuga 1667": "asst_2Vefju23PKbg9whCNFr4Tk7Y",
};


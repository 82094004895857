import Switch from "components/switch";
import { useEffect, useState } from "react";
import { subscribeToStripeProduct } from "../../../../utils/stripe-services/products";
import { useSelector } from "react-redux";
import { RootState } from "store/store";

export const CodexPricing = () => {
  const [validation, setValidation] = useState(true);
  const [subPeriod, setSubPeriod] = useState("year");
  const [prices, setPrices] = useState({ month: [0, 0, 0], year: [0, 0, 0] });
  const [isLoading, setIsLoading] = useState(false);
  const { stripeProducts } = useSelector(
    (state: RootState) => state.codexSlice
  );
  const { currentGoogleUser } = useSelector(
    (state: RootState) => state.userSlice
  );

  useEffect(() => {
    const pricesAux = {
      month: [],
      year: [],
    };
    stripeProducts.forEach((product) => {
      product.prices.forEach((price) => {
        if (price.price > 0) pricesAux[price.period].push(price.price);
      });
    });
    setPrices(pricesAux);
  }, [stripeProducts]);

  const subscribeToProduct = async (productIndex) => {
    setIsLoading(true);
    const productToSubscribe = stripeProducts[productIndex];
    let priceId = productToSubscribe.prices.find(
      (price) => price.period === subPeriod
    ).id;
    const response = subscribeToStripeProduct(currentGoogleUser.uid, priceId);
    if (response["type"] === "error") {
      alert("An error occurred, please try again later");
      setIsLoading(false);
    }
  };

  const FeatureRow = ({ feature }) => {
    return (
      <div className="flex items-center gap-4">
        <span className="rounded-full border border-white/20 bg-white/20 p-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            aria-hidden="true"
            className="h-3 w-3"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4.5 12.75l6 6 9-13.5"
            ></path>
          </svg>
        </span>
        <p className="text-inherit block font-sans text-base font-normal leading-relaxed antialiased">
          {feature}
        </p>
      </div>
    );
  };
  const PricingCard = ({
    title,
    price,
    features,
    buttonText,
    buttonDisabled,
    productIndex,
    textColor,
    toColor,
    fromColor,
    color,
  }) => {
    return (
      <div
        className={`relative col-span-3 flex w-full max-w-[15rem] flex-col rounded-xl bg-gradient-to-tr ${fromColor} ${toColor} bg-clip-border p-8 text-white shadow-md md:col-span-1 shadow-${color}-500/40`}
      >
        <div className="bg-transparent relative m-0 mb-8 overflow-hidden rounded-none border-b border-white/10 bg-clip-border pb-8 text-center text-gray-700 shadow-none">
          <p className="block font-sans text-sm font-normal uppercase leading-normal text-white antialiased">
            {title}
          </p>

          <h1 className="mt-6 flex justify-center gap-1 font-sans text-4xl font-normal tracking-normal text-white antialiased">
            <span className="mt-2 text-2xl">$</span>
            {price}
          </h1>
          <span className="self-end text-2xl text-white">CAD/{subPeriod}</span>
        </div>
        <div className="p-0">
          <ul className="flex flex-col gap-4">
            {features.map((feature, index) => (
              <FeatureRow key={index} feature={feature} />
            ))}
          </ul>
        </div>
        <div className="mt-12 p-0">
          <button
            disabled={buttonDisabled || isLoading}
            className={`shadow-blue-gray-500/10 hover:shadow-blue-gray-500/20 block w-full select-none rounded-lg ${textColor} bg-white px-7 py-3.5 text-center align-middle font-sans text-sm font-bold uppercase shadow-md transition-all hover:scale-[1.02] hover:shadow-lg focus:scale-[1.02] focus:opacity-[0.85] focus:shadow-none active:scale-100 active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none`}
            type="button"
            data-ripple-dark="true"
            onClick={() => subscribeToProduct(productIndex)}
          >
            {isLoading ? (
              <l-reuleaux
                size="37"
                stroke="5"
                stroke-length="0.15"
                bg-opacity="0.1"
                speed="1.2"
                color={color}
              ></l-reuleaux>
            ) : (
              <span>{buttonText}</span>
            )}
          </button>
        </div>
      </div>
    );
  };
  const changePeriod = () => {
    setValidation(!validation);
    setSubPeriod(validation ? "month" : "year");
  };

  return (
    <>
      <div className="m-4 flex w-full items-center gap-3 md:justify-center">
        <Switch checked={validation} onChange={changePeriod} />
        <label
          htmlFor="checkbox1"
          className="text-lg font-normal dark:text-white"
        >
          {validation ? "Yearly" : "Monthly"} Subscription
        </label>
      </div>
      <div className="grid grid-cols-3 place-items-center gap-2">
        <PricingCard
          toColor={"to-blue-300"}
          fromColor={"from-blue-500"}
          textColor={"text-blue-500"}
          productIndex={0}
          buttonDisabled={false}
          color={"blue"}
          title="Early Access"
          price={prices[subPeriod][0]}
          features={[
            "Before official launch",
            "Frozen for 2 years",
            "Request rulebooks",
            "Unlimited access",
            "Future Updates",
            "Future Assistants",
          ]}
          buttonText="You are here"
        />
        <PricingCard
          toColor={"to-purple-300"}
          fromColor={"from-purple-500"}
          textColor={"text-purple-500"}
          color={"purple"}
          productIndex={1}
          buttonDisabled={false}
          title="epic"
          price={prices[subPeriod][1]}
          features={[
            "Request rulebooks",
            "Unlimited access",
            "Future Updates",
            "Future Assistants",
            "Undying Gratitude",
          ]}
          buttonText="Subscribe"
        />
        <PricingCard
          toColor={"to-orange-300"}
          fromColor={"from-orange-500"}
          textColor={"text-orange-500"}
          color="orange"
          productIndex={2}
          buttonDisabled={false}
          title="legend"
          price={prices[subPeriod][2]}
          features={[
            "Request rulebooks",
            "Unlimited access",
            "Future Updates",
            "Future Assistants",
            "Priority Requests",
            "Undying Gratitude",
          ]}
          buttonText="Subscribe"
        />
      </div>
    </>
  );
};

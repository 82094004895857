import useTranslation from "utils/hooks/useTranslation";

export default function TermsOfUse() {
  const { translation } = useTranslation();
  return (
    <div className="mb-16 mt-1 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-1 lg:items-center lg:justify-start">
      <div className="mt-[5vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[620px]">
        <p className="mb-9 ml-1 text-base text-gray-600">
          {translation.termsIntro}
        </p>
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          {translation.termsFistHeader}
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          {translation.termsFistContent}
        </p>
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          {translation.termsSecondHeader}
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          {translation.termsSecondContent}
        </p>
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          {translation.termsThirdHeader}
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          {translation.termsThirdContent}
        </p>
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          {translation.termsFourthHeader}
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          {translation.termsFourthContent}
        </p>
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          {translation.termsFifthHeader}
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          {translation.termsFifthContent}
        </p>
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          {translation.termsSixthHeader}
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          {translation.termsSixthContent}
        </p>
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          {translation.termsSeventhHeader}
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          {translation.termsSeventhContent}
        </p>

        <p className="mb-9 ml-1 text-base text-gray-600">
          {translation.footerAffiliateDisclaimer}
        </p>
      </div>
    </div>
  );
}

import {addDoc, collection, getDocs, onSnapshot, query, where } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import firebaseApp, { db } from "../../firebase/credenciales";

export const getStripeProducts = async () => {
    const q = query(
        collection(db, 'products'), 
        where('active', '==', true)
      );
      
      const querySnapshot = await getDocs(q);
      
      // for each product, get the product price info
      const productsPromises = querySnapshot.docs.map(async (productDoc) => {
        let productInfo = productDoc.data();
      
        // fetch prices subcollection per product
        const pricesCollection = collection(productDoc.ref, 'prices');
        const priceQuerySnapshot = await getDocs(pricesCollection);
      
        // expect more than one price per product
        let productPrices = []
        priceQuerySnapshot.docs.forEach((doc) => {
            if(doc.data().active && doc.data().currency === 'cad')
            {
                const priceData = {
                    id: doc.id,
                    active: doc.data().active,
                    currency: doc.data().currency,
                    period: doc.data().recurring.interval,
                    price: doc.data().unit_amount / 100,
                }
                productPrices.push(priceData);

            }
        });
        productInfo['prices'] = productPrices
        return productInfo;
      });
      
      // 'products' is an array of products (including price info)
      const products = await Promise.all(productsPromises);
        return products;
}

export const getUserActiveSubscription = async (userId) => {
    const q = query(
        // currentUser is provided by firebase, via getAuth().currentUser
        collection(db, 'customers', userId, 'subscriptions'), 
        where('status', 'in', ['trialing', 'active'])
      );
         
      // fetch the active subscriptions
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
        // user doesn't have any active subscriptions
        return null;
        }
    // assuming user only has one active subscription max
    const subscription = querySnapshot.docs[0].data();
    return subscription;
}

export const subscribeToStripeProduct = async (userId, priceId) : Promise<any> => {
    let checkoutSessionData = {
        price: priceId, // price ID from products fetch
        success_url: window.location.origin, // can set this to a custom page
        cancel_url: window.location.origin   // can set this to a custom page
    };
    
    const checkoutSessionRef = await addDoc(
      collection(db, `customers/${userId}/checkout_sessions`), 
      checkoutSessionData
    );
    
    onSnapshot(checkoutSessionRef, (snap) => {
      const { error, url } = snap.data();
      if (error) {
        console.log('error',error)
        return {message: error.message, type: 'error'};
      }
      if (url) {
        window.location.assign(url);  // redirect to payment link
        return (url);
      }
    });
}

export const openSuscribePortal = async () => {
    const functions = getFunctions(firebaseApp, 'us-central1');
    const createPortalLink = httpsCallable(
    functions, 
    'ext-firestore-stripe-payments-createPortalLink');
    createPortalLink({
        returnUrl: window.location.origin
    }).then((result) => {
        window.location.assign(result.data['url']);
    }).catch((error) => {
      console.log('error',error)
    });
}


import { LANGUAGE_OPTIONS_DATA } from "utils/constants/constants";
import useTranslation from "utils/hooks/useTranslation";
import { LazyLoadImage } from "react-lazy-load-image-component";
import bggPlaceHolderImg from "assets/img/loader/placeholder_img.jpg";

const LanguageOptions = (props: { setLanguange: (lang: string) => void }) => {
  const { setLanguange } = props;

  const { translation } = useTranslation();

  let options = LANGUAGE_OPTIONS_DATA;
  translation.getAvailableLanguages().forEach((lang: string, index: number) => {
    options[index].lang = lang;
  });

  const renderOption = () => {
    const optionItems = options.map((option, index) => (
      <div
        key={option.lang + index}
        className="!z-[9999] col-span-1 flex h-7 w-full items-center justify-center self-center rounded-lg hover:!bg-navy-300 hover:shadow-2xl"
      >
        <LazyLoadImage
          wrapperClassName={`cursor-pointer h-full h-full hover:cursor-pointer`}
          onClick={() => setLanguange(option.lang)}
          placeholderSrc={bggPlaceHolderImg}
          src={option.flag}
          className="h-full w-full rounded-lg object-cover"
          alt={option.lang}
          effect="blur"
        />
      </div>
    ));
    return <>{optionItems}</>;
  };

  return (
    <div className="grid grid-cols-4 items-center justify-center gap-1 pt-2">
      {renderOption()}
    </div>
  );
};

export default LanguageOptions;

import useTranslation from "utils/hooks/useTranslation";

export default function FeelingLost() {
  const { translation } = useTranslation();
  return (
    <div className="mb-16 mt-1 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-1 lg:items-center lg:justify-start">
      <div className="mt-[5vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[620px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          {translation.faqHeader}
        </h4>
        <p className="mb-3 ml-1 text-base text-white">
          {translation.faqExplanationP1}
          <a
            className="text-branded-900"
            rel="noreferrer"
            target="_blank"
            href="https://boardgamegeek.com/"
          >
            BoardGameGeek
          </a>{" "}
          {translation.faqExplanationP2}
        </p>
        <h6 className="mb-3 ml-1 text-base text-white">
          {translation.faqSubHeader1}
        </h6>
        <p className="mb-3 ml-1 text-base text-white">
          {translation.faqPointOne}
        </p>
        <p className="mb-3 ml-1 text-base text-white">
          {translation.faqPointTwo}
        </p>
        <p className="mb-3 ml-1 text-base text-white">
          {translation.faqPointThree}
        </p>
        <p className="mb-3 ml-1 text-base text-white">
          {translation.faqPointAllSet}
        </p>
        <p className="mb-3 ml-1 text-base text-white">
          {translation.faqLastPoint}
        </p>
        <h6 className="mb-2.5 text-4xl font-bold text-brand-500">
          {translation.faqImportantNotes}
        </h6>
        <p className="mb-3 ml-1 text-base text-white">
          {translation.faqImportantNoteOne}
        </p>
        <p className="mb-3 ml-1 text-base text-white">
          {translation.faqImportantNoteTwo}
        </p>
      </div>
    </div>
  );
}

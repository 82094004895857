import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  ai_assistants: localStorage.getItem("openai_assistants")
    ? localStorage.getItem("openai_assistants")
    : {},
  threadInfo: {},
  messagesInfo: {},
  stripeProducts: [],
  isLoadingCodexAISubscription: true,
};

const codexSlice = createSlice({
  name: "codexSlice",
  initialState,
  reducers: {
    setAiAssistants(state, action: PayloadAction<{}>) {
      state.ai_assistants = action.payload;
    },
    setCodexHistory(
      state,
      action: PayloadAction<{ threadInfo: {}; messagesInfo: {} }>
    ) {
      state.threadInfo = action.payload.threadInfo;
      state.messagesInfo = action.payload.messagesInfo;
    },
    setStripeProducts(state, action: PayloadAction<[]>) {
      state.stripeProducts = action.payload;
    },
    setIsLoadingCodexAISubscription(state, action: PayloadAction<boolean>) {
      state.isLoadingCodexAISubscription = action.payload;
    },
  },
});

export const {
  setAiAssistants,
  setCodexHistory,
  setStripeProducts,
  setIsLoadingCodexAISubscription,
} = codexSlice.actions;
export default codexSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface AffiliateState {
  allLinks: any[];
}

const initialState = {
  allLinks: [],
} as AffiliateState;

const affiliateSlice = createSlice({
  name: "affiliateSlice",
  initialState,
  reducers: {
    setAllLinks(state, action: PayloadAction<any[]>) {
      state.allLinks = action.payload;
    },

    resetAllData(state, action: PayloadAction<any[]>) {
      state.allLinks = action.payload;
    },
  },
});

export const { setAllLinks, resetAllData } = affiliateSlice.actions;
export default affiliateSlice.reducer;

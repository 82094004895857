import { Link } from "react-router-dom";
import useTranslation from "utils/hooks/useTranslation";

const FreeCard = () => {
  const { translation } = useTranslation();
  return (
    <div className="relative mt-14 flex w-[256px] justify-center rounded-[20px] bg-gradient-to-br from-[#868CFF] via-[#432CF3] to-brand-500 pb-4">
      <div className="absolute -top-12 flex h-24 w-24 items-center justify-center rounded-full border-[4px] border-white bg-gradient-to-b from-[#868CFF] to-brand-500 dark:!border-navy-800">
        <svg
          width="60"
          height="60"
          fill="none"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.057 7.431a2.5 2.5 0 1 1 3.536 3.536 2.5 2.5 0 0 1-3.536-3.536Zm2.475 1.06a1 1 0 1 0-1.414 1.415 1 1 0 0 0 1.414-1.414Z"
            fill="#fff"
          />
          <path
            d="M19.698 2.512a2.75 2.75 0 0 1 1.81 1.81l.207.665a6.75 6.75 0 0 1-1.673 6.776l-.998.998a3.497 3.497 0 0 1-.328 4.568l-1.242 1.242a.75.75 0 0 1-1.06 0l-1.59-1.59-.177.177a1.75 1.75 0 0 1-2.475 0l-.487-.487-.811 1.39a.75.75 0 0 1-1.178.153l-3.89-3.889a.75.75 0 0 1 .146-1.174l1.384-.829-.47-.47a1.75 1.75 0 0 1 0-2.475l.18-.18-1.592-1.59a.75.75 0 0 1 0-1.061l1.243-1.243a3.498 3.498 0 0 1 4.569-.327l.996-.995a6.75 6.75 0 0 1 6.773-1.674l.663.205Zm.378 2.256a1.25 1.25 0 0 0-.823-.823l-.662-.205a5.25 5.25 0 0 0-5.269 1.301l-5.396 5.397a.25.25 0 0 0 0 .353l5.307 5.307a.25.25 0 0 0 .353 0l5.396-5.395a5.25 5.25 0 0 0 1.3-5.27l-.206-.665ZM6.69 18.395a.75.75 0 0 0-1.06-1.061l-2.476 2.475a.75.75 0 0 0 1.061 1.06l2.475-2.474ZM4.745 15.39a.75.75 0 0 1 0 1.06l-1.06 1.06a.75.75 0 1 1-1.061-1.06l1.06-1.06a.75.75 0 0 1 1.061 0ZM8.632 20.341a.75.75 0 1 0-1.06-1.06l-1.059 1.058a.75.75 0 0 0 1.06 1.06l1.06-1.058Z"
            fill="#fff"
          />
        </svg>
      </div>

      <div className="mt-16 flex h-fit flex-col items-center">
        <p className="mt-1 px-4 text-center text-sm text-white">
          {translation.annoucementText}
        </p>

        <a
          rel="noreferrer"
          target="_blank"
          className="text-medium mt-3 block rounded-full bg-gradient-to-b from-white/50 to-white/10 px-11 py-[12px] text-center text-base text-white hover:bg-gradient-to-b hover:from-white/40 hover:to-white/5 "
          href="https://www.youtube.com/channel/UC4L8DeqoDbP4kXn2EGXc90Q"
        >
          {translation.getToKnowUs}
        </a>
        <Link key={"feedback"} to={"/admin/feedback"}>
          <div className="text-medium mt-3 block rounded-full bg-gradient-to-b from-white/50 to-white/10 px-11 py-[12px] text-center text-base text-white hover:bg-gradient-to-b hover:from-white/40 hover:to-white/5 ">
            {translation.footerFeedback}
          </div>
        </Link>

        <p className="text-lg font-bold text-white">v0.0.6</p>
      </div>
    </div>
  );
};

export default FreeCard;

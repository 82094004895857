import CodexCard from "./components/CodexCard";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import Chat from "./components/Chat";
import { useState, useEffect } from "react";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import PaginationButton from "components/paginationButton";
import { DEFAULT_USE_AUTO_ANIMATE } from "utils/constants/constants";
import InputField from "components/fields/InputField";
//import useTranslation from "utils/hooks/useTranslation";
import Popup from "reactjs-popup";
import addNewGameRequest from "utils/firebase-services/gamesService";
import { Link } from "react-router-dom";

const Codex = () => {
  const { currentGoogleUser } = useSelector(
    (state: RootState) => state.userSlice
  );
  const { isLoadingCodexAISubscription } = useSelector(
    (state: RootState) => state.codexSlice
  );
  const { ai_assistants } = useSelector((state: RootState) => state.codexSlice);
  const { ownedGames } = useSelector((state: RootState) => state.gamesSlice);
  const [gameCards, setGameCards] = useState(ownedGames);
  const [selectedGame, setSelectedGame] = useState({
    title: "",
    image: "",
  });
  const paginationCodexBreakPoint = 30;
  const [paginationOptions, setPaginationOptions] = useState([]);
  const [pagination, setPagination] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const [searchInputSupported, setSearchInputSupported] = useState("");
  const [debouncedInputValue, setDebouncedInputValue] = useState("");
  const [debouncedInputValueSupported, setDebouncedInputValueSupported] =
    useState("");
  //const { translation } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [gameRequest, setGameRequest] = useState("");
  const [openModalSupportedGames, setOpenModalSupportedGames] = useState(false);
  const [showAllSupportedGames, setShowAllSupportedGames] = useState(false);

  const sendGameRequest = () => {
    setOpenModal(false);
    const gameRequestToSave = {
      email: currentGoogleUser.email,
      title: gameRequest,
      creationDate: new Date().getTime(),
      user: currentGoogleUser.key_firebase,
    };
    addNewGameRequest(gameRequestToSave);
    alert("Your request has been sent, thanks for helping us improve!");
  };
  const closeModal = () => {
    setOpenModal(false);
  };

  const closeModalSupportedGames = () => {
    setOpenModalSupportedGames(false);
  };

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      setDebouncedInputValue(searchInput);
    }, 500);
    return () => clearTimeout(delayInputTimeoutId);
  }, [searchInput]);

  useEffect(() => {
    const delayInputSupportedTimeoutId = setTimeout(() => {
      setDebouncedInputValueSupported(searchInputSupported);
    }, 500);
    return () => clearTimeout(delayInputSupportedTimeoutId);
  }, [searchInputSupported]);

  useEffect(() => {
    setPaginationOptions(
      [
        ...Array(
          Math.ceil(gameCards.length / paginationCodexBreakPoint)
        ).keys(),
      ].map((i) => i + 1)
    );
    setPagination(1);
  }, [gameCards]);

  useEffect(() => {
    if (ownedGames.length > 0) {
      if (currentGoogleUser) {
        const auxGoogleUser = JSON.parse(JSON.stringify(currentGoogleUser));
        if (auxGoogleUser.collection === undefined)
          auxGoogleUser.collection = { games: [], lastModified: 0 };
        auxGoogleUser.collection.games = ownedGames;
        auxGoogleUser.collection.lastModified = new Date().getTime();
      }
      const filteredOwnedGames = ownedGames.filter((game) =>
        game.name.toLowerCase().includes(debouncedInputValue.toLowerCase())
      );
      setGameCards(filteredOwnedGames);
    }
  }, [currentGoogleUser, ownedGames, debouncedInputValue]);

  const [animationParent] = useAutoAnimate(DEFAULT_USE_AUTO_ANIMATE);
  const renderGameCard = () => {
    const gameItems = gameCards
      .slice(
        (pagination - 1) * paginationCodexBreakPoint,
        paginationCodexBreakPoint + (pagination - 1) * paginationCodexBreakPoint
      )
      .map((g, index) => (
        <CodexCard
          setOpenModal={setOpenModal}
          setGameRequest={setGameRequest}
          selectedGame={selectedGame}
          setSelectedGame={selectGame}
          key={g.gameId + "-" + index}
          isExpansion={g.isExpansion}
          title={g.name}
          image={g.image}
        />
      ));
    return <>{gameItems}</>;
  };
  const renderSupportedGames = () => {
    const filteredGames = Object.keys(ai_assistants).filter((game) =>
      game.toLowerCase().includes(debouncedInputValueSupported.toLowerCase())
    );
    filteredGames.sort((a, b) => a.length - b.length);
    return filteredGames.map((game, index) => {
      if (index < 9) {
        return (
          <div
            key={game + "" + Math.random()}
            className="col-span-1 flex w-full"
          >
            <span>- {game}</span>
          </div>
        );
      } else {
        return (
          <>
            {showAllSupportedGames && (
              <div
                key={game + "" + Math.random()}
                className="col-span-1 flex w-full"
              >
                <span>- {game}</span>
              </div>
            )}
          </>
        );
      }
    });
  };
  const selectGame = (game) => {
    window.scrollTo(0, 0);
    setSelectedGame(game);
  };

  return (
    <div className="mt-3 grid h-full grid-cols-6 gap-5  md:grid-cols-6 xl:grid-cols-6 2xl:grid-cols-6">
      {isLoadingCodexAISubscription && (
        <div className="absolute z-50 col-span-6 h-[80%] w-[80%] rounded-xl bg-navy-900">
          <div className="flex h-full w-full items-center justify-center">
            <l-reuleaux
              size="60"
              stroke="5"
              stroke-length="0.15"
              bg-opacity="0.1"
              speed="1.2"
              color="orange"
            ></l-reuleaux>
          </div>
        </div>
      )}

      {currentGoogleUser && !isLoadingCodexAISubscription ? (
        <>
          <div
            className={`${
              selectedGame.title === "" ? "order-1" : "order-2 md:order-1"
            } col-span-6 h-full w-full rounded-xl md:col-span-2`}
          >
            <div className="grid grid-cols-6 items-center justify-center">
              <div className="col-span-6 flex w-full">
                <h4 className="text-lg font-bold text-navy-700 dark:text-white">
                  Select a game to use Codex AI
                </h4>
              </div>

              <div className="col-span-6 flex w-full md:col-span-4">
                <InputField
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  variant="auth"
                  extra="mb-3 w-full"
                  label=""
                  placeholder="Filter your collection by name"
                  id="email"
                  type="text"
                />
              </div>
              <div className="col-span-6 flex w-full justify-end md:col-span-2">
                <h4 className="justify-end text-xs font-bold text-navy-700 dark:text-white">
                  <span
                    className="mr-1 cursor-pointer text-lg text-brand-500"
                    onClick={() => {
                      setOpenModalSupportedGames(true);
                    }}
                  >
                    {Object.keys(ai_assistants).length}
                  </span>{" "}
                  rulebooks
                </h4>
              </div>
            </div>

            <div className=" mt-2 grid grid-cols-4 gap-3 sm:grid-cols-4 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-5">
              {renderGameCard()}
            </div>
            <div
              className="mt-4 flex h-fit w-full justify-center"
              ref={animationParent}
            >
              {ownedGames.length > paginationCodexBreakPoint &&
                paginationOptions.map((option, index) => (
                  <PaginationButton
                    key={index + option}
                    option={option}
                    pagination={pagination}
                    setPagination={setPagination}
                    animationParent={animationParent}
                  />
                ))}
            </div>
          </div>

          <div
            ref={animationParent}
            className={`${
              selectedGame.title === "" ? "order-2" : "order-1 md:order-2"
            } col-span-6 h-full w-full rounded-xl md:col-span-4`}
          >
            <Chat selectGame={selectGame} selectedGame={selectedGame} />
          </div>

          <Popup open={openModal} closeOnDocumentClick onClose={closeModal}>
            <div className="modal flex w-[250px] flex-col rounded-lg bg-navy-700 p-3">
              <div className="header border-grey-500 justify-left mb-2.5 flex w-full items-center border-b-2 p-5 text-lg font-bold text-navy-700 dark:text-white">
                {gameRequest}
              </div>
              <div className="header border-grey-500 justify-left mb-2.5 flex w-full items-center border-b-2 p-5 text-sm font-bold text-navy-700 dark:text-white">
                We currently dont support this game, we are trying to
                incorporate more Rulebooks as soon as possible.
              </div>
              <div className="actions text-base text-gray-600">
                Would you like to request it?
                <button
                  className="linear mt-1 w-2/3 rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                  onClick={() => {
                    sendGameRequest();
                  }}
                >
                  Yes, Please
                </button>
                <button
                  className="linear mt-1 w-2/3 rounded-xl py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                  onClick={() => {
                    closeModal();
                  }}
                >
                  No, thank you
                </button>
              </div>
            </div>
          </Popup>
        </>
      ) : (
        <>
          {!isLoadingCodexAISubscription && (
            <div className="col-span-6 mt-[10vh] flex w-full max-w-full flex-col items-center">
              <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
                Oops..
              </h4>
              <p className="mb-9 ml-1 text-base text-gray-600">
                To access Codex AI you need to be logged in, please sign in
                first.
              </p>
              <Link
                className="mt-6 flex h-[50px] w-[100px] items-center justify-center gap-2 rounded-xl text-white hover:cursor-pointer hover:bg-branded-900 dark:bg-navy-800"
                to="/mibox/sign-in"
              >
                Sign In
              </Link>
            </div>
          )}
        </>
      )}
      <Popup
        open={openModalSupportedGames}
        closeOnDocumentClick
        onClose={closeModalSupportedGames}
      >
        <div className="modal flex w-[50vw] flex-col rounded-lg bg-navy-700 p-3">
          <div className="header border-grey-500 flex w-full items-center justify-center border-b-2 p-2 text-lg font-bold text-white">
            Currently we support{" "}
            <span className="ml-1 mr-1 text-2xl text-brand-500">
              {Object.keys(ai_assistants).length}
            </span>{" "}
            games
          </div>
          <div
            ref={animationParent}
            className="border-grey-500 justify-left mb-2.5 grid w-full grid-cols-1 border-b-2 p-2 text-xs text-white sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3"
          >
            <div className="col-span-1 sm:col-span-2 md:col-span-3 lg:col-span-3">
              <InputField
                value={searchInputSupported}
                onChange={(e) => setSearchInputSupported(e.target.value)}
                variant="auth"
                extra="mb-2"
                label="Filter supported games"
                placeholder="e.g. Wyrmspan"
                id="email"
                type="text"
              />
            </div>
            {renderSupportedGames()}
            <div
              onClick={() => {
                setShowAllSupportedGames(!showAllSupportedGames);
              }}
              className="col-span-1 cursor-pointer justify-center text-center text-lg text-brand-500 sm:col-span-2 md:col-span-3 lg:col-span-3"
            >
              {!showAllSupportedGames ? "View " : "Hide "} all supported games
            </div>
          </div>
          <div className="actions text-base text-gray-600">
            <button
              className="linear mt-1 w-1/3 rounded-xl py-[12px] text-base font-medium text-branded-900 transition duration-200  hover:bg-brand-300  hover:text-white active:bg-brand-200"
              onClick={() => {
                closeModalSupportedGames();
              }}
            >
              Close
            </button>
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default Codex;

//import React, { useState, useContext } from "react";
import LocalizedStrings from "react-localization";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import localizationStrings from "utils/localizeStrings/localizationStrings.json";
import { setCurrentWishListLanguage } from "slices/languageSlice";

export default function useWishListTranslation() {
  const dispatch = useDispatch();
  const setNewCurrentWishListLanguange = (lang) => {
    localStorage.setItem("language", lang);
    dispatch(setCurrentWishListLanguage(lang));
  };

  const { currentWishListLanguage } = useSelector(
    (state: RootState) => state.languageSlice
  );
  let wishListTranslation = new LocalizedStrings(localizationStrings);
  wishListTranslation.setLanguage(currentWishListLanguage);
  return { wishListTranslation, setNewCurrentWishListLanguange };
}

import Card from "components/card";
import newsBlogImg from "assets/img/blog/news.jpg";
import {
  getImgTagsInDescription,
  getLocalizedDateString,
} from "utils/functions/utils";
import { LazyLoadImage } from "react-lazy-load-image-component";
import bggPlaceHolderImg from "assets/img/loader/placeholder_img.jpg";
const BlogCard = (props) => {
  const { title, pubDate, description, author, blog, link } = props;
  return (
    <Card extra={`flex flex-col w-full h-full bg-white relative`}>
      <a href={link} target="blank" className=" hover:cursor-pointer">
        <div className="h- w-full p-2 hover:cursor-pointer">
          <div className="relative w-full">
            <LazyLoadImage
              wrapperClassName={`h-44 w-full`}
              placeholderSrc={bggPlaceHolderImg}
              src={getImgTagsInDescription(description) || newsBlogImg}
              className="h-44 w-full rounded-xl object-cover"
              alt={author}
              effect="blur"
            />

            <div className="absolute right-0 top-0 flex items-center justify-center rounded-bl-lg rounded-tr-lg bg-branded-900 p-2 text-white">
              <div className="w-fill flex h-4 items-center justify-center text-xs  font-bold capitalize dark:text-white">
                {author}
              </div>
            </div>
          </div>

          <div className="absolute bottom-5 flex w-full items-center  justify-between bg-navy-900 bg-opacity-95 p-1 md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col xl:items-start 3xl:flex-row 3xl:justify-between">
            <div className="mb-6">
              <p className="mr-2 text-xs font-normal text-navy-700 dark:text-white">
                {title.length > 75 ? title.substring(0, 75) + "..." : title}
              </p>
            </div>
          </div>
          <div className="text-navy absolute bottom-0  left-0 flex w-full items-center justify-between rounded-b-lg bg-branded-800 p-3">
            <div className="flex h-4 w-fit items-center justify-center rounded-full text-sm font-bold dark:text-white">
              {blog}
            </div>
            <div className="flex h-4 w-fit items-center justify-center rounded-full text-sm capitalize dark:text-white">
              {getLocalizedDateString(pubDate)}
            </div>
          </div>
        </div>
      </a>
    </Card>
  );
};

export default BlogCard;

import { FaMinusSquare, FaPlusSquare } from "react-icons/fa";
import Card from "components/card";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { useEffect, useState } from "react";
import { DEFAULT_USE_AUTO_ANIMATE } from "utils/constants/constants";
import { setCurrentGoogleUser } from "slices/userSlice";
import { updateShareLinkWishlistInFirebase } from "utils/firebase-services/wishListService";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { IoMdInformationCircleOutline } from "react-icons/io";
import Popup from "reactjs-popup";
import useTranslation from "utils/hooks/useTranslation";
import { LazyLoadImage } from "react-lazy-load-image-component";
import bggPlaceHolderImg from "assets/img/loader/placeholder_img.jpg";
import { setShareWishlistGames } from "slices/gamesSlice";

const WishlistSection = () => {
  const { translation } = useTranslation();
  const { currentGoogleUser } = useSelector(
    (state: RootState) => state.userSlice
  );
  const [animationParent] = useAutoAnimate(DEFAULT_USE_AUTO_ANIMATE);

  const { wishListGames, shareWishlistGames } = useSelector(
    (state: RootState) => state.gamesSlice
  );
  const [wishlistCards, setWishlistCards] = useState(wishListGames);
  const [shareLinkGames, setShareLinkGames] = useState(shareWishlistGames);
  const dispatch = useDispatch();

  useEffect(() => {
    setWishlistCards(wishListGames);
  }, [wishListGames]);
  useEffect(() => {
    setShareLinkGames(shareWishlistGames);
  }, [shareWishlistGames]);

  const updateAllUserShareLinkInformation = (user: any) => {
    setShareLinkGames(user.wishlist.shareLinkGames);
    dispatch(setShareWishlistGames(user.wishlist.shareLinkGames));
    dispatch(setCurrentGoogleUser(user));
    updateShareLinkWishlistInFirebase(user);
  };

  const addCardToShareLink = (card: any) => {
    const auxGameWishlist = JSON.parse(JSON.stringify(currentGoogleUser));
    auxGameWishlist.wishlist.shareLinkGames.push(card);
    auxGameWishlist.wishlist.lastModified = new Date().getTime();
    updateAllUserShareLinkInformation(auxGameWishlist);
  };

  const removeGameFromShareLink = (gameId: any) => {
    const auxGameWishlist = JSON.parse(JSON.stringify(currentGoogleUser));
    const gameIndex = auxGameWishlist.wishlist.shareLinkGames.findIndex(
      (game: any) => game.gameId === gameId
    );
    auxGameWishlist.wishlist.shareLinkGames.splice(gameIndex, 1);
    auxGameWishlist.wishlist.lastModified = new Date().getTime();
    updateAllUserShareLinkInformation(auxGameWishlist);
  };

  useEffect(() => {
    setWishlistCards(wishListGames);
  }, [wishListGames]);

  const checkGameInShareLinkList = (gameId: number) => {
    if (currentGoogleUser) {
      const gameIndex = shareLinkGames.findIndex(
        (game: any) => game.gameId === gameId
      );
      if (gameIndex !== -1) {
        return true;
      } else {
        return false;
      }
    }
  };

  const renderWishlistCards = () => {
    const sortedWishlistCards = [...wishlistCards].sort((a, b) => {
      return a.wishListPriority - b.wishListPriority;
    });
    const gameItems = sortedWishlistCards.map((data, index) => (
      <div
        key={index}
        className="flex grid h-full w-full grid-cols-6 items-center justify-between border-b-[1px] border-branded-800 bg-navy-800 px-3 py-[10px] shadow-none hover:shadow-2xl dark:hover:!bg-navy-700"
      >
        <div className="col-span-5 flex grid grid-cols-5 items-center gap-3">
          <div className="col-span-1 flex h-10 w-10 items-center justify-center">
            <LazyLoadImage
              wrapperClassName={`h-10 w-10`}
              placeholderSrc={bggPlaceHolderImg}
              src={data.image}
              className="h-10 w-10 rounded-xl"
              alt={data.name}
              effect="blur"
            />
            <div className="absolute mb-8 mr-8 flex h-4 w-4 items-center justify-center rounded-full bg-branded-800 text-white">
              <div className="flex items-center justify-center rounded-full text-xs font-bold dark:text-white">
                {!data.custom ? data.wishListPriority : "-"}
              </div>
            </div>
          </div>
          <div className="col-span-4 ml-3 flex flex-col">
            <h5 className="text-sm font-bold text-navy-700 dark:text-white">
              {data.name.length > 35
                ? data.name.substring(0, 35) + "..."
                : data.name}
            </h5>
          </div>
        </div>

        <div className="col-span-1 mt-1 flex items-center justify-center text-navy-700 dark:text-white">
          {currentGoogleUser && (
            <div>
              {!checkGameInShareLinkList(data.gameId) ? (
                <FaPlusSquare
                  onClick={() => addCardToShareLink(data)}
                  className="text-branded-900 hover:cursor-pointer hover:text-branded-800"
                />
              ) : (
                <FaMinusSquare
                  onClick={() => removeGameFromShareLink(data.gameId)}
                  className="hover:cursor-pointer"
                />
              )}
            </div>
          )}
        </div>
      </div>
    ));
    return <>{gameItems}</>;
  };

  const renderToolTipItem = () => {
    const toolTipItems = ["Must", "Love", "Like", "Think", "Don't"].map(
      (data, index) => (
        <div
          key={index + "tooltip"}
          className="col-span-1 flex h-10 w-full items-center justify-center"
        >
          <div className="flex h-4 w-4 items-center justify-center rounded-full bg-branded-800 p-2 text-white">
            <div className="flex items-center justify-center rounded-full text-xs font-bold dark:text-white">
              {index + 1}
            </div>
          </div>
          <span className="ml-2 text-xs">{data}</span>
        </div>
      )
    );
    return <>{toolTipItems}</>;
  };

  const addAllCards = () => {
    const auxGameWishlist = JSON.parse(JSON.stringify(currentGoogleUser));
    auxGameWishlist.wishlist.shareLinkGames = [...wishlistCards];
    auxGameWishlist.wishlist.lastModified = new Date().getTime();
    updateAllUserShareLinkInformation(auxGameWishlist);
  };

  const removeAllCards = () => {
    const auxGameWishlist = JSON.parse(JSON.stringify(currentGoogleUser));
    auxGameWishlist.wishlist.shareLinkGames = [];
    auxGameWishlist.wishlist.lastModified = new Date().getTime();
    updateAllUserShareLinkInformation(auxGameWishlist);
  };

  return (
    <Card extra={"mt-3 !z-5  p-5"}>
      <div className=" flex items-center justify-between px-1 pt-1 ">
        <div className="w-full text-sm font-bold dark:text-white">
          <div className="col-span-2 grid w-full grid-cols-6 items-center gap-1 text-white">
            <div className="justify-left col-span-3 flex h-10 w-full items-center text-xl">
              Your Wishlist
            </div>
            <div className="justify-left col-span-1 flex h-10 w-full items-center">
              <Popup
                trigger={
                  <p className="cursor-pointer">
                    <IoMdInformationCircleOutline className="h-4 w-4 text-gray-600 dark:text-white" />
                  </p>
                }
                position="top center"
              >
                <div
                  className="col-span-5 flex grid w-full grid-cols-5 items-center gap-1 text-white"
                  ref={animationParent}
                >
                  {currentGoogleUser && (
                    <div className="items-left justify-left col-span-5 mb-10 flex h-2 w-full p-1 text-sm">
                      {translation.addYourGamesToWishList}
                    </div>
                  )}
                  <div className="items-left justify-left col-span-5 mb-1 flex h-2 w-full p-1 text-sm">
                    BGG Wishlist Priority
                  </div>

                  {renderToolTipItem()}
                </div>
                <div
                  className="col-span-5 flex grid w-full grid-cols-5 items-center gap-1 text-white"
                  ref={animationParent}
                ></div>
              </Popup>
            </div>
            <div className="col-span-1 flex h-10 w-full items-center justify-end">
              {currentGoogleUser && wishlistCards.length > 0 && (
                <FaPlusSquare
                  onClick={() => addAllCards()}
                  className="text-branded-900 hover:cursor-pointer hover:text-branded-800"
                />
              )}
            </div>
            <div className="col-span-1 flex h-10 w-full items-center justify-end">
              {currentGoogleUser && wishlistCards.length > 0 && (
                <FaMinusSquare
                  onClick={() => removeAllCards()}
                  className="hover:cursor-pointer"
                />
              )}
            </div>
          </div>
          {currentGoogleUser && wishlistCards.length === 0 && (
            <span className="text-sm font-normal text-gray-500 hover:cursor-pointer">
              {" "}
              {translation.noWishListGames}
            </span>
          )}
        </div>
      </div>

      <div ref={animationParent}>{renderWishlistCards()}</div>
    </Card>
  );
};

export default WishlistSection;

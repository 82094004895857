import {
  addDoc,
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../firebase/credenciales";

export const getGamesCatalog = async () => {
  try {
    const collectionRef = collection(db, "catalog");
    const doc_refs = await getDocs(collectionRef);
    const res = [];
    doc_refs.forEach((user) => {
      res.push({
        id: user.id,
        ...user.data(),
      });
    });
    let resultArary = [];
    res.forEach((e) => {
      resultArary = [...resultArary, ...e.allGames];
    });
    return resultArary;
  } catch (error) {
    console.log(error);
  }
};

export const saveGameRawInfoToGameCatalog = async (arrayOfGames) => {
  try {
    const collectionRef = collection(db, "catalog");

    let allGamesCatalogObj = {
      gameIds: [],
      allGames: [],
      count: 0,
    };

    for (const g of arrayOfGames) {
      allGamesCatalogObj.gameIds.push(g.gameId);
      allGamesCatalogObj.allGames.push(g);
      allGamesCatalogObj.count++;
    }
    const newGame = await addDoc(collectionRef, allGamesCatalogObj);
    return newGame;
  } catch (error) {
    console.log(error);
  }
};

export const saveToCurrentCatalog = async (arrayOfGames) => {
  try {
    const collectionRef = collection(db, "catalog");
    const q = query(collectionRef, where("current", "==", true));
    const doc_refs = await getDocs(q);
    const res = [];
    doc_refs.forEach((catalog) => {
      res.push({
        id: catalog.id,
        ...catalog.data(),
      });
    });

    let currentCatalogObj;

    if (res.length === 0) {
      currentCatalogObj = {
        gameIds: [],
        allGames: [],
        count: 0,
        current: false,
      };

      if (arrayOfGames.length >= 1500) {
        let auxArray = arrayOfGames.slice(0, 1500);
        for (const g of auxArray) {
          currentCatalogObj.gameIds.push(g.gameId);
          currentCatalogObj.allGames.push(g);
          currentCatalogObj.count++;
        }
        currentCatalogObj.current = false;

        const newGame = await addDoc(collectionRef, currentCatalogObj);
        console.log("newGame", newGame);

        let newCurrentCatalogObj = {
          gameIds: [],
          allGames: [],
          count: 0,
          current: false,
        };
        let newAuxArray = arrayOfGames.slice(1500, arrayOfGames.length);
        for (const g of newAuxArray) {
          newCurrentCatalogObj.gameIds.push(g.gameId);
          newCurrentCatalogObj.allGames.push(g);
          newCurrentCatalogObj.count++;
        }
        newCurrentCatalogObj.current = true;

        const newGame2 = await addDoc(collectionRef, newCurrentCatalogObj);
        console.log("newGame2", newGame2);
      }
    } else {
      currentCatalogObj = {
        id: res[0].id,
        gameIds: res[0].gameIds,
        allGames: res[0].allGames,
        count: res[0].count,
        current: res[0].current,
      };

      if (arrayOfGames.length >= 1500 - currentCatalogObj.count) {
        let auxArray = arrayOfGames.slice(0, 1500 - currentCatalogObj.count);
        for (const g of auxArray) {
          currentCatalogObj.gameIds.push(g.gameId);
          currentCatalogObj.allGames.push(g);
          currentCatalogObj.count++;
        }
        currentCatalogObj.current = false;

        const gameCatalog = doc(db, "catalog", res[0].id);
        await updateDoc(gameCatalog, {
          gameIds: currentCatalogObj.gameIds,
          allGames: currentCatalogObj.allGames,
          count: currentCatalogObj.count,
          current: currentCatalogObj.current,
        });

        let newCurrentCatalogObj = {
          gameIds: [],
          allGames: [],
          count: 0,
          current: false,
        };
        let newAuxArray = arrayOfGames.slice(
          1500 - currentCatalogObj.count,
          arrayOfGames.length
        );
        for (const g of newAuxArray) {
          newCurrentCatalogObj.gameIds.push(g.gameId);
          newCurrentCatalogObj.allGames.push(g);
          newCurrentCatalogObj.count++;
        }
        newCurrentCatalogObj.current = true;

        const newGame = await addDoc(collectionRef, newCurrentCatalogObj);
        console.log("newGame", newGame);
      } else {
        for (const g of arrayOfGames) {
          currentCatalogObj.gameIds.push(g.gameId);
          currentCatalogObj.allGames.push(g);
          currentCatalogObj.count++;
        }

        const gameCatalog = doc(db, "catalog", res[0].id);
        await updateDoc(gameCatalog, {
          gameIds: currentCatalogObj.gameIds,
          allGames: currentCatalogObj.allGames,
          count: currentCatalogObj.count,
        });
      }
    }

    return currentCatalogObj;
  } catch (error) {
    console.log(error);
  }
};

export const getAsinsCatalog = async () => {
  try {
    const collectionRef = collection(db, "catalogAsins");
    const q = query(collectionRef, where("c", "==", 1));
    const doc_refs = await getDocs(q);
    const res = [];
    doc_refs.forEach((user) => {
      res.push({
        id: user.id,
        ...user.data(),
      });
    });
    return res[0];
  } catch (error) {
    console.log(error);
  }
};

export const saveToCurrentAsinCatalog = async (arrayOfAsins) => {
  try {
    const collectionRef = collection(db, "catalogAsins");
    const q = query(collectionRef, where("current", "==", true));
    const doc_refs = await getDocs(q);
    const res = [];
    doc_refs.forEach((catalog) => {
      res.push({
        id: catalog.id,
        ...catalog.data(),
      });
    });

    let currentCatalogObj;

    if (res.length === 0) {
      currentCatalogObj = {
        gameIds: [],
        asins: [],
        count: 0,
        current: false,
      };

      if (arrayOfAsins.length >= 3000) {
        let auxArray = arrayOfAsins.slice(0, 3000);
        for (const g of auxArray) {
          currentCatalogObj.gameIds.push(isNaN(g.gameId) ? -1 : g.gameId);
          currentCatalogObj.asins.push(g);
          currentCatalogObj.count++;
        }
        currentCatalogObj.current = false;

        console.log("currentCatalogObj", currentCatalogObj);
        const newAsin = await addDoc(collectionRef, currentCatalogObj);
        console.log("newAsin", newAsin);

        let newCurrentCatalogObj = {
          gameIds: [],
          asins: [],
          count: 0,
          current: false,
        };
        let newAuxArray = arrayOfAsins.slice(3000, arrayOfAsins.length);
        for (const g of newAuxArray) {
          currentCatalogObj.gameIds.push(isNaN(g.gameId) ? -1 : g.gameId);
          newCurrentCatalogObj.asins.push(g);
          newCurrentCatalogObj.count++;
        }
        newCurrentCatalogObj.current = true;
        console.log("newCurrentCatalogObj", newCurrentCatalogObj);

        const newAsin2 = await addDoc(collectionRef, newCurrentCatalogObj);

        console.log("newAsin2", newAsin2);
      }
    } else {
      currentCatalogObj = {
        id: res[0].id,
        gameIds: res[0].gameIds,
        asins: res[0].asins,
        count: res[0].count,
        current: res[0].current,
      };

      if (arrayOfAsins.length >= 3000 - currentCatalogObj.count) {
        let auxArray = arrayOfAsins.slice(0, 3000 - currentCatalogObj.count);
        for (const g of auxArray) {
          currentCatalogObj.gameIds.push(isNaN(g.gameId) ? -1 : g.gameId);
          currentCatalogObj.asins.push(g);
          currentCatalogObj.count++;
        }
        currentCatalogObj.current = false;

        console.log("currentCatalogObj", currentCatalogObj);
        const asinCatalog = doc(db, "catalogAsins", res[0].id);
        await updateDoc(asinCatalog, {
          gameIds: currentCatalogObj.gameIds,
          asins: currentCatalogObj.asins,
          count: currentCatalogObj.count,
          current: currentCatalogObj.current,
        });
        console.log("currentCatalog", currentCatalogObj);

        let newCurrentCatalogObj = {
          gameIds: [],
          asins: [],
          count: 0,
          current: false,
        };
        let newAuxArray = arrayOfAsins.slice(
          3000 - currentCatalogObj.count,
          arrayOfAsins.length
        );
        for (const g of newAuxArray) {
          currentCatalogObj.gameIds.push(isNaN(g.gameId) ? -1 : g.gameId);
          newCurrentCatalogObj.asins.push(g);
          newCurrentCatalogObj.count++;
        }
        newCurrentCatalogObj.current = true;

        const newAsin = await addDoc(collectionRef, newCurrentCatalogObj);

        console.log("newCurrentCatalogObj", newCurrentCatalogObj);
        console.log("newAsin", newAsin);
      } else {
        for (const g of arrayOfAsins) {
          currentCatalogObj.gameIds.push(isNaN(g.gameId) ? -1 : g.gameId);
          currentCatalogObj.asins.push(g);
          currentCatalogObj.count++;
        }

        console.log("currentCatalogObj", currentCatalogObj);
        const asinCatalog = doc(db, "catalogAsins", res[0].id);
        await updateDoc(asinCatalog, {
          gameIds: currentCatalogObj.gameIds,
          asins: currentCatalogObj.asins,
          count: currentCatalogObj.count,
        });
        console.log("currentCatalog", currentCatalogObj);
      }
    }

    return currentCatalogObj;
  } catch (error) {
    console.log(error);
  }
};

export const saveToCurrentAsinCatalogNoMax = async (arrayOfAsins) => {
  try {
    const collectionRef = collection(db, "catalogAsins");
    const q = query(collectionRef, where("current", "==", true));
    const doc_refs = await getDocs(q);
    const res = [];
    doc_refs.forEach((catalog) => {
      res.push({
        id: catalog.id,
        ...catalog.data(),
      });
    });

    console.log("res", res);

    let currentCatalogObj = {
      gameIds: [],
      asins: [],
      count: 0,
      current: true,
    };

    for (const g of arrayOfAsins) {
      currentCatalogObj.gameIds.push(isNaN(g.gameId) ? -1 : g.gameId);
      currentCatalogObj.asins.push(g);
      currentCatalogObj.count++;
    }

    const asinCatalog = doc(db, "catalogAsins", res[0].id);
    await updateDoc(asinCatalog, {
      gameIds: currentCatalogObj.gameIds,
      asins: currentCatalogObj.asins,
      count: currentCatalogObj.count,
      current: currentCatalogObj.current,
    });

    return currentCatalogObj;
  } catch (error) {
    console.log(error);
  }
};

export const saveBanAsin = async (gameId) => {
  try {
    const collectionRef = collection(db, "catalogAsins");
    const q = query(collectionRef, where("c", "==", 1));
    const doc_refs = await getDocs(q);
    const res = [];
    doc_refs.forEach((catalog) => {
      res.push({
        id: catalog.id,
        ...catalog.data(),
      });
    });

    let currentCatalogObj = {
      b: res[0].b,
      bco: res[0].bco,
    };

    currentCatalogObj.b.push(gameId);
    currentCatalogObj.bco++;

    const asinCatalog = doc(db, "catalogAsins", res[0].id);
    await updateDoc(asinCatalog, {
      b: currentCatalogObj.b,
      bco: currentCatalogObj.bco,
    });

    return currentCatalogObj;
  } catch (error) {
    console.log(error);
  }
};
export const saveNewAsin = async (asin, gameId) => {
  try {
    const collectionRef = collection(db, "catalogAsins");
    const q = query(collectionRef, where("c", "==", 1));
    const doc_refs = await getDocs(q);
    const res = [];
    doc_refs.forEach((catalog) => {
      res.push({
        id: catalog.id,
        ...catalog.data(),
      });
    });

    let currentCatalogObj = {
      a: res[0].a,
      c: res[0].c,
      g: res[0].g,
      co: res[0].co,
    };

    currentCatalogObj.g.push(gameId);
    currentCatalogObj.a.push(asin);
    currentCatalogObj.co++;

    const asinCatalog = doc(db, "catalogAsins", res[0].id);
    await updateDoc(asinCatalog, {
      g: currentCatalogObj.g,
      a: currentCatalogObj.a,
      co: currentCatalogObj.co,
    });

    return currentCatalogObj;
  } catch (error) {
    console.log(error);
  }
};

export const updateAsinCatalog = async () => {
  /* const collectionRef = collection(db, "catalogAsins");
  const q = query(collectionRef, where("c", "==", 1));
  const doc_refs = await getDocs(q);
  const res = [];
  doc_refs.forEach((catalog) => {
    res.push({
      id: catalog.id,
      ...catalog.data(),
    });
  });

  let currentCatalogObj = res[0];

  console.log("currentCatalogObj", currentCatalogObj);

  let newCatalog = {
    a: currentCatalogObj.a,
    co: currentCatalogObj.co,
    g: currentCatalogObj.g,
  }; */
  /* let newCatalog = {
    a: [],
    co: 0,
    g: [],
  }; */
  /* for (let i = 0; i < 3776; i++) {
    newCatalog.a.push(currentCatalogObj.a[i]);
    newCatalog.g.push(currentCatalogObj.g[i]);
    newCatalog.co++;
  } */
  /* let theJsonLinks = jsonLinks as Array<any>;

  theJsonLinks.forEach((e) => {
    if (!newCatalog.g.includes(e.gameId)) {
      newCatalog.g.push(e.gameId);
      newCatalog.a.push(e.asin);
      newCatalog.co++;
    }
  });

  const asinCatalog = doc(db, "catalogAsins", res[0].id);
  await updateDoc(asinCatalog, {
    g: newCatalog.g,
    a: newCatalog.a,
    co: newCatalog.co,
  });
  // console.log("currentCatalog", currentCatalogObj);

  return currentCatalogObj; */
};

import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface CollectionState {
  collectionCategories: any[];
  collectionMechanics: any[];
  collectionComplexity: any[];
  collectionPlayers: any[];
  collectionTimes: any[];
  collectionDates: any[];
}

const initialState = {
  collectionMechanics: [],
  collectionCategories: [],
  collectionComplexity: [],
  collectionPlayers: [],
  collectionTimes: [],
  collectionDates: [],
} as CollectionState;

const collectionSlice = createSlice({
  name: "collectionSlice",
  initialState,
  reducers: {
    setCollectionMechanics(state, action: PayloadAction<any[]>) {
      state.collectionMechanics = action.payload;
    },
    setCollectionCategories(state, action: PayloadAction<any[]>) {
      state.collectionCategories = action.payload;
    },
    setCollectionComplexity(state, action: PayloadAction<any[]>) {
      state.collectionComplexity = action.payload;
    },
    setCollectionPlayers(state, action: PayloadAction<any[]>) {
      state.collectionPlayers = action.payload;
    },
    setCollectionTimes(state, action: PayloadAction<any[]>) {
      state.collectionTimes = action.payload;
    },
    setCollectionDates(state, action: PayloadAction<any[]>) {
      state.collectionDates = action.payload;
    },
    resetAllGameData(state, action: PayloadAction<any[]>) {
      state.collectionMechanics = action.payload;
      state.collectionCategories = action.payload;
      state.collectionComplexity = action.payload;
      state.collectionPlayers = action.payload;
      state.collectionTimes = action.payload;
    },
  },
});

export const {
  setCollectionTimes,
  setCollectionDates,
  setCollectionMechanics,
  setCollectionCategories,
  setCollectionComplexity,
  setCollectionPlayers,
  resetAllGameData,
} = collectionSlice.actions;
export default collectionSlice.reducer;

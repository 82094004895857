import { useDispatch } from "react-redux";
import {
  setCollectionCategories,
  setCollectionComplexity,
  setCollectionDates,
  setCollectionMechanics,
  setCollectionPlayers,
  setCollectionTimes,
} from "slices/collectionSlice";
import { setIsLoadingData, setIsSavingWishlistData } from "slices/userSlice";
import {
  setHorasJugadas,
  setJuegosJugados,
  setMyPlays,
  setOwnedExpansions,
  setOwnedGames,
  setWishlistGames,
} from "slices/gamesSlice";
import { AxiosRequests } from "utils/axios/request";
import {
  getTimingString,
  getUniqueArrayWithElementsCounted,
  getUniqueArrayWithElementsCountedComplexity,
  jsonParseLocalStorageKey,
} from "utils/functions/utils";
import useTranslation from "./useTranslation";
import {
  checkIfGameExists,
  //checkForDuplicates,
} from "utils/firebase-services/gamesService";
import allGamesJsonData from "../../catalog/allGames.json";
import { updateWishlistAndCollection } from "utils/firebase-services/userService";

export const SyncData = () => {
  const {
    getMyCollection,
    getAllGamesInformation,
    getPlaysInformation,
    getGameInformationByGameId,
  } = AxiosRequests();
  const { translation } = useTranslation();
  const dispatch = useDispatch();

  const setGamesInformationToStoredData = () => {
    if (localStorage.getItem("storedOwnedGames")) {
      dispatch(
        setOwnedGames(JSON.parse(localStorage.getItem("storedOwnedGames")))
      );
    }
    if (localStorage.getItem("storedWishlistGames")) {
      dispatch(
        setWishlistGames(
          JSON.parse(localStorage.getItem("storedWishlistGames"))
        )
      );
    }
    if (localStorage.getItem("ownedExpansions")) {
      dispatch(
        setOwnedExpansions(Number(localStorage.getItem("ownedExpansions")) / 1)
      );
    }
    if (localStorage.getItem("storedMyPlays")) {
      dispatch(setMyPlays(JSON.parse(localStorage.getItem("storedMyPlays"))));
    }
    if (localStorage.getItem("storedHorasJugadas")) {
      dispatch(
        setHorasJugadas(Number(localStorage.getItem("storedHorasJugadas")) / 1)
      );
    }
    if (localStorage.getItem("storedJuegosJugados")) {
      dispatch(
        setJuegosJugados(
          Number(localStorage.getItem("storedJuegosJugados")) / 1
        )
      );
    }
  };

  const setUpFilters = (setUpArray) => {
    const playersArray = [];
    const mechanicsArray = [];
    const categoriesArray = [];
    const complexityArray = [];
    const timesArray = [];
    const datesArray = [
      { name: translation.oneWeek },
      { name: translation.oneMonth },
      { name: translation.threeMonths },
      { name: translation.sixMonthsOrMore },
      { name: translation.never },
    ];
    setUpArray.forEach((g: any) => {
      if (g.averageweight) {
        let complexity = "";
        switch (g.averageweight) {
          case 1:
            complexity = translation.veryEasy;
            break;
          case 2:
            complexity = translation.easy;
            break;
          case 3:
            complexity = translation.normal;
            break;
          case 4:
            complexity = translation.hard;
            break;
          case 5:
            complexity = translation.veryHard;
            break;
        }
        complexityArray.push({
          name: complexity,
          complexity: g.averageweight,
        });
      }
      if (g.categories) {
        g.categories.forEach((m: any) => {
          categoriesArray.push({ name: m });
        });
      }
      if (g.mechanics) {
        g.mechanics.forEach((m: any) => {
          mechanicsArray.push({ name: m });
        });
      }
      if (g.minPlayers && g.maxPlayers) {
        for (let i = g.minPlayers / 1; i <= g.maxPlayers / 1; i++) {
          playersArray.push({ name: i });
        }
      }
      if (g.playingTime) {
        timesArray.push({
          name: getTimingString(g.playingTime),
          time: g.playingTime,
        });
      }
    });

    const uniqueTimes = getUniqueArrayWithElementsCounted(timesArray);
    const uniqueMechanics = getUniqueArrayWithElementsCounted(mechanicsArray);
    const uniqueCategories = getUniqueArrayWithElementsCounted(categoriesArray);
    const uniqueComplexity =
      getUniqueArrayWithElementsCountedComplexity(complexityArray);
    const uniquePlayers = getUniqueArrayWithElementsCounted(playersArray);

    dispatch(setCollectionPlayers(uniquePlayers));
    dispatch(setCollectionComplexity(uniqueComplexity));
    dispatch(setCollectionMechanics(uniqueMechanics));
    dispatch(setCollectionCategories(uniqueCategories));
    dispatch(setCollectionTimes(uniqueTimes));
    dispatch(setCollectionDates(datesArray));

    setTimeout(() => {
      dispatch(setIsLoadingData(false));
    }, 3000);
  };

  const processPlaysFromGames = (owned, myPlays) => {
    const newOwnedGames = owned.map((g: any) => {
      let plays = myPlays.find((p: any) => p.gameId === g.gameId);
      let playsInfo = {
        plays: 0,
        horas: 0,
        dates: [],
      };
      if (plays) {
        playsInfo.plays = plays.plays;
        playsInfo.horas = plays.horas;
        playsInfo.dates = plays.dates;
      }

      return { ...g, ...playsInfo };
    });
    dispatch(setOwnedGames(newOwnedGames));
    setUpFilters(newOwnedGames);
  };

  const bindGameDataToMyGames = (
    missingGames,
    searchedOwned,
    searchedWishlist,
    auxUserName,
    currentOwned,
    currentWishlist,
    firebaseUser
  ) => {
    let newOwnedGames = [];
    let newWishlistGames = [];
    missingGames.forEach((g: any) => {
      if (g) {
        const game = searchedOwned.find((juego) => juego.gameId === g.gameId);
        if (game) {
          let newGame = { ...game };
          if (g.mechanics) newGame["mechanics"] = g.mechanics;
          if (g.categories) newGame["categories"] = g.categories;
          if (g.publishers) newGame["publishers"] = g.publishers;
          if (g.family) newGame["family"] = g.family;
          if (g.averageweight) newGame["averageweight"] = g.averageweight;
          newGame["isExpansion"] = g.isExpansion;
          newOwnedGames.push(newGame);
        }

        const gameW = searchedWishlist.find(
          (juegoW) => juegoW.gameId === g.gameId
        );
        if (gameW) {
          let newGameW = { ...gameW };
          if (g.mechanics) newGameW["mechanics"] = g.mechanics;
          if (g.categories) newGameW["categories"] = g.categories;
          if (g.averageweight) newGameW["averageweight"] = g.averageweight;
          if (g.publishers) newGameW["publishers"] = g.publishers;
          if (g.family) newGameW["family"] = g.family;
          newGameW["isExpansion"] = g.isExpansion;
          newWishlistGames.push(newGameW);
        }
      }
    });
    const expansionGamesOwned = [...newOwnedGames, ...currentOwned].filter(
      (g) => {
        return g.isExpansion;
      }
    );

    let auxCurrentOwned = [...currentOwned];
    auxCurrentOwned.forEach((g) => {
      const game = [...searchedOwned].find(
        (juego) => juego.gameId === g.gameId
      );
      if (game) {
        g.averageRating = game.averageRating;
        g.rating = game.rating;
      }
      return g;
    });

    currentWishlist = currentWishlist.filter((wishlistGame) => {
      return searchedWishlist.find((game) => {
        return wishlistGame.gameId === game.gameId;
      });
    });

    const newOwnedGamesToAdd = [...newOwnedGames, ...auxCurrentOwned];
    const newWishListGamesToAdd = [...newWishlistGames, ...currentWishlist];

    dispatch(setOwnedExpansions(expansionGamesOwned.length));
    dispatch(setWishlistGames(newWishListGamesToAdd));
    dispatch(setOwnedGames(newOwnedGamesToAdd));
    if (firebaseUser) {
      updateWishlistAndCollection(
        firebaseUser,

        newWishListGamesToAdd,
        newOwnedGamesToAdd
      ).then((res) => {
        setTimeout(() => {
          dispatch(setIsSavingWishlistData(false));
        }, 1000);
      });
    }
    getPlaysInformation(auxUserName).then((myPlays) => {
      processPlaysFromGames(newOwnedGamesToAdd, myPlays);
    });
  };

  const addGameToCollectionOrWishlist = (game, selection) => {
    let storedOwnedGames = [];
    let storedWishlistGames = [];
    let gameInfoToSave = {
      custom: true,
      owned: selection === "collection" ? true : false,
      wishlist: selection === "wishlist" ? true : false,
    };
    if (selection === "collection") {
      storedOwnedGames = jsonParseLocalStorageKey("storedOwnedGames");
      if (storedOwnedGames.find((g) => g.gameId === game.id)) {
        return {
          status: "error",
          message: "You already have this game in your collection",
        };
      }
    }
    if (selection === "wishlist") {
      storedWishlistGames = jsonParseLocalStorageKey("storedWishlistGames");
      if (storedWishlistGames.find((g) => g.gameId === game.id)) {
        return {
          status: "error",
          message: "You already have this game in your wishlist",
        };
      }
    }

    const gameInfoFromBGGAPI = getGameInformationByGameId(game.id);
    gameInfoFromBGGAPI.then((gameInfo) => {
      const finalGameInfoToSave = {
        ...gameInfoToSave,
        ...gameInfo,
        minPlayers: gameInfo.minplayers,
        maxPlayers: gameInfo.maxplayers,
        playingTime: gameInfo.playingtime,
      };
      if (gameInfo) {
        if (selection === "collection") {
          storedOwnedGames.push(finalGameInfoToSave);
          dispatch(setOwnedGames(storedOwnedGames));
        }
        if (selection === "wishlist") {
          storedWishlistGames.push(finalGameInfoToSave);
          dispatch(setWishlistGames(storedWishlistGames));
        }
        return {
          status: "success",
          message: "Game added to your " + selection,
        };
      }
    });
  };

  const removeGameFromCollection = (gameId) => {
    let storedOwnedGames = jsonParseLocalStorageKey("storedOwnedGames");
    const newStoredOwnedGames = storedOwnedGames.filter(
      (g) => g.gameId !== gameId
    );
    dispatch(setOwnedGames(newStoredOwnedGames));
  };

  const syncAll = async (auxUserName, setShowLoader, firebaseUser) => {
    getMyCollection(auxUserName).then(async (values) => {
      let allGamesResults = allGamesJsonData as Array<any>;
      if (values === false) {
        setShowLoader(false);
        dispatch(setIsLoadingData(false));
        return;
      }
      dispatch(setIsSavingWishlistData(true));
      if (values.ownedGames || values.wishListGames) {
        let currentlyStoredOwnedGames =
          jsonParseLocalStorageKey("storedOwnedGames");

        let currentlyStoredWishlistGames = jsonParseLocalStorageKey(
          "storedWishlistGames"
        );

        let missingGames = [];
        if (currentlyStoredOwnedGames && currentlyStoredOwnedGames.length > 0) {
          let missingOwnedGames = values.ownedGames.filter(
            (g) =>
              !currentlyStoredOwnedGames.find((g2) => g.gameId === g2.gameId)
          );
          missingGames = [...missingGames, ...missingOwnedGames];
        } else {
          missingGames = [...missingGames, ...values.ownedGames];
        }
        if (
          currentlyStoredWishlistGames &&
          currentlyStoredWishlistGames.length > 0
        ) {
          let missingWishlistGames = values.wishListGames.filter(
            (g) =>
              !currentlyStoredWishlistGames.find((g2) => g.gameId === g2.gameId)
          );
          missingGames = [...missingWishlistGames, ...missingGames];
        } else {
          missingGames = [...missingGames, ...values.wishListGames];
        }

        let myCurrentlyStoredGames = [
          ...currentlyStoredOwnedGames,
          ...currentlyStoredWishlistGames,
        ];

        let indexesAQuitar = [];
        let resultsFromDB = [];
        for (const g of missingGames) {
          const missedGameFound = allGamesResults.find(
            (g2) => g.gameId === g2.gameId
          );
          if (missedGameFound) {
            myCurrentlyStoredGames.push(missedGameFound);
            resultsFromDB.push(missedGameFound);
            indexesAQuitar.push(g.gameId);
          } else {
            const missedGameFound = await checkIfGameExists(g.gameId);
            if (missedGameFound.length > 0) {
              myCurrentlyStoredGames.push(missedGameFound[0]);
              resultsFromDB.push(missedGameFound[0]);
              indexesAQuitar.push(g.gameId);
            }
          }
        }

        let newMissingGames = missingGames.filter(
          (g) => !indexesAQuitar.includes(g.gameId)
        );

        let resultsFromBGG = [];
        if (newMissingGames.length > 0) {
          resultsFromBGG = await getAllGamesInformation(newMissingGames);
        }

        bindGameDataToMyGames(
          [...resultsFromDB, ...resultsFromBGG],
          values.ownedGames,
          values.wishListGames,
          auxUserName,
          currentlyStoredOwnedGames,
          currentlyStoredWishlistGames,
          firebaseUser
        );
      }
    });
  };

  return {
    removeGameFromCollection,
    addGameToCollectionOrWishlist,
    setGamesInformationToStoredData,
    processPlaysFromGames,
    bindGameDataToMyGames,
    syncAll,
    setUpFilters,
  };
};

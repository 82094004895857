import { useAutoAnimate } from "@formkit/auto-animate/react";
import LoadingSpinner from "components/sidebar/components/LoadingSpinner";
import { useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "store/store";
import { DEFAULT_USE_AUTO_ANIMATE } from "utils/constants/constants";
import { signUpWithGoogle } from "utils/firebase-services/googleAuth";
import useTranslation from "utils/hooks/useTranslation";

export default function SignIn() {
  const { translation } = useTranslation();
  const { currentGoogleUser } = useSelector(
    (state: RootState) => state.userSlice
  );
  const [animationParent] = useAutoAnimate(DEFAULT_USE_AUTO_ANIMATE);
  const [showSignInLoader, setShowSignInLoader] = useState(false);

  const handleSignUpWithGoogle = () => {
    console.log("handleSignUpWithGoogle");
    setShowSignInLoader(true);
    signUpWithGoogle(setShowSignInLoader);
  };

  return (
    <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {currentGoogleUser ? (
        <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
          <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
            {translation.loggedAccountWelcomeMsg}
          </h4>

          <div className="mb-6 flex  gap-3">
            <p className="text-base text-gray-600 dark:text-white">
              {" "}
              {translation.loggedAccountDescriptionMsg}{" "}
            </p>
          </div>
          <Link key={"default"} to={"/mibox/collection"}>
            <div className="mb-6 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl hover:cursor-pointer hover:bg-branded-900 dark:bg-branded-900">
              <h5 className="text-sm font-medium text-navy-700 dark:text-white">
                Go to MiBox
              </h5>
            </div>
          </Link>

          <div className="mb-6 flex items-center  gap-3">
            <p className="dark:text-gray text-base text-gray-600">
              {" "}
              {translation.loggedAccountDisclaimerMsg}{" "}
            </p>
          </div>
        </div>
      ) : (
        <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
          <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
            {translation.creatingAccountsWelcomeMessage}
          </h4>
          <p className="mb-9 ml-1 text-base text-gray-600">
            {translation.creatingAccountsContentMessage}
          </p>
          {showSignInLoader && (
            <div
              ref={animationParent}
              className="mb-10 flex h-20 items-center justify-center p-2 text-navy-700 xl:w-60"
            >
              <LoadingSpinner />
              <div className="ml-5 h-full font-poppins font-bold dark:text-white">
                <span className="font-xs">
                  {translation.loadingMessageAccCreation}
                </span>
              </div>
            </div>
          )}
          <div
            className="mb-6 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl hover:cursor-pointer hover:bg-branded-900 dark:bg-navy-800"
            onClick={handleSignUpWithGoogle}
          >
            <div className="rounded-full text-xl">
              <FcGoogle />
            </div>
            <h5 className="text-sm font-medium text-navy-700 dark:text-white">
              Sign In with Google
            </h5>
          </div>
          <div className="mb-6 flex  gap-3">
            <p className="text-base text-gray-600 dark:text-white">
              {" "}
              {translation.creatingAccountsSupportMessage}{" "}
            </p>
          </div>
          <div className="mb-6 flex items-center  gap-3">
            <p className="dark:text-gray text-base text-gray-600">
              {" "}
              {translation.creatingAccountsDisclaimerMessage}{" "}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

const BoardGamesGeek = (props) => {
  const { className } = props;
  return (
    <svg
      viewBox="0 0 1500 1800"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className} items-center justify-center pl-1`}
    >
      <path d="m170.436 1398.297c-121.457-284.747-158.921-375.811-156.04-379.283 2.085-2.513 28.679-26.204 59.097-52.646 30.418-26.443 55.318-50.411 55.334-53.262.015-2.852-15.465-112.425-34.403-243.496-18.937-131.072-33.852-238.89-33.144-239.598.707-.707 240.547-89.829 532.978-198.048 292.431-108.22 535.292-198.318 539.69-200.22 4.398-1.901 7.234-2.223 6.302-.714-5.216 8.439-143.466 365.248-142.063 366.65.927.927 37.83-9.258 82.006-22.633s80.506-24.147 80.734-23.94c.228.21-16.52 126.355-37.217 280.325l-37.631 279.946 46.721 44.385c25.697 24.412 46.721 45.806 46.721 47.542 0 4.394-200.827 519.163-203.874 522.58-2.01 2.254-164.032 64.312-624.254 239.103l-21.124 8.023z" />
    </svg>
  );
};

export default BoardGamesGeek;

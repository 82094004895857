import useTranslation from "utils/hooks/useTranslation";

export default function SupportUs() {
  const { translation } = useTranslation();
  return (
    <div className="mb-16 mt-1 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-1 lg:items-center lg:justify-start">
      <div className="mt-[5vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[620px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          {translation.supportUsHeader}
        </h4>
        <p className="mb-3 ml-1 text-base text-gray-600">
          {translation.supportUsSubHeader}
        </p>
        <p className="mb-3 ml-1 text-base text-white">
          {translation.supportUsSubHeader2}
        </p>
        <p className="mb-3 ml-1 text-base text-gray-600">
          {translation.supportUsSubHeader3}
        </p>
        <p className="mb-3 ml-1 text-base text-white">
          {translation.supportUsSubHeader4}
          <p className="mb-3 ml-1 text-base text-gray-600">
            {translation.supportUsListItemOne}
          </p>
          <p className="mb-3 ml-1 text-base text-gray-600">
            {translation.supportUsListItemTwo}
          </p>
          <p className="text-white-600 mb-3 ml-1 text-base">
            {translation.supportUsListItemThree}
          </p>
        </p>
        <a
          rel="noreferrer"
          target="_blank"
          href="https://www.buymeacoffee.com/citadejuegos"
        >
          <img
            loading="lazy"
            alt="buyMeAnIcecream "
            src="https://img.buymeacoffee.com/button-api/?text=Buy me an Ice cream&emoji=🍦&slug=citadejuegos&button_colour=5F7FFF&font_colour=ffffff&font_family=Cookie&outline_colour=000000&coffee_colour=FFDD00"
          />
        </a>

        <p className="mb-3 ml-1 mt-5 text-base text-gray-600">
          {translation.supportUsThanksMsg}
        </p>
      </div>
    </div>
  );
}

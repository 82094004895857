//import React, { useState, useContext } from "react";
import LocalizedStrings from "react-localization";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import localizationStrings from "utils/localizeStrings/localizationStrings.json";
import { setCurrentLanguage } from "slices/languageSlice";

export default function useTranslation() {
  const dispatch = useDispatch();
  const setNewCurrentLanguange = (lang) => {
    localStorage.setItem("language", lang);
    dispatch(setCurrentLanguage(lang));
    translation.setLanguage(lang);
  };

  const { currentLanguage } = useSelector(
    (state: RootState) => state.languageSlice
  );
  let translation = new LocalizedStrings(localizationStrings);
  translation.setLanguage(currentLanguage);
  return { translation, setNewCurrentLanguange };
}

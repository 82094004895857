import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface newsState {
  boardGameGeekBlog: any[];
  newsForum: any[];
  boardGameGeekPodcast: any[];
  hotnessInfo: any[];

}

const initialState = {
  boardGameGeekBlog: [],
  newsForum: [],
  boardGameGeekPodcast: [],
  hotnessInfo: [],

} as newsState;

const newsSlice = createSlice({
  name: "newsSlice",
  initialState,
  reducers: {
    setBoardGameGeekBlog(state, action: PayloadAction<any[]>) {
      state.boardGameGeekBlog = action.payload;
    },
    setNewsForum(state, action: PayloadAction<any[]>) {
      state.newsForum = action.payload;
    },
    setBoardGameGeekPodcast(state, action: PayloadAction<any[]>) {
      state.boardGameGeekPodcast = action.payload;
    },
    setTheHotness(state, action: PayloadAction<any[]>) {
      state.hotnessInfo = action.payload;
    },
  },
});

export const { setBoardGameGeekBlog, setNewsForum, setBoardGameGeekPodcast,setTheHotness } =
  newsSlice.actions;
export default newsSlice.reducer;

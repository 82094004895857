import Switch from "components/switch";

const FilterChip = (props: {
  showCount: boolean;
  count: number;
  validation: boolean;
  editFilter: (event: any) => void;
  name: string;
  suffix: string;
  id: string | number;
}) => {
  const { count, validation, editFilter, name, suffix, id, showCount } = props;
  return (
    <>
      <div className="mt-2 flex items-center gap-3" key={"complexity" + name}>
        {showCount && (
          <span className="w-fill h-7 rounded-full bg-gray-800 px-3 py-1 text-sm font-normal dark:text-white">
            {count}
          </span>
        )}

        <Switch checked={validation} onChange={editFilter} id={id} />
        <label
          htmlFor="checkbox1"
          className="text-sm font-normal dark:text-white"
        >
          {name} {suffix}
        </label>
      </div>
    </>
  );
};

export default FilterChip;

import { useAutoAnimate } from "@formkit/auto-animate/react";
import Card from "components/card";
import { IoIosFlame } from "react-icons/io";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import useTranslation from "utils/hooks/useTranslation";
import { LazyLoadImage } from "react-lazy-load-image-component";
import bggPlaceHolderImg from "assets/img/loader/placeholder_img.jpg";
import { DEFAULT_USE_AUTO_ANIMATE } from "utils/constants/constants";

const TheHotness = (props) => {
  const { showCount } = props;
  const [animationParent] = useAutoAnimate(DEFAULT_USE_AUTO_ANIMATE);
  const { hotnessInfo } = useSelector((state: RootState) => state.newsSlice);
  const { translation } = useTranslation();

  return (
    <Card extra={"mt-3 !z-0 overflow-hidden"}>
      <div className="grid grid-cols-5 items-center rounded-t-3xl px-3">
        <div className="col-span-3 flex w-full flex-row p-2">
          <IoIosFlame className="h-5 w-5" />
          <div className="text-base font-bold text-navy-700 dark:text-white">
            The Hotness
          </div>
        </div>
        <div className="col-span-2 flex justify-end p-2">
          <div className="flex w-fit items-center justify-end rounded-t-3xl">
            <a
              href="https://boardgamegeek.com/hotness"
              target="blank"
              className="linear rounded-[20px] bg-lightPrimary px-4 py-1 text-sm font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
            >
              {translation.seeAll}
            </a>
          </div>
        </div>
      </div>
      <div className="p-2">
        {hotnessInfo.slice(0, showCount).map((data, index) => (
          <a
            ref={animationParent}
            key={index + data.gameId}
            href={"https://boardgamegeek.com/boardgame/" + data.gameId}
            target="blank"
            className="  w-full hover:cursor-pointer"
          >
            <div
              ref={animationParent}
              className={`border-grey-900 h-18 ml-2 flex w-full items-start justify-between border-opacity-10 ${
                index !== showCount - 1 ? "border-b-[1px]" : ""
              } !bg-navy-800 py-2 pr-3 shadow-none hover:!bg-navy-700 hover:shadow-2xl`}
            >
              <div className=" flex w-full items-start gap-3">
                <div className="flex h-full w-1/5 items-center justify-center">
                  <LazyLoadImage
                    wrapperClassName={`h-14 w-14`}
                    placeholderSrc={bggPlaceHolderImg}
                    src={data.thumbnail}
                    className="h-full w-full rounded-xl"
                    alt={data.name}
                    effect="blur"
                  />
                  <div className="absolute mb-10 mr-10 flex h-4 w-4 items-center justify-center rounded-full bg-branded-900 text-white">
                    <div className="flex items-center justify-center rounded-full text-xs font-bold dark:text-white">
                      {data.rank === -1 ? "-" : data.rank}
                    </div>
                  </div>
                </div>
                <div className=" flex w-4/5 flex-col">
                  <h6 className="text-sm font-bold text-white"> {data.name}</h6>
                  <p className="mt-1 text-xs font-normal text-gray-600">
                    {" "}
                    {data.yearPublished}{" "}
                  </p>
                </div>
              </div>
            </div>
          </a>
        ))}
      </div>
    </Card>
  );
};

export default TheHotness;

import { FiEye, FiEyeOff } from "react-icons/fi";
import { MdExtension, MdPeople } from "react-icons/md";
import {
  IoIosBusiness,
  IoIosCalendar,
  IoIosGrid,
  IoIosHourglass,
} from "react-icons/io";
import { IoHeart } from "react-icons/io5";
import WishlistCard from "components/card/WishlistCard";
import { useEffect, useState } from "react";
import useWishListTranslation from "utils/hooks/useWishListTranslation";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import useTranslation from "utils/hooks/useTranslation";
import allAsins from "../../../../catalog/allAsins.json";
import { AMAZON_AFFILIATE_TAGS } from "utils/constants/constants";

const WishlistNoAccountPreview = () => {
  const { userName } = useSelector((state: RootState) => state.userSlice);
  const { translation } = useTranslation();

  const { wishListTranslation } = useWishListTranslation();

  const { shareWishlistGames } = useSelector(
    (state: RootState) => state.gamesSlice
  );
  const [shouldShowIconInfo, setShouldShowIconInfo] = useState(false);
  const [showTurnThemAllBack, setShowTurnThemAllBack] = useState(false);

  const [wishlistCards, setWishlistCards] = useState(shareWishlistGames);
  const [asinLinks] = useState(allAsins);
  const [auxUserName, setAuxUserName] = useState(userName);
  const [currentLanguageRegion, setCurrentLanguageRegion] = useState(
    translation.getLanguage()
  );

  const [showEyeArray, setShowEyeArray] = useState([]);
  const [showEye, setShowEye] = useState(false);

  useEffect(() => {
    setCurrentLanguageRegion(translation.getLanguage());
  }, [translation]);

  useEffect(() => {
    setAuxUserName(userName);
  }, [userName]);

  useEffect(() => {
    if (shareWishlistGames.length > 0) {
      setWishlistCards(shareWishlistGames);
    }
  }, [shareWishlistGames]);

  useEffect(() => {
    const shouldShow = showEyeArray.find((p: any) => p === true);
    if (shouldShow) {
      setShowEye(true);
    } else {
      setShowEye(false);
    }
  }, [showEyeArray]);

  useEffect(() => {
    setTimeout(() => {
      setShowTurnThemAllBack(false);
      setShowEye(false);
    }, 500);
  }, [showTurnThemAllBack]);

  const showIconInformation = () => {
    setShouldShowIconInfo(!shouldShowIconInfo);
  };

  const getLinkByGameIdAndRegion = (gameId) => {
    let regionToSearch = "";
    let tagToUse = "";
    switch (translation.getLanguage()) {
      case "en":
        regionToSearch = "amazon.com";
        tagToUse = AMAZON_AFFILIATE_TAGS.US;
        break;
      case "en-can":
        regionToSearch = "amazon.ca";
        tagToUse = AMAZON_AFFILIATE_TAGS.CA;
        break;
      case "es":
        regionToSearch = "amazon.com.mx";
        tagToUse = AMAZON_AFFILIATE_TAGS.US;
        break;
    }
    const asinFound = asinLinks.find((l: any) => l.g / 1 === gameId);
    if (asinFound) {
      return (
        "https://" + regionToSearch + "/dp/" + asinFound.a + "?tag=" + tagToUse
      );
    } else {
      return "";
    }
  };

  const renderCards = () => {
    let auxWishlistCards = [...wishlistCards];
    const gameItems = auxWishlistCards
      .sort((a, b) => {
        return a.wishListPriority - b.wishListPriority;
      })
      .map((g, index) => (
        <WishlistCard
          affiliateLink={getLinkByGameIdAndRegion(g.gameId)}
          averageWeight={g.averageweight}
          eyeIndex={index}
          showEyeArray={showEyeArray}
          setShowEyeArray={setShowEyeArray}
          showTurnThemAllBack={showTurnThemAllBack}
          userObject={{ wishlist: { region: currentLanguageRegion } }}
          publishers={g.publishers}
          family={g.family}
          linkOnClick={"https://boardgamegeek.com/boardgame/" + g.gameId}
          key={g.gameId + "-" + index}
          minPlayers={g.minPlayers}
          maxPlayers={g.maxPlayers}
          rank={g.rank}
          isExpansion={g.isExpansion}
          rating={g.averageRating}
          wishlistPrio={g.wishListPriority}
          title={g.name}
          author={g.yearpublished}
          image={g.image}
          estimatedTime={g.playingTime}
        />
      ));
    return <>{gameItems}</>;
  };

  const turnAllCardsBack = () => {
    setShowTurnThemAllBack(true);
    let newShowEyeArray = [];
    wishlistCards.forEach((g) => {
      newShowEyeArray.push(false);
    });
    setShowEyeArray(newShowEyeArray);
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center rounded-xl bg-navy-900 pl-1 pr-5">
        <div className="mt-5 flex w-full items-center justify-center px-4 py-2 text-lg font-bold text-navy-700 dark:text-white">
          <p>
            {wishListTranslation.welcomeTo}
            {currentLanguageRegion === "es" &&
              wishListTranslation.wishListTopHeader}
            <span className="text-brand-500">{auxUserName}</span>
            {(currentLanguageRegion === "en" ||
              currentLanguageRegion === "en-can") &&
              wishListTranslation.wishListTopHeader}
            !
          </p>
        </div>

        <div className="flex w-full flex-col items-center justify-center px-6 text-lg font-normal text-navy-700 dark:text-white">
          <span className="flex items-center justify-center text-base font-normal text-gray-500">
            {wishListTranslation.wishListFirstHeaderMsg}
          </span>
          <span className="flex items-center justify-center text-base font-normal text-gray-500">
            {wishListTranslation.wishListSecondHeaderMsg}
          </span>
          <span className="flex items-center justify-center text-base font-normal text-gray-500">
            {wishListTranslation.wishListThirdHeaderMsg}
          </span>
        </div>

        {showEye && (
          <div className=" flex w-full items-center justify-center text-lg font-bold text-navy-700 dark:text-white">
            <span className="mb-1 flex flex-row text-sm font-normal text-gray-500 hover:cursor-pointer">
              <FiEyeOff
                className="ml-2 mr-2 h-5 w-5 text-brand-500"
                onClick={turnAllCardsBack}
              />
            </span>
          </div>
        )}

        <div className="visible flex w-full items-center justify-center text-lg font-bold text-navy-700 dark:text-white sm:invisible">
          <span className="text-xs font-normal text-gray-500 hover:cursor-pointer">
            {wishListTranslation.swipeTip}
          </span>
        </div>
        <div className="mb-10 mt-10 grid grid-cols-2 gap-5 px-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-5">
          {renderCards()}
        </div>
        <div
          className="mt-5 flex w-full flex-col items-center justify-center px-4 text-lg font-normal  hover:cursor-pointer dark:text-white"
          onClick={showIconInformation}
        >
          <span className="text-gray-700">
            {wishListTranslation.whatTheIconsMean}
          </span>
        </div>
        {shouldShowIconInfo && (
          <div className=" flex w-full flex-col items-center justify-center px-7 text-lg font-bold text-navy-700 dark:text-white md:px-44 ">
            <span className="mb-1 flex flex-row text-sm font-normal text-gray-500">
              {wishListTranslation.ClickOn}{" "}
              <FiEye className="ml-2 mr-2 h-5 w-5 text-brand-500" />{" "}
              {wishListTranslation.willOpen}
            </span>
            <span className="mb-2 flex flex-row text-sm font-normal text-gray-500">
              {wishListTranslation.gameTitle}
            </span>
            <div className="grid h-full w-full grid-cols-1 gap-2 rounded-xl px-2 md:grid-cols-4 lg:grid-cols-8">
              <span className="col-span-2 flex w-full justify-start text-sm font-normal text-gray-500 md:col-span-4 md:justify-end">
                <MdPeople className="mr-2 h-5 w-5 text-brand-500" />{" "}
                {wishListTranslation.playersMeaning}
              </span>
              <span className="col-span-2 flex w-full justify-start text-sm font-normal text-gray-500 md:col-span-4 ">
                <IoIosHourglass className="mr-2 h-5 w-5 text-brand-500 md:ml-2" />{" "}
                {wishListTranslation.playTimeMeaning}
              </span>
              <span className="col-span-2 flex justify-start text-sm font-normal text-gray-500 md:col-span-4  md:justify-end ">
                <IoIosBusiness className="mr-2 h-5 w-5 text-brand-500" /> for
                {wishListTranslation.publisherMeaning}
              </span>
              <span className="col-span-2 flex justify-start text-sm font-normal text-gray-500 md:col-span-4">
                <IoIosGrid className="mr-2 h-5 w-5 text-brand-500 md:ml-2" />{" "}
                {wishListTranslation.typeMeaning}
              </span>
              <span className="col-span-2 flex justify-start text-sm font-normal text-gray-500 md:col-span-4 md:justify-end">
                <IoHeart className="mr-2 h-5 w-5 text-brand-500 md:ml-2" />{" "}
                {wishListTranslation.prioMeaning}
              </span>
              <span className="col-span-2 flex justify-start text-sm font-normal text-gray-500 md:col-span-4">
                <MdExtension className="mr-2 h-5 w-5 text-brand-500 md:ml-2" />{" "}
                {wishListTranslation.complexityMeaning}
              </span>
              <span className="col-span-2 flex justify-center text-sm font-normal text-gray-500 md:col-span-4 md:justify-center lg:col-span-8">
                <IoIosCalendar className="mr-2 h-5 w-5 text-brand-500 md:ml-2" />{" "}
                {wishListTranslation.yearMeaning}
              </span>
            </div>
            <span className="mb-2 flex flex-row text-sm font-normal text-gray-500">
              * {wishListTranslation.expansionMeaning}
            </span>
          </div>
        )}
      </div>
    </>
  );
};

export default WishlistNoAccountPreview;

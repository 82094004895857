import { addDoc, collection } from "firebase/firestore";
import { db } from "../../firebase/credenciales";

export default async function addNewMailFromFeedback(data) {
  try {
    console.log("mailData", data);
    const collectionRef = collection(db, "mail");
    const newMailId = await addDoc(collectionRef, data);
    return newMailId;
  } catch (error) {
    console.log(error);
  }
}

export async function addNewFeedback(data) {
  try {
    console.log("mailData", data);
    const collectionRef = collection(db, "feedback");
    const newMailId = await addDoc(collectionRef, data);
    return newMailId;
  } catch (error) {
    console.log(error);
  }
}

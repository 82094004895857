import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../firebase/credenciales";

export default async function addNewGameRequest(data) {
  try {
    const collectionRef = collection(db, "gameRequests");
    const newMailId = await addDoc(collectionRef, data);
    return newMailId;
  } catch (error) {
    console.log(error);
  }
}

export const deleteGame = async (gameId) => {
  console.log("deleteGame", gameId);
  const docRef = doc(db, "gamesBD", gameId);

  deleteDoc(docRef);
};

export const checkIfGameExists = async (gameId) => {
  try {
    const collectionRef = collection(db, "gamesBD");
    const q = query(collectionRef, where("gameId", "==", gameId));
    const doc_refs = await getDocs(q);
    const res = [];
    doc_refs.forEach((user) => {
      res.push({
        id: user.id,
        ...user.data(),
      });
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const saveNewGame = async (gameInformation) => {
  try {
    if (!gameInformation) return;
    checkIfGameExists(gameInformation.gameId).then(async (res) => {
      if (res.length === 0) {
        const collectionRef = collection(db, "gamesBD");
        const newGame = await addDoc(collectionRef, gameInformation);
        return newGame;
      } else {
        return res[0];
      }
    });
  } catch (error) {
    console.log(error);
  }
};

export const getGamesBD = async () => {
  try {
    const collectionRef = collection(db, "gamesBD");
    const doc_refs = await getDocs(collectionRef);
    const res = [];
    doc_refs.forEach((user) => {
      res.push({
        id: user.id,
        ...user.data(),
      });
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const checkForDuplicates = async (gamesInDB) => {
  let aux = [...gamesInDB];
  const lookup = aux.reduce((a, e) => {
    a[e.gameId] = ++a[e.gameId] || 0;
    return a;
  }, {});

  console.log(aux.filter((e) => lookup[e.gameId]).length);
  aux
    .filter((e) => lookup[e.gameId])
    .forEach((e) => {
      checkIfGameExists(e.gameId);
    });
  return gamesInDB;
};

export const updateGameImgAndThumbnail = async (gameKey, img, thumbnail) => {
  try {
    const docRef = doc(db, "gamesBD", gameKey);
    await updateDoc(docRef, {
      img: img,
      thumbnail: thumbnail,
    });
  } catch (error) {
    console.log(error);
  }
};

import { Link } from "react-router-dom";
import useTranslation from "utils/hooks/useTranslation";

const Footer = () => {
  const { translation } = useTranslation();
  return (
    <div className="col-span-5 flex w-full flex-col px-4 pb-4 md:col-span-1 lg:col-span-5 lg:px-8 xl:col-span-5 2xl:col-span-5">
      <h5 className="col-span-5 mb-4 px-2 text-center text-sm font-medium text-gray-600 sm:!mb-0 md:col-span-5 md:text-lg lg:col-span-5">
        <p className="mb-4 px-2 text-center text-sm text-gray-600 sm:!mb-0 md:text-base">
          {translation.footerDisclaimer}
        </p>
      </h5>

      <div>
        <ul className="flex flex-wrap items-center justify-between gap-10 sm:flex-nowrap md:gap-10">
          <li>
            <Link key={"supportUs"} to={"/mibox/codex-ai-pricing"}>
              <div className="text-base font-medium text-brand-600 hover:text-brand-200">
                CodexAI Pricing
              </div>
            </Link>
          </li>
          <li>
            <Link key={"feedback"} to={"/mibox/feedback"}>
              <div className="text-base font-medium text-brand-600 hover:text-brand-200">
                {translation.footerFeedback}
              </div>
            </Link>
          </li>
          <li>
            <Link key={"terms-footer"} to={"/mibox/terms-of-use"}>
              <div className="text-base font-medium text-gray-600 hover:text-brand-300">
                {translation.footerTermsOfUse}
              </div>
            </Link>
          </li>
          <li className="text-brand-500">
            {translation.footerMessage}
            <a
              rel="noreferrer"
              target="_blank"
              href="https://www.youtube.com/channel/UC4L8DeqoDbP4kXn2EGXc90Q"
              className="text-base font-medium text-white"
            >
              {" "}
              ©{new Date().getFullYear()} Cita de Juegos{" "}
            </a>
          </li>
          <li className="text-brand-500">
            <Link key={"terms-footer"} to={"/mibox/release-notes"}>
              <div className="hover:text-white-300 text-base font-medium text-brand-600">
                v0.0.6
              </div>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;

import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { DEFULT_USERNAME } from "utils/constants/constants";
import { CodexSubscription, UserObject } from "utils/constants/interfaces";

interface UserInfoState {
  userName: string;
  isLoadingData: boolean;
  isLoadingUserData: boolean;
  currentGoogleUser: UserObject;
  codexSubscription: CodexSubscription;
  isSavingWishlistData: boolean;
}

const initialState = {
  userName: localStorage.getItem("bgg_username")
    ? localStorage.getItem("bgg_username")
    : DEFULT_USERNAME,
  isLoadingData: false,
  isLoadingUserData: true,
  currentGoogleUser: null,
  codexSubscription: null,
  isSavingWishlistData: false,
} as UserInfoState;

const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    changeUserName(state, action: PayloadAction<string>) {
      state.userName = action.payload;
    },
    setIsLoadingData(state, action: PayloadAction<boolean>) {
      state.isLoadingData = action.payload;
    },
    setIsLoadingUserData(state, action: PayloadAction<boolean>) {
      state.isLoadingUserData = action.payload;
    },
    setCurrentGoogleUser(state, action: PayloadAction<any>) {
      state.currentGoogleUser = action.payload;
    },
    setCodexSubscription(state, action: PayloadAction<any>) {
      state.codexSubscription = action.payload;
    },
    setIsSavingWishlistData(state, action: PayloadAction<boolean>) {
      state.isSavingWishlistData = action.payload;
    },
  },
});

export const {
  setIsSavingWishlistData,
  setIsLoadingUserData,
  setCurrentGoogleUser,
  changeUserName,
  setIsLoadingData,
  setCodexSubscription,
} = userSlice.actions;
export default userSlice.reducer;

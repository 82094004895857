import axios from "axios";
import { useDispatch } from "react-redux";
import {
  setBoardGameGeekBlog,
  setBoardGameGeekPodcast,
} from "slices/newsSlice";
import { setTheHotness } from "slices/newsSlice";
import {
  setGamesInformation,
  setHorasJugadas,
  setJuegosJugados,
  setMyPlays,
} from "slices/gamesSlice";
import {
  parseCollectionElementsToJson,
  parseGameElementsToJson,
  parsePlaysElementsToJson,
} from "utils/functions/parseElementsToJson";
import convert from "xml-js";
import useTranslation from "utils/hooks/useTranslation";
import { saveNewGame } from "utils/firebase-services/gamesService";
import { delay } from "utils/functions/utils";
import {
  saveBanAsin,
  saveNewAsin,
} from "utils/firebase-services/catalogService";

export const AxiosRequests = () => {
  const dispatch = useDispatch();
  const { translation } = useTranslation();

  const getTheHotness = () => {
    return axios
      .get(`https://bgg-json.azurewebsites.net/hot`)
      .then((res: any) => {
        dispatch(setTheHotness(res.data));
      })
      .catch((err) => {
        console.log("The Hotness", err);
      });
  };

  const getNewsForumInformation = async () => {
    /* try {
      const forum = await client.forum.query({ id: 19, page: 3 });
      const forumlists: BggForumlistDto[] = await client.forumlist.query({
        id: 8374,
        type: ["family"],
      });

      return forum;
    } catch (e) {
      console.log(e);
      return e;
    } */
    /* return axios
      .post(
        `https://us-central1-citadejuegos-7cdbd.cloudfunctions.net/testWithOnRequestGetBggForumThreadsById`,
        { forumId: 19 },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res: any) => {
        const json = convert.xml2json(res.data);
        const jsonParsed = JSON.parse(json);

        let jsonElementsParsed;
        if (jsonParsed.elements[0].elements[0].elements.length > 0) {
          jsonElementsParsed = parseForumElementsToJson(
            jsonParsed.elements[0].elements[0].elements
          );
          dispatch(setNewsForum(jsonElementsParsed));
        }
        console.log("jsonElementsParsed", jsonElementsParsed);
        return jsonElementsParsed;
      })
      .catch((err) => {
        console.log(err);
      }); */
  };

  const getBoardGameGeedNewsBlog = () => {
    const corsUrl = "https://api.rss2json.com/v1/api.json?rss_url=";
    const url = "https://boardgamegeek.com/rss/blog/1";

    return axios
      .get(`${corsUrl}${url}`)
      .then((res: any) => {
        dispatch(setBoardGameGeekBlog(res.data.items));
      })
      .catch((err) => {
        console.log("BoardGameGeedNewsBlog", err);
      });
  };

  const getBoardGameGeedPodcast = () => {
    const corsUrl = "https://api.rss2json.com/v1/api.json?rss_url=";
    const url = "https://boardgamegeek.com/rss/blog/12153";

    return axios
      .get(`${corsUrl}${url}`)
      .then((res: any) => {
        dispatch(setBoardGameGeekPodcast(res.data.items));
      })
      .catch((err) => {
        console.log("BoardGameGeekPodcast", err);
      });
  };

  const getMyCollection = (auxUserName): any => {
    return axios
      .get(
        `https://api.geekdo.com/xmlapi2/collection?stats=1&username=` +
          auxUserName
      )
      .then((res: any) => {
        if (res.status === 202) {
          alert(
            "Your Collection request is being proccesed by BGG, please try again in 15 seconds."
          );
          return false;
        } else {
          const json = convert.xml2json(res.data);
          const jsonParsed = JSON.parse(json);
          let jsonElementsParsed;
          if (jsonParsed.elements[0].elements.length > 0) {
            jsonElementsParsed = parseCollectionElementsToJson(
              jsonParsed.elements[0].elements
            );
          }

          return {
            myGames: jsonElementsParsed,
            wishListGames: jsonElementsParsed.filter((g: any) => g.wishList),
            ownedGames: jsonElementsParsed.filter((g: any) => g.owned),
          };
        }
      })
      .catch((err) => {
        alert(translation.axiosGeneralErrorMsg);
        console.log(err);
        return err;
      });
  };

  const getGameInformation = (gameId: number) => {
    return axios
      .get(`https://boardgamegeek.com/xmlapi2/thing?id=` + gameId + `&stats=1`)
      .then((res: any) => {
        const json = convert.xml2json(res.data);
        const jsonParsed = JSON.parse(json);

        let jsonElementsParsed;
        if (jsonParsed.elements[0].elements[0].elements.length > 0) {
          jsonElementsParsed = parseGameElementsToJson(
            jsonParsed.elements[0].elements[0].elements
          );
          jsonElementsParsed["gameId"] = gameId;
          dispatch(setGamesInformation(jsonElementsParsed));
        }
        return jsonElementsParsed;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getGameInformationByGameId = (gameId: number) => {
    return axios
      .get(`https://boardgamegeek.com/xmlapi2/thing?id=` + gameId + `&stats=1`)
      .then((res: any) => {
        const json = convert.xml2json(res.data);
        const jsonParsed = JSON.parse(json);

        let jsonElementsParsed;
        if (jsonParsed.elements[0].elements[0].elements.length > 0) {
          jsonElementsParsed = parseGameElementsToJson(
            jsonParsed.elements[0].elements[0].elements
          );
          jsonElementsParsed["gameId"] = gameId;
        }
        return jsonElementsParsed;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllGamesInformation = async (missingGames) => {
    let results = [];
    for (const g of missingGames) {
      const result = await getGameInformation(g.gameId);
      await delay(5000);
      if (result) {
        saveNewGame(result);
        results.push(result);
      }
    }

    return results;
  };

  const getPlaysInformation = (auxUserName) => {
    return axios
      .get(`https://api.geekdo.com/xmlapi2/plays?username=` + auxUserName)
      .then((res: any) => {
        try {
          const json = convert.xml2json(res.data);
          const jsonParsed = JSON.parse(json);
          if (jsonParsed.elements.length > 0) {
            const jsonElementsParsed = parsePlaysElementsToJson(
              jsonParsed.elements[0].elements
            );
            dispatch(setMyPlays(jsonElementsParsed.json_to_save));
            dispatch(setHorasJugadas(jsonElementsParsed.horas));
            dispatch(setJuegosJugados(jsonElementsParsed.partidas));

            return jsonElementsParsed.json_to_save;
          }
        } catch (e) {
          console.log(e);
          return e;
        }
      });
  };

  const getAsinFromScrap = (gameId) => {
    return axios
      .get("https://scrapsite-cfbfp4of2a-uc.a.run.app?gameId=" + gameId)
      .then(({ data }) => {
        try {
          console.log("data", data);
          return data.data;
        } catch (e) {
          console.log("e", e);
          return e;
        }
      })
      .catch((err) => {
        console.log("err", err);
        return err;
      });
  };

  const getWishlistAsins = async (wishlistGames) => {
    let results = [];
    for (const g of wishlistGames) {
      const result = await getAsinFromScrap(g.gameId);
      console.log("result", result);
      if (result && result.name !== "AxiosError") {
        console.log("result", result);
        saveNewAsin(result, g.gameId);
        results.push({ asin: result, gameId: g.gameId });
      } else {
        saveBanAsin(g.gameId);
      }
    }

    return results;
  };
  return {
    getGameInformationByGameId,
    getWishlistAsins,
    getBoardGameGeedPodcast,
    getBoardGameGeedNewsBlog,
    getTheHotness,
    getMyCollection,
    getPlaysInformation,
    getAllGamesInformation,
    getNewsForumInformation,
  };
};

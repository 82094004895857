// Admin Imports
import MainDashboard from "views/admin/default";
/* import Profile from "views/admin/profile"; */
/* import SignIn from "views/auth/SignIn"; */
import { IoCart, IoHeart } from "react-icons/io5";
import {
  MdDashboard,
  MdIcecream,
  MdLock,
  /*   MdPerson,
  MdLock, */
} from "react-icons/md";
import TermsOfUse from "views/admin/terms/TermsOfUse";
import {
  IoIosSend,
  IoLogoGoogle,
  IoMdColorWand,
  IoMdHelp,
} from "react-icons/io";
import { GiSpellBook } from "react-icons/gi";
import ReleaseNotes from "components/releaseNotes";
import Feedback from "views/admin/feedback/Feedback";
import SignIn from "views/admin/signin/SignIn";
import BoardGamesGeek from "components/icons/BoardGameGeekIcon";
import News from "views/admin/news";
import WishList from "views/admin/wishlist";
import WishlistPreview from "views/admin/wishlist/components/WishlistPreview";
import SupportUs from "views/admin/supportUs/SupportUs";
import FeelingLost from "views/admin/feelingLost/FeelingLost";
import CollectionPreview from "views/admin/wishlist/components/CollectionPreview";
import Redirect from "views/redirect/Redirect";
import Codex from "views/admin/codex";
import Pricing from "views/admin/pricing";

const routes = [
  {
    name: "Sign In",
    layout: "/mibox",
    path: "sign-in",
    icon: <IoLogoGoogle className="h-6 w-6" />,
    component: <SignIn />,
  },
  {
    name: "MiBox",
    layout: "/mibox",
    path: "collection",
    icon: <MdDashboard className="h-6 w-6" />,
    component: <MainDashboard />,
  },

  {
    name: "Wishlist",
    layout: "/mibox",
    path: "wish-list",
    icon: <IoHeart className="h-6 w-6" />,
    component: <WishList />,
    secondary: true,
  },
  {
    name: "Codex AI",
    layout: "/mibox",
    path: "codex-ai",
    icon: <GiSpellBook className="h-6 w-6" />,
    component: <Codex />,
  },
  /*   {
    name: "All Games",
    layout: "/admin",
    path: "all-games",
    icon: <IoHeart className="h-6 w-6" />,
    component: <AffiliateLinks />,
    secondary: true,
  }, */
  {
    name: "BGG News",
    layout: "/mibox",
    path: "bgg-news",
    icon: <BoardGamesGeek className="h-6 w-6" />,
    component: <News />,
    secondary: true,
  },
  {
    name: "Terms of Use",
    layout: "/mibox",
    path: "terms-of-use",
    icon: <MdLock className="h-6 w-6" />,
    component: <TermsOfUse />,
  },
  {
    name: "Release Notes",
    layout: "/mibox",
    path: "release-notes",
    icon: <IoMdColorWand className="h-6 w-6" />,
    component: <ReleaseNotes version="v0.0.6" />,
  },
  {
    name: "Codex AI Pricing",
    layout: "/mibox",
    path: "codex-ai-pricing",
    icon: <IoCart className="h-6 w-6" />,
    component: <Pricing />,
  },
  {
    name: "FAQ",
    layout: "/mibox",
    path: "feeling-lost",
    icon: <IoMdHelp className="h-6 w-6" />,
    component: <FeelingLost />,
  },
  {
    name: "Feedback",
    layout: "/mibox",
    path: "feedback",
    icon: <IoIosSend className="h-6 w-6" />,
    component: <Feedback />,
  },
  {
    name: "Support Us",
    layout: "/mibox",
    path: "support-us",
    icon: <MdIcecream className="h-6 w-6" />,
    component: <SupportUs />,
  },

  {
    name: "My Wishlist",
    layout: "/features",
    path: "my-wishlist",
    icon: <MdLock className="h-6 w-6" />,
    component: <WishlistPreview />,
  },
  {
    name: "My Collection",
    layout: "/features",
    path: "my-collection",
    icon: <MdLock className="h-6 w-6" />,
    component: <CollectionPreview />,
  },
  {
    name: "Redirect",
    layout: "/features",
    path: "redirect",
    icon: <MdLock className="h-6 w-6" />,
    component: <Redirect />,
  },
  /* {
    name: "Profile",
    layout: "/admin",
    path: "profile",
    icon: <MdPerson className="h-6 w-6" />,
    component: <Profile />,
  }, */
  /* 
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
  } ,
  {
    name: "RTL Admin",
    layout: "/rtl",
    path: "rtl",
    icon: <MdHome className="h-6 w-6" />,
    component: <RTLDefault />,
  }, */
];
export default routes;

import Card from "components/card";
import { IoIosCalendar } from "react-icons/io";
import { MdClose, MdExtension } from "react-icons/md";
import useTranslation from "utils/hooks/useTranslation";
import { LazyLoadImage } from "react-lazy-load-image-component";
import bggPlaceHolderImg from "assets/img/loader/placeholder_img.jpg";
import { SyncData } from "utils/hooks/syncData";
import { useState } from "react";
import { isMobile } from "react-device-detect";
const GameCard = (props: {
  gameId?: string;
  custom?: boolean;
  plays?: number;
  horas?: number;
  dates?: string;
  linkOnClick?: string;
  image: string;
  title: string;
  author: string;
  download?: string;
  price?: string | number;
  rating?: string | number;
  myRating?: string | number;
  extra?: string;
  minPlayers?: string | number;
  maxPlayers?: string | number;
  rank?: string | number;
  isExpansion?: boolean;
  estimatedTime?: string | number;
}) => {
  const {
    gameId,
    custom,
    plays,
    horas,
    dates,
    linkOnClick,
    title,
    author,
    image,
    extra,
    rating,
    myRating,
    minPlayers,
    maxPlayers,
    rank,
    isExpansion,
    estimatedTime,
  } = props;

  const { translation } = useTranslation();
  const { removeGameFromCollection } = SyncData();
  const [removeGameLoader, setRemoveGameLoader] = useState(false);
  const removeGame = async () => {
    const answer = window.confirm(
      "Do you really want to remove " + title + " from your collection?"
    );
    if (answer) {
      setRemoveGameLoader(true);
      await removeGameFromCollection(gameId);
      setTimeout(() => {
        setRemoveGameLoader(false);
      }, 1000);
    }
  };
  return (
    <Card
      extra={`flex flex-col w-full h-full bg-white relative hover:translate-y-1 transition ${extra}`}
    >
      <a
        href={linkOnClick}
        target="blank"
        className=" group hover:cursor-pointer"
      >
        <div className="h-44 w-full hover:cursor-pointer">
          <div className="relative w-full">
            <LazyLoadImage
              wrapperClassName={`h-44 w-full`}
              placeholderSrc={bggPlaceHolderImg}
              src={image}
              className="h-44 w-full rounded-xl object-cover"
              alt={title}
              effect="blur"
            />

            <div className="absolute left-0 top-0 flex items-center justify-center rounded-br-lg rounded-tl-lg bg-branded-900 p-2 text-white">
              <div className="w-fill flex h-3 items-center justify-center rounded-full text-sm dark:text-white">
                {rank === -1 ? "-" : "# " + rank}
              </div>
            </div>
            {!custom && (
              <>
                <div className="absolute right-0 top-0 flex items-center justify-center rounded-tr-lg bg-branded-900 p-2 text-white">
                  <div className="flex h-3 w-7 items-center justify-center rounded-l-lg  text-sm dark:text-white">
                    {rating === -1 ? "-" : Number(rating).toFixed(1)}
                  </div>
                </div>
                <div className="absolute right-11 top-0 flex items-center justify-center rounded-bl-lg bg-brand-500 p-2 text-white">
                  <div className="flex h-3 w-7 items-center justify-center rounded-r-lg text-sm dark:text-white">
                    {myRating === -1 ? "-" : Number(myRating).toFixed(1)}
                  </div>
                </div>
              </>
            )}

            {isExpansion && (
              <div className="absolute right-0 top-7 flex items-center justify-center rounded-bl-lg bg-red-500 p-2 text-white ">
                <div className="w-15 flex h-2 items-center justify-center rounded-full text-sm text-white">
                  {translation.expansion}
                </div>
              </div>
            )}
            <div
              className={`absolute bottom-6 left-0 flex h-fit w-full flex-col bg-branded-800 bg-opacity-80 px-1 pb-2 pt-0.5 text-sm  text-white ${
                !isMobile && "opacity-0 group-hover:opacity-100"
              }`}
            >
              <div className="justify-start px-1 font-bold">{title}</div>
              <div className="flex h-fit w-full items-center justify-between px-1 text-[11px]">
                <div>
                  {minPlayers}-{maxPlayers + " " + translation.players}
                </div>
                <div>{estimatedTime} min</div>
                <div>{author}</div>
              </div>
            </div>
          </div>

          <div className="absolute bottom-0 left-0 flex w-full items-center justify-between rounded-b-lg bg-branded-800 px-0.5 py-2 text-white md:p-1">
            <div className="ml-1 flex h-3 items-center justify-center rounded-full text-[11px] dark:text-white">
              <MdExtension className="mr-1 h-3 w-3" />{" "}
              {plays === 0 || !plays ? "-" : plays}
            </div>
            <div className="flex h-3 items-center justify-center rounded-full text-[11px] dark:text-white">
              {horas >= 180 && horas
                ? (horas / 60).toFixed(0) + " hr"
                : horas
                ? horas + " min"
                : "-"}
            </div>
            <div className="mr-1 flex h-3 items-center justify-center rounded-full text-[11px] capitalize dark:text-white">
              <IoIosCalendar className="mr-1 h-3 w-3" />{" "}
              {dates === "" || !dates ? "-" : dates}
            </div>
          </div>
        </div>
      </a>
      {custom && (
        <>
          <div
            onClick={(e) => {
              e.stopPropagation();
              removeGame();
            }}
            className="absolute right-0 top-0 flex cursor-pointer items-center justify-center rounded-tr-lg bg-red-600 p-2 text-white"
          >
            <div className="flex h-3 w-7 items-center justify-center rounded-l-lg  text-sm dark:text-white">
              <MdClose className="h-5 w-5" />
            </div>
          </div>
        </>
      )}
      {removeGameLoader && (
        <div className="bg-black absolute left-0 top-0 flex h-full w-full items-center justify-center bg-opacity-50">
          <div className="h-12 w-12 animate-spin rounded-full border-b-2 border-t-2 border-red-500"></div>
        </div>
      )}
    </Card>
  );
};

export default GameCard;

import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { jsonParseLocalStorageKey } from "utils/functions/utils";

interface GamesState {
  myGames: any[];
  ownedGames: any[];
  wishListGames: any[];
  shareWishlistGames: any[];
  myPlays: any[];
  gamesInformation: any[];
  horasJugadas: number;
  juegosJugados: number;
  ownedExpansions: number;
}

const initialState = {
  myGames: [],
  ownedGames: jsonParseLocalStorageKey("storedOwnedGames"),
  wishListGames: jsonParseLocalStorageKey("storedWishlistGames"),
  shareWishlistGames: [],
  myPlays: [],
  gamesInformation: [],
  horasJugadas: 0,
  juegosJugados: 0,
  ownedExpansions: 0,
} as GamesState;

const gamesSlice = createSlice({
  name: "gamesSlice",
  initialState,
  reducers: {
    setOwnedGames(state, action: PayloadAction<any[]>) {
      localStorage.setItem("storedOwnedGames", JSON.stringify(action.payload));
      state.ownedGames = action.payload;
    },
    setWishlistGames(state, action: PayloadAction<any[]>) {
      localStorage.setItem(
        "storedWishlistGames",
        JSON.stringify(action.payload)
      );
      state.wishListGames = action.payload;
    },
    setShareWishlistGames(state, action: PayloadAction<any[]>) {
      state.shareWishlistGames = action.payload;
    },
    setMyPlays(state, action: PayloadAction<any[]>) {
      localStorage.setItem("storedMyPlays", JSON.stringify(action.payload));
      state.myPlays = action.payload;
    },
    setGamesInformation(state, action: PayloadAction<any>) {
      if (localStorage.getItem("gamesInfo")) {
        let storedGames = jsonParseLocalStorageKey("gamesInfo");
        storedGames.push(action.payload);
        localStorage.setItem("gamesInfo", JSON.stringify(storedGames));
      } else {
        localStorage.setItem("gamesInfo", JSON.stringify([action.payload]));
      }
      state.gamesInformation.push(action.payload);
    },
    setAllGamesInformation(state, action: PayloadAction<any>) {
      state.gamesInformation = action.payload;
    },
    setHorasJugadas(state, action: PayloadAction<number>) {
      localStorage.setItem(
        "storedHorasJugadas",
        JSON.stringify(action.payload)
      );
      state.horasJugadas = action.payload;
    },
    setJuegosJugados(state, action: PayloadAction<number>) {
      localStorage.setItem(
        "storedJuegosJugados",
        JSON.stringify(action.payload)
      );
      state.juegosJugados = action.payload;
    },
    setOwnedExpansions(state, action: PayloadAction<number>) {
      localStorage.setItem("ownedExpansions", JSON.stringify(action.payload));
      state.ownedExpansions = action.payload;
    },
    resetAllData(state, action: PayloadAction<any[]>) {
      state.ownedGames = action.payload;
      state.wishListGames = action.payload;
      state.myGames = action.payload;
      state.myPlays = action.payload;
    },
  },
});

export const {
  setShareWishlistGames,
  setAllGamesInformation,
  setOwnedExpansions,
  setHorasJugadas,
  setJuegosJugados,
  setWishlistGames,
  setOwnedGames,
  setMyPlays,
  setGamesInformation,
  resetAllData,
} = gamesSlice.actions;
export default gamesSlice.reducer;

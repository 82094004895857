import { useAutoAnimate } from "@formkit/auto-animate/react";
import Card from "components/card";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import useTranslation from "utils/hooks/useTranslation";
import { LazyLoadImage } from "react-lazy-load-image-component";
import bggPlaceHolderImg from "assets/img/loader/placeholder_img.jpg";
import { SelectedGame } from "utils/constants/interfaces";
import { DEFAULT_USE_AUTO_ANIMATE } from "utils/constants/constants";

interface CodexCardProps {
  setOpenModal: (value: boolean) => void;
  setGameRequest: (value: string) => void;
  selectedGame: SelectedGame;
  setSelectedGame: (game: SelectedGame) => void;
  image: string;
  title: string;
  isExpansion?: boolean;
}

const CodexCard = (props: CodexCardProps) => {
  const {
    title,
    image,
    isExpansion,
    setSelectedGame,
    selectedGame,
    setOpenModal,
    setGameRequest,
  } = props;
  const { translation } = useTranslation();
  const [showTitle, setShowTitle] = useState(false);
  const [animationParent] = useAutoAnimate(DEFAULT_USE_AUTO_ANIMATE);
  const { ai_assistants } = useSelector((state: RootState) => state.codexSlice);
  const [supportedGame] = useState(ai_assistants[title]);

  const handleTapCard = () => {
    if (supportedGame) {
      setSelectedGame({
        title: title,
        image: image,
      });
    } else {
      setGameRequest(title);
      setOpenModal(true);
    }
  };

  const extraCss =
    selectedGame.title === title
      ? "border-brand-500 border-[5px]"
      : "border-branded-900 border-[2px]";

  return (
    <Card
      extra={`flex flex-col w-14 h-14 sm:w-16 sm:h-16 2xl:w-16 2xl:h-16 rounded-xl hover:translate-y-1 rounded-[10px] z-40 transition ${extraCss}`}
    >
      <div
        onClick={() => {
          handleTapCard();
        }}
        onMouseEnter={() => {
          setShowTitle(true);
        }}
        onMouseLeave={() => {
          setShowTitle(false);
        }}
        ref={animationParent}
        className="  h-full w-full hover:cursor-pointer"
        style={{
          backgroundImage: `url(${image})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <>
          <LazyLoadImage
            wrapperClassName={`h-full w-full`}
            placeholderSrc={bggPlaceHolderImg}
            src={image}
            className="mb-3 h-full w-full rounded-lg object-fill"
            alt={title}
            effect="blur"
          />
          {!supportedGame && (
            <div className="absolute right-0 top-2 flex h-fit w-full rounded-bl-lg rounded-br-lg bg-brand-900 bg-opacity-70 p-1 text-white">
              <div className="flex h-fit items-center justify-center rounded-full text-xs font-bold">
                SOON
              </div>
            </div>
          )}
          {showTitle && (
            <div className="absolute bottom-0 right-0 flex h-fit w-full rounded-bl-lg rounded-br-lg bg-navy-900 bg-opacity-70 p-1 text-white">
              <div className="flex h-fit items-center justify-center rounded-full text-xs font-bold">
                {title}
              </div>
            </div>
          )}

          <div className="absolute bottom-0 right-0 flex w-fit items-center justify-between  overflow-hidden bg-navy-900 bg-opacity-70 md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col xl:items-start 3xl:flex-row 3xl:justify-between">
            {isExpansion && (
              <div className="flex items-center justify-center rounded-lg bg-red-500 p-1 text-white">
                <div className="w-15 flex h-2  items-center justify-center rounded-full text-[10px] dark:text-white sm:text-xs">
                  {translation.expansion}
                </div>
              </div>
            )}
          </div>
        </>
      </div>
    </Card>
  );
};

export default CodexCard;

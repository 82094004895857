import Card from "components/card";

const Widget = (props: {
  showSavingWishlistLoader?: boolean;
  icon: JSX.Element;
  title: string;
  subtitle: string;
}) => {
  const { icon, title, subtitle, showSavingWishlistLoader } = props;
  return (
    <Card extra="!flex-row flex-grow items-center rounded-[20px]">
      <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
        <div className="rounded-full bg-lightPrimary p-3 dark:bg-navy-700">
          <span className="flex items-center text-brand-500 dark:text-white">
            {icon}
          </span>
        </div>
      </div>

      <div className="h-50 ml-4 flex w-auto flex-col justify-center">
        <p className="font-dm text-sm font-medium text-gray-600">{title}</p>
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          {showSavingWishlistLoader ? (
            <l-reuleaux
              size="20"
              stroke="5"
              stroke-length="0.15"
              bg-opacity="0.1"
              speed="1.2"
              color="orange"
            ></l-reuleaux>
          ) : (
            subtitle
          )}
        </h4>
      </div>
    </Card>
  );
};

export default Widget;

interface PaginationButtonProps {
  option: number;
  pagination: number;
  setPagination: (value: number) => void;
  animationParent?: any;
}
const PaginationButton = (props: PaginationButtonProps) => {
  const { option, pagination, setPagination, animationParent } = props;
  return (
    <button
      ref={animationParent}
      className={
        "mb-2 ml-6 h-7 w-7 rounded-full  text-sm font-bold text-white transition duration-200 " +
        (option === pagination ? "bg-brand-500" : "bg-red-500")
      }
      onClick={() => setPagination(option)}
    >
      {option}
    </button>
  );
};

export default PaginationButton;

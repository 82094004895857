import {
  collection,
  doc,
  getDocs,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase/credenciales";

export const getAssistants = async () => {
  try {
    const collectionRef = collection(db, "ai_assistants");
    const doc_refs = await getDocs(collectionRef);
    let res = {};
    doc_refs.forEach((record) => {
      res = record.data().live;
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const checkIfUserCodexHistoryCollectionExists = async (userId) => {
  try {
    const collectionRef = collection(db, "user_codex_history");
    const q = getDocs(collectionRef);
    const doc_refs = await q;
    let res = [];
    doc_refs.forEach((user) => {
      res.push({
        id: user.id,
        ...user.data(),
      });
    });
    const userExists = res.find((user) => user.id === userId);
    return userExists;
  } catch (error) {
    console.log(error);
  }
};

export const getCodexHistoryInformation = async (userId) => {
  try {
    const collectionRef = collection(db, "user_codex_history");
    const q = getDocs(collectionRef);
    const doc_refs = await q;
    let res = [];
    doc_refs.forEach((user) => {
      res.push({
        id: user.id,
        ...user.data(),
      });
    });
    const userInfo = res.find((user) => user.id === userId);
    if (!userInfo) return { threadInfo: {}, messagesInfo: {} };
    return { threadInfo: userInfo.threads, messagesInfo: userInfo.messages };
  } catch (error) {
    console.log(error);
  }
};

export const saveUserCodexHistory = async (
  threadInfo,
  messagesInfo,
  userId
) => {
  try {
    const exists = await checkIfUserCodexHistoryCollectionExists(userId);
    if (exists) {
      const user_codex_history = doc(db, "user_codex_history", userId);
      await updateDoc(user_codex_history, {
        threads: threadInfo,
        messages: messagesInfo,
      });
    } else {
      setDoc(doc(db, "user_codex_history", userId), {
        threads: threadInfo,
        messages: messagesInfo,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const updateFreeQuestions = async (userId, newNumFreeQuestions) => {
  try {
    const user_codex_history = doc(db, "users", userId);
    await updateDoc(user_codex_history, {
      freeQuestions: newNumFreeQuestions,
    });
  } catch (error) {
    console.log(error);
  }
};

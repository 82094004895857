import { HiX } from "react-icons/hi";
import Links from "./components/Links";
import SidebarCard from "components/sidebar/components/SidebarCard";
import routes from "routes";
import { MdPlayArrow } from "react-icons/md";

const Sidebar = (props: {
  open: boolean;
  onClose: React.MouseEventHandler<HTMLSpanElement>;
}) => {
  const { open, onClose } = props;

  return (
    <div
      className={`sm:none duration-175 linear fixed !z-50 flex min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${
        open ? "translate-x-0" : "-translate-x-96"
      }`}
    >
      <span
        className="absolute right-4 top-4 block cursor-pointer xl:hidden"
        onClick={onClose}
      >
        <HiX />
      </span>

      <div className={`mx-[56px] mt-[30px] flex items-center`}>
        <div className="font-poppins text-[20px] font-bold text-navy-700 dark:text-white">
          Mi<span className="font-medium">BOX</span>
        </div>
        <button
          className="ml-2.5 hidden items-center space-x-1 rounded-md bg-branded-900 px-2 py-1  text-xs font-semibold text-white md:block md:flex"
          onClick={() => {
            window.open("https://citadejuegos.navattic.com/mibox", "_blank");
          }}
        >
          <MdPlayArrow className="h-4 w-4" /> Demo
        </button>
      </div>

      <div className="mb-7 mt-5 h-px bg-gray-300 dark:bg-white/30" />

      <ul className="mb-auto pt-1">
        <Links routes={routes} />
      </ul>

      <div className="flex justify-center">
        <SidebarCard />
      </div>
    </div>
  );
};

export default Sidebar;

import { useAutoAnimate } from "@formkit/auto-animate/react";
import Card from "components/card";
import BoardGamesGeek from "components/icons/BoardGameGeekIcon";
import { useEffect, useState } from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { HiX } from "react-icons/hi";
import {
  IoIosBusiness,
  IoIosCalendar,
  IoIosGrid,
  IoIosHourglass,
} from "react-icons/io";
import { IoLogoAmazon } from "react-icons/io5";
import { MdExtension, MdPeople, MdStar } from "react-icons/md";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import {
  AMAZON_SITE_OPTIONS,
  DEFAULT_USE_AUTO_ANIMATE,
} from "utils/constants/constants";
import useTranslation from "utils/hooks/useTranslation";
import useWishListTranslation from "utils/hooks/useWishListTranslation";
import { LazyLoadImage } from "react-lazy-load-image-component";
import bggPlaceHolderImg from "assets/img/loader/placeholder_img.jpg";

const CollectionCard = (props: {
  affiliateLink?: string;
  averageWeight?: string;
  eyeIndex?: number;
  showEyeArray?: any;
  setShowEyeArray?: any;
  showTurnThemAllBack?: boolean;
  userObject: any;
  family: string[];
  publishers?: string[];
  linkOnClick?: string;
  image: string;
  title: string;
  author: string;
  download?: string;
  price?: string | number;
  rating?: string | number;
  extra?: string;
  minPlayers?: string | number;
  maxPlayers?: string | number;
  rank?: string | number;
  isExpansion?: boolean;
  estimatedTime?: string | number;
}) => {
  const {
    affiliateLink,
    averageWeight,
    eyeIndex,
    showEyeArray,
    setShowEyeArray,
    showTurnThemAllBack,
    userObject,
    family,
    publishers,
    linkOnClick,
    title,
    author,
    image,
    extra,
    minPlayers,
    maxPlayers,
    rank,
    isExpansion,
    estimatedTime,
  } = props;

  const { translation } = useTranslation();
  const { wishListTranslation } = useWishListTranslation();

  const [extraCss, setExtraCss] = useState(extra);
  const [showOverlay, setShowOverlay] = useState(false);
  const [showXorEye, setShowXorEye] = useState(false);
  const [showTitle, setShowTitle] = useState(false);
  const [animationParent] = useAutoAnimate(DEFAULT_USE_AUTO_ANIMATE);

  const [currentPublisher, setCurrentPublisher] = useState(0);
  const [currentFamily, setCurrentFamily] = useState(0);

  const [userObjectLocal, setUserObjectLocal] = useState(userObject);

  const { currentGoogleUser } = useSelector(
    (state: RootState) => state.userSlice
  );

  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const minSwipeDistance = 50;

  const onTouchStart = (e) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe || isRightSwipe) {
      console.log("swipe", isLeftSwipe ? "left" : "right");
      if (!showOverlay) {
        handleTapCard();
      } else {
        if (showOverlay && showXorEye) {
          hideExpandedCard();
        } else {
          if (!showXorEye && showOverlay) {
            turnItBack();
          }
        }
      }
    }
  };

  useEffect(() => {
    if (showTurnThemAllBack) {
      setShowOverlay(false);
      setShowXorEye(false);
      setExtraCss("scale-[1] duration-300 z-40");
    }
  }, [showTurnThemAllBack]);

  useEffect(() => {
    if (currentGoogleUser) {
      setUserObjectLocal(currentGoogleUser);
    } else {
      if (userObjectLocal) {
        setCurrentPublisher(0);
        setCurrentFamily(0);
      }
    }
  }, [currentGoogleUser, userObjectLocal]);

  const turnItBack = () => {
    let auxShowEyeArray = [...showEyeArray];
    auxShowEyeArray[eyeIndex] = false;
    setShowEyeArray(auxShowEyeArray);
    setShowOverlay(false);
  };

  const handleTapCard = () => {
    let auxShowEyeArray = [...showEyeArray];
    auxShowEyeArray[eyeIndex] = true;
    setShowEyeArray(auxShowEyeArray);

    setExtraCss("scale-[1.4] md:scale-[1.5] duration-300 z-50");
    setShowOverlay(true);
    setShowXorEye(true);
  };
  const hideExpandedCard = () => {
    setExtraCss("scale-[1] duration-300 z-40");
    //setExtraCssDiv("");
    setShowXorEye(false);
  };
  const movePublishers = () => {
    if (!family || publishers.length === 0) return;
    if (currentPublisher < publishers.length - 1) {
      setCurrentPublisher(currentPublisher + 1);
    } else {
      setCurrentPublisher(0);
    }
  };
  const moveFamily = () => {
    if (!family || family.length === 0) return;
    if (currentFamily < family.length - 1) {
      setCurrentFamily(currentFamily + 1);
    } else {
      setCurrentFamily(0);
    }
  };
  const getComplexity = (weight) => {
    let complexity = "";
    switch (weight) {
      case 1:
        complexity = wishListTranslation.veryEasy;
        break;
      case 2:
        complexity = wishListTranslation.easy;
        break;
      case 3:
        complexity = wishListTranslation.normal;
        break;
      case 4:
        complexity = wishListTranslation.hard;
        break;
      case 5:
        complexity = wishListTranslation.veryHard;
        break;
    }
    return complexity;
  };
  return (
    <Card
      extra={`flex flex-col w-40 h-40 2xl:w-44 2xl:h-44 bg-branded-900 rounded-xl hover:translate-y-1 rounded-[10px] border-branded-900 border-[2px] z-40 transition  ${extraCss}`}
    >
      <div
        onClick={() => {
          !showOverlay && handleTapCard();
        }}
        onMouseEnter={() => {
          setShowTitle(true);
        }}
        onMouseLeave={() => {
          setShowTitle(false);
        }}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
        ref={animationParent}
        className="  h-full w-full hover:cursor-pointer"
        style={{
          backgroundImage: `url(${image})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        {!showOverlay && (
          <>
            <LazyLoadImage
              wrapperClassName={`h-full w-full`}
              placeholderSrc={bggPlaceHolderImg}
              src={image}
              className="mb-3 h-full w-full rounded-lg object-fill"
              alt={title}
              effect="blur"
            />
            <div
              className={`absolute right-0 top-0 flex items-center justify-center rounded-bl-lg rounded-tr-lg bg-branded-800 p-1 text-white  hover:cursor-pointer`}
            >
              <FiEye
                onClick={handleTapCard}
                className=" h-5 w-5 md:h-5 md:w-5"
              />
            </div>
            {showTitle && (
              <div className="absolute bottom-0 right-0 flex h-fit w-full rounded-bl-lg rounded-br-lg bg-navy-900 bg-opacity-70 p-3 text-white">
                <div className="flex h-fit items-center justify-center rounded-full text-sm font-bold">
                  {title}
                </div>
              </div>
            )}
            <div className="absolute bottom-0 right-0 flex w-fit items-center justify-between  overflow-hidden bg-navy-900 bg-opacity-70 md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col xl:items-start 3xl:flex-row 3xl:justify-between">
              {isExpansion && (
                <div className="flex items-center justify-center rounded-br-lg rounded-tl-lg bg-red-500 p-2 text-white">
                  <div className="w-15 flex h-2 items-center justify-center rounded-full text-sm dark:text-white">
                    {translation.expansion}
                  </div>
                </div>
              )}
            </div>
          </>
        )}
        {showOverlay && (
          <>
            <LazyLoadImage
              wrapperClassName={`h-full w-full`}
              placeholderSrc={bggPlaceHolderImg}
              src={image}
              className="mb-3 h-full w-full rounded-lg object-fill "
              alt={title}
              effect="blur"
            />
            <div className="absolute left-0 top-0 h-fit h-full w-full items-start justify-start rounded-lg  bg-navy-800 bg-opacity-90 px-3 py-3 text-xs text-white">
              <div className="text-[9px]  font-normal dark:text-white md:text-[10px]">
                <p className="mb-1 font-normal leading-[.7rem]  dark:text-white md:mb-2">
                  {title}
                </p>
                {!isExpansion && (
                  <div className="mb-1.5 mt-1 flex h-3 items-center justify-start rounded-l-lg dark:text-white  md:mb-2">
                    <MdStar className="h-3 w-3" />
                    <p className="ml-2  font-normal dark:text-white ">
                      {"#" + rank}
                    </p>
                  </div>
                )}
                <div className="mb-1.5 mt-1 flex h-3 items-center justify-start rounded-l-lg dark:text-white  md:mb-2">
                  <MdPeople className="h-3 w-3" />
                  <p className="ml-2  font-normal dark:text-white ">
                    {minPlayers} - {maxPlayers + " " + translation.players}
                  </p>
                  <IoIosHourglass className="ml-2 h-3 w-3" />
                  <p className=" ml-1 font-normal  dark:text-white">
                    {estimatedTime} min
                  </p>
                </div>
                <div className="mb-1.5 flex h-3 items-center justify-start rounded-l-lg dark:text-white md:mb-2">
                  {publishers && (
                    <>
                      <IoIosBusiness className="h-3 w-3" />
                      <p
                        className="ml-2 font-normal  hover:cursor-pointer dark:text-brand-500"
                        onClick={movePublishers}
                      >
                        {publishers && publishers.length > 0
                          ? publishers[currentPublisher].length > 50
                            ? publishers[currentPublisher].substring(0, 50) +
                              "..."
                            : publishers[currentPublisher]
                          : "N/A"}
                      </p>
                    </>
                  )}
                </div>
                <div className="mb-1.5 flex h-3 items-center justify-start rounded-l-lg dark:text-white md:mb-2">
                  {family && (
                    <>
                      <IoIosGrid className="h-3 w-3" />
                      <p
                        className="ml-2 rounded-lg  font-normal hover:cursor-pointer dark:text-brand-500"
                        onClick={moveFamily}
                      >
                        {family && family.length > 0
                          ? family[currentFamily].length > 50
                            ? family[currentFamily].substring(0, 50) + "..."
                            : family[currentFamily]
                          : "N/A"}
                      </p>
                    </>
                  )}
                </div>
                <div className="mb-1.5 flex h-3 items-center justify-start rounded-l-lg dark:text-white md:mb-2 ">
                  <MdExtension className="h-3 w-3" />
                  <p className="ml-2 font-normal  dark:text-white">
                    {getComplexity(averageWeight)}
                  </p>
                </div>
                <div className="mb-1 flex h-3 items-center justify-start rounded-l-lg dark:text-white ">
                  <IoIosCalendar className="h-3 w-3" />
                  <p className="ml-2 font-normal  dark:text-white">{author}</p>
                </div>
              </div>
            </div>
            <div className="absolute bottom-0 right-10 flex w-fit items-center justify-center hover:cursor-pointer   md:flex-col">
              {isExpansion && (
                <div className="flex items-center justify-center rounded-tl-lg bg-red-500 p-1 text-white">
                  <div className="flex h-2 w-fit items-center justify-center rounded-full text-[10px] dark:text-white">
                    {translation.expansion}
                  </div>
                </div>
              )}
            </div>
            <a
              href={linkOnClick}
              target="blank"
              className=" hover:cursor-pointer"
            >
              <div className="absolute bottom-0 right-0 flex  h-7 items-center justify-center  rounded-br-lg bg-branded-800 p-2 hover:cursor-pointer">
                <BoardGamesGeek className={"h-6 w-6 text-branded-900"} />
              </div>
            </a>
            <a
              rel="noreferrer"
              href={
                affiliateLink === ""
                  ? AMAZON_SITE_OPTIONS[userObjectLocal.wishlist.region].url +
                    title +
                    "&tag=" +
                    AMAZON_SITE_OPTIONS[userObjectLocal.wishlist.region].tag
                  : affiliateLink
              }
              target="_blank"
              className=" hover:cursor-pointer"
            >
              <div className="absolute bottom-7 right-0 flex  h-7 items-center justify-center rounded-tl-lg  bg-branded-200 p-2  hover:cursor-pointer">
                <IoLogoAmazon className={"h-6 w-6"} />
              </div>
            </a>
            {showXorEye ? (
              <div
                className="absolute right-0 top-0 flex h-5 items-center justify-center rounded-bl-xl  rounded-tr-lg bg-branded-200 text-white   hover:cursor-pointer"
                onClick={hideExpandedCard}
              >
                <HiX className={"h-5 w-5 px-1"} />
              </div>
            ) : (
              <div
                className="absolute right-0 top-0 flex h-5 items-center justify-center rounded-bl-xl rounded-tr-lg  bg-branded-800 p-1 text-white   hover:cursor-pointer"
                onClick={turnItBack}
              >
                <FiEyeOff className={"h-5 w-5 px-1"} />
              </div>
            )}
          </>
        )}
      </div>
    </Card>
  );
};

export default CollectionCard;

import { Routes, Route, Navigate } from "react-router-dom";
import "react-lazy-load-image-component/src/effects/blur.css";
import RtlLayout from "layouts/rtl";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase/credenciales";
import { useDispatch } from "react-redux";
import {
  changeUserName,
  setCurrentGoogleUser,
  setCodexSubscription,
  setIsLoadingUserData,
} from "slices/userSlice";
import addNewUser, {
  checkIfUserExists,
  updatePermanentWishlistUrl,
} from "utils/firebase-services/userService";
import useTranslation from "utils/hooks/useTranslation";
import { setCurrentLanguage } from "slices/languageSlice";
import MyWishListLayout from "layouts/mywishlist";
import { CodexSubscription, UserObject } from "utils/constants/interfaces";
import { useEffect } from "react";
import {
  getAssistants,
  getCodexHistoryInformation,
} from "utils/firebase-services/codexService";
import {
  setAiAssistants,
  setCodexHistory,
  setIsLoadingCodexAISubscription,
  setStripeProducts,
} from "slices/codexSlice";
import {
  getStripeProducts,
  getUserActiveSubscription,
} from "utils/stripe-services/products";
import { reuleaux } from "ldrs";
import {
  setOwnedGames,
  setShareWishlistGames,
  setWishlistGames,
} from "slices/gamesSlice";
reuleaux.register();

const App = () => {
  const { translation } = useTranslation();
  document.body.classList.add("dark");
  const dispatch = useDispatch();
  onAuthStateChanged(auth, (usuarioFirebase) => {
    if (usuarioFirebase) {
      checkIfUserExists(usuarioFirebase).then((res) => {
        dispatch(setIsLoadingUserData(false));
        const currentUser = res[0];
        if (res.length === 0) {
          const user_to_store: UserObject = {
            creationDate: new Date().getTime(),
            key_firebase: "",
            bgg_username: localStorage.getItem("bgg_username"),
            displayName: usuarioFirebase.displayName,
            email: usuarioFirebase.email,
            photoURL: usuarioFirebase.photoURL,
            uid: usuarioFirebase.uid,
            prefered_language: translation.getLanguage(),
            wishlist: {
              games: [],
              region: translation.getLanguage(),
              lastModified: new Date().getTime(),
              personalized_message: "",
              shareLinkGames: [],
            },
            collection: {
              games: [],
              lastModified: new Date().getTime(),
            },
            permanent_wishlist_url:
              "https://mibox.citadejuegos.com/features/my-wishlist?key=",
            firebaseRole: "free",
            freeQuestions: { time: 0, quantity: 0 },
          };
          addNewUser(user_to_store)
            .then((res) => {
              user_to_store.key_firebase = res.id;
              user_to_store.permanent_wishlist_url =
                "https://mibox.citadejuegos.com/features/my-wishlist?key=" +
                res.id;
              updatePermanentWishlistUrl(user_to_store);
              dispatch(setCurrentGoogleUser(user_to_store));
            })
            .catch((error) => {
              console.log("error", error);
              dispatch(setCurrentGoogleUser(user_to_store));
            });
        } else {
          dispatch(setIsLoadingCodexAISubscription(true));
          getUserActiveSubscription(currentUser.uid).then(
            (responseSubscriptions) => {
              if (responseSubscriptions) {
                const suscriptionToSlice: CodexSubscription = {
                  role: responseSubscriptions.role,
                  status: responseSubscriptions.status,
                  stripeLink: responseSubscriptions.stripeLink,
                  expiringDate:
                    responseSubscriptions.current_period_end.seconds * 1000,
                };
                dispatch(setCodexSubscription(suscriptionToSlice));
              } else {
                dispatch(setCodexSubscription(null));
                if (!currentUser.freeQuestions) {
                  currentUser.freeQuestions = { time: 0, quantity: 0 };
                }
              }
              setTimeout(() => {
                dispatch(setIsLoadingCodexAISubscription(false));
              }, 1000);
            }
          );
          if (currentUser.bgg_username) {
            localStorage.setItem("bgg_username", currentUser.bgg_username);
            dispatch(changeUserName(currentUser.bgg_username));
          }
          if (currentUser.prefered_language) {
            translation.setLanguage(currentUser.prefered_language);
            dispatch(setCurrentLanguage(currentUser.prefered_language));
          }

          dispatch(setCurrentGoogleUser(currentUser));
          getCodexHistoryInformation(currentUser.id).then(
            (codexHistoryData) => {
              dispatch(setCodexHistory(codexHistoryData));
            }
          );
          if (currentUser.collection.games)
            dispatch(setOwnedGames(currentUser.collection.games));
          if (currentUser.wishlist.games)
            dispatch(setWishlistGames(currentUser.wishlist.games));
          if (currentUser.wishlist.shareLinkGames)
            dispatch(
              setShareWishlistGames(currentUser.wishlist.shareLinkGames)
            );
        }
      });
    } else {
      dispatch(setIsLoadingCodexAISubscription(false));
      dispatch(setCurrentGoogleUser(null));
      dispatch(setIsLoadingUserData(false));
    }
  });

  useEffect(() => {
    getAssistants().then((res) => {
      dispatch(setAiAssistants(res));
    });
    getStripeProducts().then((res) => {
      const products = res as any;
      dispatch(setStripeProducts(products));
    });
  }, [dispatch]);
  return (
    <Routes>
      <Route path="features/*" element={<MyWishListLayout />} />
      <Route path="auth/*" element={<AuthLayout />} />
      <Route path="mibox/*" element={<AdminLayout />} />
      <Route path="rtl/*" element={<RtlLayout />} />
      <Route path="/" element={<Navigate to="/mibox" replace />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default App;

import PreviewSection from "./components/PreviewSection";
import WishlistSection from "./components/WishlistSection";

const WishList = () => {
  return (
    <div className="mt-3 grid h-full grid-cols-1 gap-5 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-3 2xl:grid-cols-4">
      <div className="col-span-1 h-full w-full rounded-xl md:col-span-2 lg:col-span-1">
        <WishlistSection />
      </div>
      <div className="col-span-1 h-full w-full rounded-xl sm:col-span-2 md:col-span-2 lg:col-span-3 xl:col-span-3 2xl:col-span-3">
        <PreviewSection />
      </div>
    </div>
  );
};

export default WishList;

import newsBlogImg from "assets/img/blog/news.jpg";
import Card from "components/card";
import useTranslation from "utils/hooks/useTranslation";
import { NEWS_STATIC_DATA } from "utils/staticData/newsSD";
import { LazyLoadImage } from "react-lazy-load-image-component";
import bggPlaceHolderImg from "assets/img/loader/placeholder_img.jpg";

const Project = () => {
  const { translation } = useTranslation();
  const renderThreads = () => {
    const optionsItems = NEWS_STATIC_DATA.forum.threads.map((g, index) => (
      <div
        key={"option" + index}
        className="mb-2 flex w-full items-center justify-between rounded-2xl bg-white p-3 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none"
      >
        <div className="flex items-center">
          <LazyLoadImage
            wrapperClassName={`h-[35px] w-[35px]`}
            placeholderSrc={bggPlaceHolderImg}
            src={newsBlogImg}
            className="h-[35px] w-[35px] rounded-lg"
            alt={g.author}
            effect="blur"
          />
          <div className="ml-3">
            <p className="text-sm font-medium text-navy-700 dark:text-white">
              {g.title.length > 40 ? g.title.substring(0, 40) + "..." : g.title}
            </p>
            <p className="mt-2 text-sm font-bold text-gray-600">
              {g.author}.
              <a
                target="blank"
                className="ml-1 font-medium text-brand-500 hover:text-brand-500 dark:text-white"
                href={g.link}
              >
                See Threads
              </a>
            </p>
          </div>
        </div>
      </div>
    ));
    return <>{optionsItems}</>;
  };
  return (
    <Card extra={"w-full p-4 h-full"}>
      <div className="mb-1 w-full">
        <h4 className="text-lg font-bold text-navy-700 dark:text-white">
          {translation.newsForumTitle}
        </h4>
      </div>

      {renderThreads()}
    </Card>
  );
};

export default Project;

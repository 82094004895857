//import React, { useState, useContext } from "react";

import { AMAZON_AFFILIATE_TAGS } from "utils/constants/constants";

export default function useRedirectionHook() {
  const baseUrls = {
    MX: "https://www.amazon.com.mx",
    CA: "https://www.amazon.ca",
    US: "https://www.amazon.com",
  };
  const tags = {
    MX: AMAZON_AFFILIATE_TAGS.MX,
    CA: AMAZON_AFFILIATE_TAGS.CA,
    US: AMAZON_AFFILIATE_TAGS.US,
  };

  const getCountry = async () => {
    try {
      const response = await fetch("https://ipapi.co/json/");
      const data = await response.json();
      return data.country;
    } catch (error) {
      console.error("Failed to fetch country", error);
      return null;
    }
  };

  const getQueryParam = (param) => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(param);
  };

  const buildAffiliateLink = (countryCode, affiliateCode) => {
    const baseUrl = baseUrls[countryCode] || baseUrls.US;
    const tag = tags[countryCode] || tags.US;
    return `${baseUrl}/dp/${affiliateCode}?tag=${tag}`;
  };

  return { getQueryParam, buildAffiliateLink, getCountry };
}

import Card from "components/card";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import WishlistPreview from "./WishlistPreview";
import useTranslation from "utils/hooks/useTranslation";
import WishlistNoAccountPreview from "./WishlistNoAccountPreview";
import Dropdown from "components/dropdown";
import {
  AMAZON_SITE_OPTIONS,
  LANGUAGE_OPTIONS_DATA,
} from "utils/constants/constants";
import { useEffect, useState } from "react";
import InputField from "components/fields/InputField";
import { FiCopy, FiEye } from "react-icons/fi";
import { setCurrentGoogleUser } from "slices/userSlice";
import { updateShareLinkWishlistInFirebase } from "utils/firebase-services/wishListService";
import { Link } from "react-router-dom";

const PreviewSection = () => {
  const { currentGoogleUser } = useSelector(
    (state: RootState) => state.userSlice
  );
  const { translation } = useTranslation();
  const dispatch = useDispatch();
  const [shareLink, setShareLink] = useState("");
  const [shouldCloseDropDown, setShouldCloseDropDown] = useState(false);

  useEffect(() => {
    if (currentGoogleUser) {
      setShareLink(currentGoogleUser.permanent_wishlist_url);
    }
  }, [currentGoogleUser]);

  const changeWishlistRegion = (region: string) => {
    const auxGameWishlist = JSON.parse(JSON.stringify(currentGoogleUser));
    auxGameWishlist.wishlist.region = region;
    auxGameWishlist.wishlist.lastModified = new Date().getTime();
    dispatch(setCurrentGoogleUser(auxGameWishlist));
    updateShareLinkWishlistInFirebase(auxGameWishlist);
    setShouldCloseDropDown(!shouldCloseDropDown);
  };

  const renderStoreDropDownItem = () => {
    const toolTipItems = LANGUAGE_OPTIONS_DATA.map((data, index) => (
      <p
        key={index}
        className="hover:text-black mb-1 flex cursor-pointer items-center text-gray-600 hover:font-medium"
        onClick={() => changeWishlistRegion(data.lang)}
      >
        <img
          loading="lazy"
          className="col-span-1 mr-1 h-7 w-7"
          src={data.flag}
          alt="flag"
        />
        {data.site}
      </p>
    ));
    return <>{toolTipItems}</>;
  };

  return (
    <Card extra={"mt-3 !z-5 overflow-hidden p-5 "}>
      {currentGoogleUser && (
        <div className=" grid w-full grid-cols-2 items-center justify-between">
          <div className="col-span-1 flex items-center justify-between rounded-t-3xl p-3">
            <div className=" w-full max-w-full md:pl-4 lg:pl-0">
              <InputField
                disabled
                variant="auth"
                value={shareLink}
                extra="mb-3"
                label={translation.shareLink}
                placeholder=""
                id="shareLink"
                type="text"
              />
            </div>
            <a
              rel="noreferrer"
              target="_blank"
              href={shareLink}
              className="ml-3 text-base font-medium text-white "
            >
              <FiEye className="mt-5 h-5 w-5 text-white hover:text-navy-200" />
            </a>
            <FiCopy
              onClick={() => {
                navigator.clipboard.writeText(shareLink);
              }}
              className="dark:hover:text-grey-400 ml-3 mt-5 h-5 w-5 rounded-full text-white hover:cursor-pointer hover:text-navy-200 active:bg-brand-700"
            />
          </div>
          <div className="col-span-1 flex items-center justify-between rounded-t-3xl p-3">
            <div className="flex items-center justify-between rounded-t-3xl p-1">
              <div className="mr-5 text-sm font-bold text-navy-700 dark:text-white">
                {translation.preferedStore}
              </div>
              <Dropdown
                showCloseDropDown={shouldCloseDropDown}
                button={
                  <span className="text-sm font-normal text-brand-500 hover:cursor-pointer">
                    {" "}
                    {
                      AMAZON_SITE_OPTIONS[currentGoogleUser.wishlist.region]
                        .site
                    }
                  </span>
                }
                animation={
                  "origin-top-right transition-all duration-300 ease-in-out"
                }
                classNames={"top-5 right-1 w-max"}
                children={
                  <div className="z-50 w-max rounded-xl bg-white px-4 py-3 text-sm shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                    {renderStoreDropDownItem()}
                  </div>
                }
              />
            </div>
          </div>
        </div>
      )}
      {!currentGoogleUser && (
        <div className="flex items-center rounded-t-3xl p-3">
          <span className="text-sm font-normal text-gray-600">
            {translation.toCreateWishListSignIn}
          </span>
          <Link
            className="flex h-[50px] w-[100px] items-center justify-center gap-2 rounded-xl text-white hover:cursor-pointer hover:bg-branded-900 dark:bg-navy-800"
            to="/mibox/sign-in"
          >
            Sign In
          </Link>
        </div>
      )}
      <div className="flex items-center justify-between rounded-t-3xl p-3">
        <div className="mr-4 text-lg font-bold text-navy-700 dark:text-white">
          {translation.preview}
        </div>
        <span className="text-sm font-normal text-gray-500 hover:cursor-pointer">
          {translation.dependingOnScreenSize}
        </span>
      </div>
      {currentGoogleUser && <WishlistPreview />}
      {!currentGoogleUser && <WishlistNoAccountPreview />}
    </Card>
  );
};

export default PreviewSection;

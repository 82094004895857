const ReleaseNotes = (props: { version: string }) => {
  const { version } = props;
  const releaseNotes = {
    "v0.0.1": [
      {
        title: "Sign In with Google",
        description:
          "To be able to access the Wishlist feature we added the functionality to sign in with Google. Now you can save your wishlist and share it with your friends!",
      },
      {
        title: "Translations",
        description:
          "Added site-wide translations. Now you can see the site in English or Spanish!",
      },
      {
        title: "MiBox",
        description:
          "We created a more specific, consentrated and easier way to view your BGG information.",
      },
      {
        title: "Filtering!",
        description:
          "We created as many filters the information from the API let us, hopefully you find it handy to search throught your collection.",
      },
      {
        title: "Wishlist",
        description:
          "Probably the site main feature, once you log in you can create your own wishlist and share it with your friends using a permanent link in which you can customize what information to share!",
      },
      {
        title: "BGG News",
        description:
          "We searched, parsed and mined all the news, blog, podcast and forum information we could from BGG API, now you can see all the latest news in one place!",
      },
      {
        title: "Support Us",
        description:
          "Added a nice way to support us, if you like the site and want to help us keep it running, you can buy us an ice cream!",
      },
    ],
    "v0.0.2": [
      {
        title: "Syncronization Process",
        description:
          "Thanks to your feedback, now the system gathers Games Information from the searchs to make it faster for future requests. Now the systema learns from every search made and uses that information to make it faster for all users, especially those with huge collections!",
      },
      {
        title: "Expansions Filter",
        description:
          "Feature request done! Added a simple filter to hide expansions from the collection results.",
      },
      {
        title: "Lazy Loading!",
        description:
          "Thanks for the feedback on the loading times and scrolling experience, we added lazy loading to the collection and wishlist results, now the site should feel faster and smoother!",
      },
    ],
    "v0.0.3": [
      {
        title: "Redesign search box",
        description:
          "Made the search box easier to access and redesigned the experience for mobile to hide the sidebar and show the search box in the header.",
      },
      {
        title: "'Feeling Lost?' section",
        description:
          "Added a FAQ kind of section to instruct none BGG users how to get their data up and running.",
      },

      {
        title: "Preview Wishlist",
        description:
          "Now you can preview how your wishlist would look like without the need of having an account.",
      },
    ],
    "v0.0.4": [
      {
        title: "Refactor all Sync process",
        description:
          "Thanks to your feedback, the syncronization process has evolved to make it so much easier for you to access your collection information. Now the information is stored locally and you may update it by clicking on the search icon.",
      },
      {
        title: "Non Account Wishlist Preview",
        description:
          "Now the Wishlist Preview for non account users is now shown sorted by the Wishlist Priority.",
      },
    ],
    "v0.0.5": [
      {
        title: "Loading times reduced-again!",
        description:
          "Now syncing your data from BGG is way faster and the loading time got reduced to a minimum.",
      },
    ],
    "v0.0.6": [
      {
        title: "New Feature: My Collection",
        description:
          "Now you can share a personalized link with your friends to show them your collection! You would require an account, just as the wishlist, and you would see the two new icons to open and copy the link to your complete collection! \nAlso, Fixed an issue that was causing the wishlist to not update properly after a new sync with your BGG data",
      },
    ],
  };

  const renderNote = (v) => {
    return releaseNotes[v].map((note: any, index) => {
      return (
        <div
          key={note + index}
          className=" ml-2 flex h-full w-full flex-col justify-center rounded-lg px-1 text-sm"
        >
          <p className="mb-1 text-left text-base font-bold text-gray-900 dark:text-white">
            {note.title}
          </p>
          <p className="font-base text-left text-xs text-gray-900 dark:text-white">
            {note.description}
          </p>
        </div>
      );
    });
  };

  return (
    <>
      <div className=" mt-2 flex w-[360px] flex-col gap-3 rounded-[20px] bg-white p-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none sm:w-[460px]">
        <div className="flex items-center justify-between">
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {version}
          </p>
          <span className="text-sm font-normal text-gray-500 hover:cursor-pointer">
            8/4/2024
          </span>
        </div>

        {renderNote(version)}
      </div>
      <div className=" mt-2 flex w-[360px] flex-col gap-3 rounded-[20px] bg-white p-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none sm:w-[460px]">
        <div className="flex items-center justify-between">
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            v0.0.5
          </p>
          <span className="text-sm font-normal text-gray-500 hover:cursor-pointer">
            31/7/2023
          </span>
        </div>

        {renderNote("v0.0.5")}
      </div>
      <div className=" mt-2 flex w-[360px] flex-col gap-3 rounded-[20px] bg-white p-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none sm:w-[460px]">
        <div className="flex items-center justify-between">
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            v0.0.4
          </p>
          <span className="text-sm font-normal text-gray-500 hover:cursor-pointer">
            26/7/2023
          </span>
        </div>

        {renderNote("v0.0.4")}
      </div>
      <div className="mt-2 flex w-[360px] flex-col gap-3 rounded-[20px] bg-white p-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none sm:w-[460px]">
        <div className="flex items-center justify-between">
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            v0.0.3
          </p>
          <span className="text-sm font-normal text-gray-500 hover:cursor-pointer">
            25/7/2023
          </span>
        </div>

        {renderNote("v0.0.3")}
      </div>
      <div className="mt-2 flex w-[360px] flex-col gap-3 rounded-[20px] bg-white p-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none sm:w-[460px]">
        <div className="flex items-center justify-between">
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            v0.0.2
          </p>
          <span className="text-sm font-normal text-gray-500 hover:cursor-pointer">
            21/7/2023
          </span>
        </div>

        {renderNote("v0.0.2")}
      </div>
      <div className=" mt-2 flex w-[360px] flex-col gap-3 rounded-[20px] bg-white p-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none sm:w-[460px]">
        <div className="flex items-center justify-between">
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            v0.0.1
          </p>
          <span className="text-sm font-normal text-gray-500 hover:cursor-pointer">
            20/7/2023
          </span>
        </div>

        {renderNote("v0.0.1")}
      </div>
    </>
  );
};

export default ReleaseNotes;

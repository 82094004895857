import { useState, useRef, useEffect } from "react";
import Markdown from "react-markdown";
import { hourglass } from "ldrs";
import AIWriter from "react-aiwriter";
import { IoPaperPlane } from "react-icons/io5";
import { useAssistantOAI } from "utils/axios/assistantOAI";
import { QuickAction } from "./Chat/QuickAction";
import { SelectedGame } from "utils/constants/interfaces";
import {
  saveUserCodexHistory,
  updateFreeQuestions,
} from "utils/firebase-services/codexService";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { DEFAULT_USE_AUTO_ANIMATE } from "utils/constants/constants";
import { FiTrash2 } from "react-icons/fi";
import { Link } from "react-router-dom";
import { setCurrentGoogleUser } from "slices/userSlice";
import {
  getDateStringFromTimestamp,
  getIfDateIsMoreThanOneDayOld,
} from "utils/functions/utils";
import { MdArrowLeft } from "react-icons/md";
hourglass.register();
interface ChatProps {
  selectedGame: SelectedGame;
  selectGame: (game: SelectedGame) => void;
}

export default function Chat(props: ChatProps) {
  const { selectedGame, selectGame } = props;
  const { threadInfo, messagesInfo } = useSelector(
    (state: RootState) => state.codexSlice
  );
  const [animationParent] = useAutoAnimate(DEFAULT_USE_AUTO_ANIMATE);
  const [messages, setMessages] = useState({});
  const [currentThread, setCurrentThread] = useState({});
  const inputRef = useRef<HTMLInputElement>();
  const [shouldTypeWrite, setShouldTypeWrite] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { sendMessageToAssistant } = useAssistantOAI();
  const { ai_assistants } = useSelector((state: RootState) => state.codexSlice);
  const dispatch = useDispatch();

  const { codexSubscription, currentGoogleUser } = useSelector(
    (state: RootState) => state.userSlice
  );

  useEffect(() => {
    if (
      Object.keys(currentThread).length === 0 &&
      Object.keys(messages).length === 0
    ) {
      setCurrentThread(threadInfo);
      setMessages(messagesInfo);
    }
  }, [selectedGame, threadInfo, messagesInfo, currentThread, messages]);
  const updateMessageAndThread = (responseThreadId, responseMessages) => {
    setIsLoading(false);
    const threadInfo = {
      ...currentThread,
      [selectedGame.title]: responseThreadId,
    };
    const messagesInfo = {
      ...messages,
      [selectedGame.title]: responseMessages,
    };
    setCurrentThread(threadInfo);
    setMessages(messagesInfo);
    setShouldTypeWrite(true);
    saveUserCodexHistory(
      threadInfo,
      messagesInfo,
      currentGoogleUser.key_firebase
    );
    if (!codexSubscription) {
      let newTime;
      let newQuantity;
      if (currentGoogleUser.freeQuestions) {
        if (
          getIfDateIsMoreThanOneDayOld(currentGoogleUser.freeQuestions.time)
        ) {
          newTime = new Date().getTime();
          newQuantity = 1;
        } else {
          newTime = currentGoogleUser.freeQuestions.time;
          newQuantity = currentGoogleUser.freeQuestions.quantity + 1;
        }
        updateFreeQuestions(currentGoogleUser.key_firebase, {
          time: newTime,
          quantity: newQuantity,
        });
      } else {
        newTime = new Date().getTime();
        newQuantity = 1;
      }

      dispatch(
        setCurrentGoogleUser({
          ...currentGoogleUser,
          freeQuestions: { time: newTime, quantity: newQuantity },
        })
      );
    }
  };
  const callAssistant = async (question) => {
    if (currentGoogleUser.freeQuestions) {
      if (
        currentGoogleUser.freeQuestions.quantity >= 3 &&
        !getIfDateIsMoreThanOneDayOld(currentGoogleUser.freeQuestions.time)
      ) {
        alert(
          "You have reached the limit of free questions for this hour. Please upgrade your plan to continue using the Codex AI or wait until the next day."
        );
        return;
      }
    }

    let currentGameMessages = messages[selectedGame.title];
    if (!currentGameMessages) {
      currentGameMessages = [];
    }
    setMessages({
      ...messages,
      [selectedGame.title]: [
        { role: "user", content: [{ text: { value: question } }] },
        ...currentGameMessages,
      ],
    });
    setIsLoading(true);
    const currentThreadId =
      currentThread[selectedGame.title] !== "" &&
      currentThread[selectedGame.title] !== undefined
        ? currentThread[selectedGame.title]
        : null;
    const { responseMessages, responseThreadId } = await sendMessageToAssistant(
      question,
      currentThreadId,
      ai_assistants[selectedGame.title]
    );
    updateMessageAndThread(responseThreadId, responseMessages);
  };
  const sendQuickAction = async (quickAction) => {
    callAssistant(quickAction);
  };
  const sendQuestion = async () => {
    if (!inputRef.current) {
      return;
    }
    const inputToSend = inputRef.current.value;
    inputRef.current.value = "";
    callAssistant(inputToSend);
  };
  const renderTypedMessage = () => {
    if (
      !messages[selectedGame.title] ||
      messages[selectedGame.title].length === 0
    ) {
      return <div key={Math.random() + "chatMessage"}></div>;
    }
    const messageToShow = messages[selectedGame.title][0];
    if (!messageToShow) {
      return <div key={Math.random() + "chatMessage"}></div>;
    }
    if (messageToShow.role === "user") {
      return <div key={messageToShow.id}></div>;
    }
    if (shouldTypeWrite) {
      setTimeout(() => {
        setShouldTypeWrite(false);
      }, 2000);
      return (
        <div className="flex flex-row" key={messageToShow.id}>
          <div className="rounded-xl bg-gray-200 p-2">
            <AIWriter>
              <Markdown className="prose text-sm">
                {messageToShow.content[0].text.value}
              </Markdown>
            </AIWriter>
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex flex-row" key={messageToShow.id}>
          <div className="rounded-xl bg-gray-200 p-2">
            <Markdown className="prose text-sm">
              {messageToShow.content[0].text.value}
            </Markdown>
          </div>
        </div>
      );
    }
  };
  const renderMessages = () => {
    if (
      !messages[selectedGame.title] ||
      messages[selectedGame.title].length === 0
    ) {
      return <div key={Math.random() + "chatMessage"}></div>;
    }
    let firstMessageId = messages[selectedGame.title][0].id;
    return messages[selectedGame.title].map((message) => {
      if (message.role === "user") {
        return (
          <div className="flex flex-row p-2" key={message.id}>
            <div className="ml-auto rounded-xl bg-brand-500 p-2">
              <Markdown className="prose text-sm text-white">
                {message.content[0].text.value}
              </Markdown>
            </div>
          </div>
        );
      } else {
        if (message.id !== firstMessageId) {
          return (
            <div className="flex flex-row" key={message.id}>
              <div className="rounded-xl bg-gray-200 p-2">
                <Markdown className="prose text-sm">
                  {message.content[0].text.value}
                </Markdown>
              </div>
            </div>
          );
        } else {
          return <div key={message.id}></div>;
        }
      }
    });
  };

  const cleanChat = () => {
    if (
      window.confirm(
        "Do you want to clear the chat History for " + selectedGame.title + "?"
      )
    ) {
      const newCurrentThread = { ...currentThread };
      const newMessages = { ...messages };
      newCurrentThread[selectedGame.title] = "";
      newMessages[selectedGame.title] = [];
      const threadInfo = {
        ...newCurrentThread,
      };
      const messagesInfo = {
        ...newMessages,
      };
      setCurrentThread(threadInfo);
      setMessages(messagesInfo);
      saveUserCodexHistory(
        threadInfo,
        messagesInfo,
        currentGoogleUser.key_firebase
      );
      console.log("Cleaning chat history for " + selectedGame.title);
    }
  };

  return (
    <div ref={animationParent}>
      {!codexSubscription && (
        <div className="flex w-full flex-col justify-end">
          <h4 className="flex w-2/3 items-end justify-end self-end text-sm font-bold text-white">
            You currently have a FREE plan of the Codex AI, you can ask 3
            questions per day.
          </h4>
          <h4 className="flex w-2/3 items-end justify-end self-end text-sm font-bold text-brand-500">
            Questions used:{" "}
            <span
              className={`ml-1 ${
                currentGoogleUser.freeQuestions.quantity === 3 && "text-red-600"
              }`}
            >
              {currentGoogleUser.freeQuestions.quantity}/3{" "}
            </span>
            {currentGoogleUser.freeQuestions.time !== 0 ? (
              <>
                <span className="ml-1">since</span>
                <span
                  className={`ml-1 ${
                    currentGoogleUser.freeQuestions.quantity === 3 &&
                    "text-red-600"
                  }`}
                >
                  {getDateStringFromTimestamp(
                    currentGoogleUser.freeQuestions.time
                  )}
                </span>
              </>
            ) : (
              <></>
            )}
          </h4>
          <h4 className="flex w-2/3 items-end justify-end self-end text-sm font-bold text-white">
            <Link
              className="mr-1 text-base font-bold text-branded-900 hover:cursor-pointer"
              to="/mibox/codex-ai-pricing"
            >
              Upgrade
            </Link>{" "}
            to access the Codex AI without restriction.
          </h4>
        </div>
      )}
      {selectedGame.title !== "" ? (
        <>
          <div className="flex flex-row items-center">
            <button
              disabled={!selectedGame}
              className="mb-2.5 mr-1 rounded-xl  bg-brand-400 text-base font-medium text-white  transition duration-200 hover:bg-brand-300 active:bg-brand-200 disabled:cursor-not-allowed disabled:bg-gray-400 sm:col-span-2 md:col-span-1"
              onClick={() => {
                selectGame({ title: "", image: "" });
              }}
            >
              <MdArrowLeft className="h-6 w-6 " />
            </button>
            <h4 className="text-md mb-2.5 p-1 font-bold text-navy-700 dark:text-white">
              Chat with Codex Assistant for{" "}
              <span className="text-brand-300">{selectedGame.title}</span>
            </h4>
          </div>

          <div className="mb-2 grid grid-cols-12">
            <input
              disabled={!selectedGame}
              placeholder="Write your question..."
              ref={inputRef}
              className="col-span-8 mr-1 rounded-xl bg-gray-200 p-2 sm:col-span-8 md:col-span-10"
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  sendQuestion();
                }
              }}
            />
            <button
              disabled={!selectedGame}
              className="col-span-2 mr-1 flex justify-center rounded-xl bg-brand-500 p-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 disabled:cursor-not-allowed disabled:bg-gray-400 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 sm:col-span-2 md:col-span-1"
              onClick={() => {
                sendQuestion();
              }}
            >
              <IoPaperPlane className="h-5 w-5 " />
            </button>
            <button
              key="cleanFilterButton"
              ref={animationParent}
              className="col-span-2 flex justify-center rounded-xl bg-red-500 p-3 text-base font-medium text-white transition duration-200 hover:bg-red-600 sm:col-span-2 md:col-span-1"
              onClick={cleanChat}
            >
              <FiTrash2 className="h-5 w-5" />
            </button>
          </div>
          <div className="mb-2 grid grid-cols-3 gap-5 md:grid-cols-6 xl:grid-cols-6 2xl:grid-cols-6 ">
            <QuickAction
              buttonText="Set Up"
              question="What is the setup for this game?"
              sendQuickAction={sendQuickAction}
              selectedGame={selectedGame}
            />
            <QuickAction
              buttonText="Scoring"
              question="What is the scoring for this game?"
              sendQuickAction={sendQuickAction}
              selectedGame={selectedGame}
            />
            <QuickAction
              buttonText="Overview"
              question="What is the overview for this game?"
              sendQuickAction={sendQuickAction}
              selectedGame={selectedGame}
            />
            <QuickAction
              buttonText="Describe"
              question="How would you describe this game?"
              sendQuickAction={sendQuickAction}
              selectedGame={selectedGame}
            />
            <QuickAction
              buttonText="Players"
              question="Are there any special rules for the number of players?"
              sendQuickAction={sendQuickAction}
              selectedGame={selectedGame}
            />
            <QuickAction
              buttonText="Turns"
              question="How does a turn look like in this game?"
              sendQuickAction={sendQuickAction}
              selectedGame={selectedGame}
            />
          </div>
          <div className="h-[550px] overflow-y-auto rounded-xl">
            <div className="p-2">
              {isLoading && (
                <div className="items-center justify-center text-white">
                  <l-hourglass
                    size="40"
                    bg-opacity="0.1"
                    speed="1.75"
                    color="#21d4fd"
                  ></l-hourglass>
                  Checking the rule book...
                </div>
              )}
              <div className="flex flex-col">{renderTypedMessage()}</div>
              <div className="flex flex-col">{renderMessages()}</div>
            </div>
          </div>
        </>
      ) : (
        <div key={"defaultMessage"}>
          <h4 className="mb-2.5 p-1 text-lg font-bold text-navy-700 dark:text-white">
            Select a game you want to learn more about, or ask questions
            regarding the rulebook.
          </h4>
        </div>
      )}
    </div>
  );
}

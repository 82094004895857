import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface languageState {
  currentLanguage: string;
  currentWishListLanguage: string;
}

const initialState = {
  currentLanguage: localStorage.getItem("language") || "en",
  currentWishListLanguage: localStorage.getItem("languageWishList") || "en",
} as languageState;

const languageSlice = createSlice({
  name: "languageSlice",
  initialState,
  reducers: {
    setCurrentLanguage(state, action: PayloadAction<string>) {
      state.currentLanguage = action.payload;
    },
    setCurrentWishListLanguage(state, action: PayloadAction<string>) {
      state.currentWishListLanguage = action.payload;
    },
  },
});

export const { setCurrentLanguage, setCurrentWishListLanguage } =
  languageSlice.actions;
export default languageSlice.reducer;

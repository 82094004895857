import { LiteYoutubeEmbed } from "react-lite-yt-embed";
import { CodexPricing } from "../codex/components/CodexPricing";

const Pricing = () => {
  return (
    <>
      <p className="mb-4 text-center text-lg font-bold text-white">
        You currently dont have a plan, please subscribe to access the Codex AI
        without restriction
      </p>
      <CodexPricing />
      <p className="mb-4 mt-4 text-center text-lg font-bold text-white">
        Here is a demo of how Codex AI works
      </p>
      <div className="m-auto block w-full text-white">
        <LiteYoutubeEmbed id="a95jBu4V_70" />
      </div>
    </>
  );
};

export default Pricing;

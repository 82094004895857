import axios from "axios";

export const useAssistantOAI = () => {
  const sendMessageToAssistant = (question, threadId, assistantId) => {
    const params = { question, threadId, assistantId };
    return axios
      .post(
        "https://lfqpgtyiugpxzwdkwxkj.supabase.co/functions/v1/codex-assistant",
        params,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + process.env.REACT_APP_SUPABASE_ANON_KEY,
          },
        }
      )
      .then((response) => {
        return {
          responseMessages: response.data.messages,
          responseThreadId: response.data.threadId,
        };
      });
  };

  return {
    sendMessageToAssistant,
  };
};

import {
  addDoc,
  collection,
  doc,
  getDocs,
  onSnapshot,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../firebase/credenciales";
import { setCurrentGoogleUser } from "slices/userSlice";

export default async function addNewUser(objUser) {
  try {
    const collectionRef = collection(db, "users");
    const newUser = await addDoc(collectionRef, objUser);
    return newUser;
  } catch (error) {
    console.log(error);
  }
}

export const findWishListInformation = async (key) => {
  try {
    const collectionRef = collection(db, "users");
    const q = query(collectionRef, where("key_firebase", "==", key));
    const doc_refs = await getDocs(q);
    const res = [];
    doc_refs.forEach((user) => {
      res.push({
        id: user.id,
        ...user.data(),
      });
    });
    return res[0];
  } catch (error) {
    console.log(error);
  }
};

export const suscribeToUsers = async (userInformation, setFunction) => {
  //console.log("userInformation", userInformation);
  const q = query(
    collection(db, "users"),
    where("email", "==", userInformation.email)
  );
  const unsubscribe = onSnapshot(q, (snapshot) => {
    snapshot.docChanges().forEach((change) => {
      if (change.doc.data()) {
        console.log("change", change.doc.data());
        setCurrentGoogleUser(change.doc.data());
        setFunction(change.doc.data().wishlist.games);
      }
    });
  });
  return unsubscribe();
};

export const checkIfUserExists = async (userInformation) => {
  try {
    const collectionRef = collection(db, "users");
    const q = query(collectionRef, where("email", "==", userInformation.email));
    const doc_refs = await getDocs(q);
    const res = [];
    doc_refs.forEach((user) => {
      res.push({
        id: user.id,
        ...user.data(),
      });
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const updateBggUserName = async (newUserName, userObj) => {
  let docId = userObj.key_firebase;
  if (!userObj.key_firebase) {
    const firebaseUsers = await checkIfUserExists(userObj);
    console.log("newUserName", newUserName);
    console.log("docId", firebaseUsers[0].id);
    docId = firebaseUsers[0].id;
  }

  const user = doc(db, "users", docId);
  await updateDoc(user, {
    bgg_username: newUserName,
    key_firebase: docId,
  });

  return true;
};

export const updatePermanentWishlistUrl = async (userObj) => {
  let docId = userObj.key_firebase;
  const user = doc(db, "users", docId);
  await updateDoc(user, {
    permanent_wishlist_url:
      "https://mibox.citadejuegos.com/features/my-wishlist?key=" +
      userObj.key_firebase,
    key_firebase: docId,
  });

  return true;
};

export const updatePreferedLanguage = async (userObj, newPreferedLanguage) => {
  const user = doc(db, "users", userObj.key_firebase);
  await updateDoc(user, {
    prefered_language: newPreferedLanguage,
  });

  return true;
};

export const updateWishlistAndCollection = async (
  userObj,
  newWishlistGames,
  newCollectionGames
) => {
  const user = doc(db, "users", userObj.key_firebase);
  const newWishlist = {
    ...userObj.wishlist,
    lastModified: new Date().getTime(),
    games: newWishlistGames,
  };
  const newCollection = {
    lastModified: new Date().getTime(),
    games: newCollectionGames,
  };
  await updateDoc(user, {
    wishlist: newWishlist,
    collection: newCollection,
  });

  return true;
};
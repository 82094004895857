const FilterButton = ({
  title,
  count,
  collection,
  animationParent,
  revealSection,
  filter,
}) => {
  return (
    <>
      {collection.length > 0 && (
        <div
          className="flex w-full items-center justify-center gap-1"
          ref={animationParent}
        >
          <button
            className="w-full rounded-full bg-branded-900 p-2 text-sm font-bold text-white transition duration-200 hover:bg-amber-600"
            onClick={revealSection}
          >
            {title}
          </button>
          {(filter.length > 0 || filter === true) && (
            <span className="w-fill h-7 rounded-full bg-brand-500 px-3 py-1 text-sm font-normal text-white">
              {count}
            </span>
          )}
        </div>
      )}
    </>
  );
};

export default FilterButton;

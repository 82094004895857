export const parsePlaysElementsToJson = (elements: any) => {
  let json_to_save = [];
  let partidas = 0;
  let horas = 0;
  if (elements) {
    elements.forEach((partida: any) => {
      let juego = json_to_save.find(
        (juego) => juego.gameId === partida.elements[0].attributes.objectid / 1
      );
      if (juego) {
        juego.plays += partida.attributes.quantity / 1;
        juego.horas += partida.attributes.length / 1;
        juego.dates.push(partida.attributes.date);
      } else {
        json_to_save.push({
          gameId: partida.elements[0].attributes.objectid / 1,
          plays: partida.attributes.quantity / 1,
          horas: partida.attributes.length / 1,
          dates: [partida.attributes.date],
        });
      }
      partidas += partida.attributes.quantity / 1;
      horas += partida.attributes.length / 1;
    });
  }

  const return_object = {
    json_to_save,
    partidas,
    horas,
  };
  return return_object;
};

export const parseGameElementsToJson = (elements: any) => {
  let json_to_save = {
    categories: [],
    mechanics: [],
    isExpansion: false,
    publishers: [],
    family: [],
  };

  elements.forEach((partida: any) => {
    if (partida.name && partida.attributes) {
      if (
        partida.name === "link" &&
        partida.attributes.type === "boardgamemechanic"
      ) {
        json_to_save["mechanics"].push(partida.attributes.value);
      } else {
        if (
          partida.name === "link" &&
          partida.attributes.type === "boardgamefamily"
        ) {
          json_to_save["family"].push(partida.attributes.value);
        } else {
          if (
            partida.name === "link" &&
            partida.attributes.type === "boardgamepublisher"
          ) {
            json_to_save["publishers"].push(partida.attributes.value);
          } else {
            if (
              partida.name === "link" &&
              partida.attributes.type === "boardgamecategory"
            ) {
              json_to_save["categories"].push(partida.attributes.value);
            } else {
              if (partida.name === "image" || partida.name === "thumbnail") {
                json_to_save[partida.name] = partida.elements.text;
              } else {
                if (
                  partida.name === "link" &&
                  partida.attributes.type === "boardgameexpansion" &&
                  partida.attributes.inbound === "true"
                ) {
                  json_to_save["isExpansion"] = true;
                } else {
                  if (partida.name === "description") {
                    json_to_save[partida.name] = partida.elements[0].text;
                  } else {
                    if (
                      partida.name === "name" &&
                      partida.attributes.type === "primary"
                    ) {
                      json_to_save[partida.name] = partida.attributes.value;
                    } else {
                      if (partida.name === "statistics") {
                        partida.elements.forEach((partida: any) => {
                          if (partida.name === "ratings") {
                            partida.elements.forEach((partida: any) => {
                              if (partida.name === "ranks") {
                                partida.elements.forEach((partida: any) => {
                                  if (
                                    partida.name === "rank" &&
                                    partida.attributes.name === "boardgame"
                                  ) {
                                    json_to_save["rank"] =
                                      partida.attributes.value / 1;
                                  }
                                  if (
                                    partida.name === "rank" &&
                                    partida.attributes.name ===
                                      "boardgameaccessory"
                                  ) {
                                    json_to_save["isExpansion"] = true;
                                  }
                                });
                              }
                              if (partida.name === "averageweight") {
                                json_to_save["averageweight"] = Math.round(
                                  partida.attributes.value / 1
                                );
                              }
                            });
                          }
                        });
                      } else {
                        if (partida.name !== "name") {
                          if (partida.attributes.value)
                            json_to_save[partida.name] =
                              partida.attributes.value;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    } else {
      if (partida.name === "image" || partida.name === "thumbnail") {
        json_to_save[partida.name] = partida.elements[0].text;
      }
    }
  });

  return json_to_save;
};

export const parseCollectionElementsToJson = (elements: any) => {
  let games_to_save = [];
  elements.forEach((partida: any) => {
    if (!partida.attributes) return;
    let game_to_save = {
      gameId: partida.attributes.objectid / 1,
    };

    partida.elements.forEach((ele: any) => {
      if (ele.elements) {
        if (ele.name !== "stats" && ele.name !== "status") {
          game_to_save[ele.name] = ele.elements[0].text;
        }
        if (ele.name === "stats") {
          ele.elements.forEach((ele2: any) => {
            if (ele2.name === "rating") {
              game_to_save["rating"] =
                ele2.attributes.value === "N/A"
                  ? -1
                  : ele2.attributes.value / 1;
              ele2.elements.forEach((ele3: any) => {
                if (ele3.name === "average") {
                  game_to_save["averageRating"] = ele3.attributes.value / 1;
                }
                if (ele3.name === "ranks") {
                  game_to_save["rank"] =
                    ele3.elements[0].attributes.value === "Not Ranked"
                      ? -1
                      : ele3.elements[0].attributes.value / 1;
                }
              });
            }
          });
          game_to_save["maxPlayers"] = ele.attributes.maxplayers / 1;
          game_to_save["minPlayers"] = ele.attributes.minplayers / 1;
          game_to_save["playingTime"] = ele.attributes.playingtime / 1;
        }
      } else {
        if (ele.name === "status") {
          game_to_save["owned"] = ele.attributes.own === "1" ? true : false;
          game_to_save["wishList"] =
            ele.attributes.wishlist === "1" ? true : false;
          if (ele.attributes.wishlist === "1") {
            game_to_save["wishListPriority"] =
              ele.attributes.wishlistpriority / 1;
          } else {
            game_to_save["wishListPriority"] = -1;
          }
        }
      }
    });

    games_to_save.push(game_to_save);
  });

  return games_to_save;
};

export const parseForumElementsToJson = (elements: any) => {
  let json_to_save = {
    categories: [],
    mechanics: [],
  };

  elements.forEach((partida: any) => {
    if (partida.name && partida.attributes) {
      if (
        partida.name === "link" &&
        partida.attributes.type === "boardgamemechanic"
      ) {
        json_to_save["mechanics"].push(partida.attributes.value);
      } else {
        if (
          partida.name === "link" &&
          partida.attributes.type === "boardgamecategory"
        ) {
          json_to_save["categories"].push(partida.attributes.value);
        } else {
          if (partida.name === "image" || partida.name === "thumbnail") {
            json_to_save[partida.name] = partida.elements.text;
          } else {
            if (partida.name === "description") {
              json_to_save[partida.name] = partida.elements[0].text;
            } else {
              if (
                partida.name === "name" &&
                partida.attributes.type === "primary"
              ) {
                json_to_save[partida.name] = partida.attributes.value;
              } else {
                if (partida.name === "statistics") {
                  partida.elements.forEach((partida: any) => {
                    if (partida.name === "ratings") {
                      partida.elements.forEach((partida: any) => {
                        if (partida.name === "ranks") {
                          partida.elements.forEach((partida: any) => {
                            if (
                              partida.name === "rank" &&
                              partida.attributes.name === "boardgame"
                            ) {
                              json_to_save["rank"] =
                                partida.attributes.value / 1;
                            }
                            if (
                              partida.name === "rank" &&
                              partida.attributes.name === "boardgameaccessory"
                            ) {
                              json_to_save["isExpansion"] = true;
                            }
                          });
                        }
                        if (partida.name === "averageweight") {
                          json_to_save["averageweight"] = Math.round(
                            partida.attributes.value / 1
                          );
                        }
                      });
                    }
                  });
                } else {
                  if (partida.name !== "name")
                    json_to_save[partida.name] = partida.attributes.value;
                }
              }
            }
          }
        }
      }
    }
  });

  return json_to_save;
};

import Dropdown from "components/dropdown";
import { FiAlignJustify, FiLogOut } from "react-icons/fi";
import { Link } from "react-router-dom";
import usaFlag from "assets/img/flags/us.png";
import canFlag from "assets/img/flags/ca.png";
import mxFlag from "assets/img/flags/mx.png";
import frFlag from "assets/img/flags/fr.png";
import "../sidebar/styles.css";
import { useEffect, useState } from "react";
import useTranslation from "utils/hooks/useTranslation";
import LanguageOptions from "components/languageOptions";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { SyncData } from "utils/hooks/syncData";
import { logOut } from "utils/firebase-services/googleAuth";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import Popup from "reactjs-popup";
import { updatePreferedLanguage } from "utils/firebase-services/userService";
import { LazyLoadImage } from "react-lazy-load-image-component";
import bggPlaceHolderImg from "assets/img/loader/placeholder_img.jpg";
import { DEFAULT_USE_AUTO_ANIMATE } from "utils/constants/constants";
import { getDateStringFromTimestamp } from "utils/functions/utils";
import { openSuscribePortal } from "utils/stripe-services/products";
import { GiSpellBook } from "react-icons/gi";
const Navbar = (props: {
  onOpenSidenav: () => void;
  brandText: string;
  secondary?: boolean | string;
}) => {
  const [animationParent] = useAutoAnimate(DEFAULT_USE_AUTO_ANIMATE);
  const { currentGoogleUser, codexSubscription, isLoadingUserData } =
    useSelector((state: RootState) => state.userSlice);

  const { isLoadingCodexAISubscription } = useSelector(
    (state: RootState) => state.codexSlice
  );

  const { setUpFilters } = SyncData();
  const { ownedGames } = useSelector((state: RootState) => state.gamesSlice);
  const { translation, setNewCurrentLanguange } = useTranslation();
  const { onOpenSidenav, brandText } = props;
  const [shouldCloseDropDown, setShouldCloseDropDown] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [shouldLogOut, setShouldLogOut] = useState(false);
  const [showUserNameAndPhoto, setShowUserNameAndPhoto] = useState(false);
  const [isOpeningPortal, setIsOpeningPortal] = useState(false);

  useEffect(() => {
    if (currentGoogleUser) {
      setShowUserNameAndPhoto(true);
    } else {
      setShowUserNameAndPhoto(false);
    }
  }, [currentGoogleUser]);

  useEffect(() => {
    if (!translation) return;
    translation.setLanguage(localStorage.getItem("language") || "en");
    if (ownedGames.length > 0) {
      setUpFilters(ownedGames);
    }
  }, [ownedGames, setUpFilters, translation]);

  useEffect(() => {
    if (shouldLogOut) {
      setShouldLogOut(false);
      logOut();
    } else {
      setOpenModal(false);
    }
  }, [shouldLogOut]);

  const getCurrentLanguage = () => {
    if (!translation) return usaFlag;
    switch (translation.getLanguage()) {
      case "en":
        return usaFlag;
      case "es":
        return mxFlag;
      case "en-can":
        return canFlag;
      case "fr":
        return frFlag;
      default:
        return usaFlag;
    }
  };
  const setNewLanguage = (lang) => {
    if (currentGoogleUser) updatePreferedLanguage(currentGoogleUser, lang);
    setIsOpeningPortal(true);
    setTimeout(() => {
      setIsOpeningPortal(false);
      setNewCurrentLanguange(lang);
    }, 1000);

    setShouldCloseDropDown(true);
  };

  return (
    <nav className="top-4 z-50 flex flex-row flex-wrap items-center justify-between rounded-xl  bg-[#0b14374d] p-2 backdrop-blur-xl">
      <div className="ml-[6px]">
        <p className="shrink text-[22px] capitalize text-white md:text-[33px]">
          <Link to="#" className="capiFstatictalize font-bold hover:text-white">
            {brandText}
          </Link>
        </p>
      </div>

      <div className="w-fill md:w-fill relative z-[9999] mt-[3px] flex h-[61px] items-center justify-around gap-3 rounded-full bg-white px-3 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:flex-grow-0 md:gap-1 xl:gap-4">
        <span
          className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden"
          onClick={onOpenSidenav}
        >
          <FiAlignJustify className="h-7 w-7" />
        </span>
        {!currentGoogleUser && !isLoadingUserData && (
          <Link
            className="flex h-[50px] w-[100px] items-center justify-center gap-2 rounded-xl text-white hover:cursor-pointer hover:bg-branded-900 dark:bg-navy-800"
            to="/mibox/sign-in"
          >
            👋 Sign In
          </Link>
        )}
        {codexSubscription &&
          currentGoogleUser &&
          !isLoadingCodexAISubscription && (
            <div className="flex h-full items-center justify-center rounded-full xl:w-32">
              <div className="mt-2 flex flex-col items-center justify-center text-center">
                <button
                  className="flex rounded-xl bg-brand-400 px-2 py-1 text-sm font-medium capitalize text-white  transition duration-200 hover:bg-brand-300 active:bg-brand-200 disabled:cursor-not-allowed disabled:bg-gray-400"
                  onClick={() => {
                    setIsOpeningPortal(true);
                    openSuscribePortal();
                  }}
                >
                  <GiSpellBook className="mr-1 h-4 w-4" />
                  {codexSubscription.role}
                  <span className="ml-1 hidden md:block">Access </span>
                </button>
                <p className="flex hidden justify-center text-[11px] capitalize text-green-400 md:block">
                  Exp:{" "}
                  {getDateStringFromTimestamp(codexSubscription.expiringDate)}
                </p>
                <p className="flex justify-center text-[10px] capitalize text-green-400 md:hidden">
                  Active
                </p>
              </div>
            </div>
          )}

        {!codexSubscription &&
          currentGoogleUser &&
          !isLoadingCodexAISubscription && (
            <div className="flex h-full items-center justify-center rounded-full xl:w-32">
              <div className="mt-2 flex flex-col items-center justify-center text-center">
                <button
                  disabled={true}
                  className="flex rounded-xl bg-brand-400 px-2 py-1 text-sm font-medium capitalize text-white  transition duration-200 hover:bg-brand-300 active:bg-brand-200 disabled:cursor-not-allowed disabled:bg-gray-400"
                  onClick={() => {
                    setIsOpeningPortal(true);
                    openSuscribePortal();
                  }}
                >
                  <GiSpellBook className=" mr-1 h-4 w-4" />
                  {currentGoogleUser.firebaseRole}
                  <span className="ml-1 hidden md:block">Access </span>
                </button>
                <Link
                  className="mr-1 w-full text-center text-base font-bold text-branded-900 hover:underline"
                  to="/admin/codex-ai-pricing"
                >
                  Upgrade
                </Link>
              </div>
            </div>
          )}
        {(isOpeningPortal || isLoadingCodexAISubscription) && (
          <l-reuleaux
            size="20"
            stroke="5"
            stroke-length="0.15"
            bg-opacity="0.1"
            speed="1.2"
            color="aquamarine"
          ></l-reuleaux>
        )}
        {showUserNameAndPhoto && currentGoogleUser && !isLoadingUserData && (
          <Dropdown
            showCloseDropDown={shouldCloseDropDown}
            button={
              <LazyLoadImage
                wrapperClassName={`!xl:ml-2 h-10 w-10 rounded-full`}
                placeholderSrc={bggPlaceHolderImg}
                src={currentGoogleUser.photoURL}
                className="!xl:ml-2 h-10 w-10 rounded-full"
                alt="User"
                effect="blur"
                onClick={() => setShouldCloseDropDown(false)}
              />
            }
            children={
              <div
                className="flex h-full w-full flex-col rounded-lg shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none"
                ref={animationParent}
              >
                <div
                  className=" flex grid cursor-pointer grid-cols-3 flex-col p-4"
                  onClick={() => setOpenModal(true)}
                >
                  <FiLogOut className="col-span-1 h-5 w-5" />
                  <p className="col-span-2 text-sm font-bold text-navy-700 dark:text-white">
                    Log Out
                  </p>
                </div>
              </div>
            }
            classNames={"py-2 top-8 -left-[70px] w-max"}
          />
        )}
        {isLoadingUserData && (
          <l-reuleaux
            size="20"
            stroke="5"
            stroke-length="0.15"
            bg-opacity="0.1"
            speed="1.2"
            color="orange"
          ></l-reuleaux>
        )}
        <Dropdown
          showCloseDropDown={shouldCloseDropDown}
          button={
            <LazyLoadImage
              wrapperClassName={`!xl:ml-2 h-10 w-10 cursor-pointer rounded-full`}
              placeholderSrc={bggPlaceHolderImg}
              src={getCurrentLanguage()}
              className="!xl:ml-2 h-10 w-10 cursor-pointer rounded-full"
              alt="Language"
              effect="blur"
              onClick={() => setShouldCloseDropDown(false)}
            />
          }
          children={
            <div className="flex h-12 w-40 flex-col items-center  justify-center rounded-[20px] shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
              <LanguageOptions setLanguange={setNewLanguage} />
            </div>
          }
          classNames={"py-2 top-8 -left-[120px] w-max"}
        />
      </div>
      <Popup open={openModal} closeOnDocumentClick>
        <div className="modal flex w-[350px] flex-col rounded-lg bg-navy-700 p-3">
          <div className="header border-grey-500 mb-2.5 flex w-full items-center justify-center border-b-2 p-5 text-2xl font-bold text-navy-700 dark:text-white">
            {translation.logOutPopUpHeader}
          </div>
          <div className="mb-1 w-full items-center justify-center p-4 text-base text-gray-600">
            {translation.logOutPopUpSubTitle}
          </div>
          <div className="actions flex-row gap-3 text-base text-gray-600">
            <button
              className="linear mr-4 mt-1 w-1/3 rounded-xl bg-branded-800 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600  dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              onClick={() => {
                setShouldLogOut(true);
              }}
            >
              {translation.LogOutPopUpConfirm}
            </button>
            <button
              className="linear mt-1 w-1/3 rounded-xl bg-branded-900 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              onClick={() => {
                setOpenModal(false);
              }}
            >
              {translation.LogOutPopUpCancel}
            </button>
          </div>
        </div>
      </Popup>
    </nav>
  );
};

export default Navbar;

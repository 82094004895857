import React from "react";

export const QuickAction = (props: {
  buttonText;
  question;
  sendQuickAction;
  selectedGame;
}) => {
  const { buttonText, question, sendQuickAction, selectedGame } = props;
  return (
    <button
      disabled={!selectedGame}
      className="col-span-1 rounded-xl bg-brand-500 p-2 text-center text-sm font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 disabled:cursor-not-allowed disabled:bg-gray-400 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 sm:col-span-1 md:col-span-2 md:text-base lg:col-span-2 xl:col-span-1"
      onClick={() => {
        sendQuickAction(question);
      }}
    >
      {buttonText}
    </button>
  );
};

import { useState } from "react";
import banner1 from "assets/img/banners/1.png";
import banner2 from "assets/img/banners/2.png";
import banner3 from "assets/img/banners/3.png";
import banner4 from "assets/img/banners/4.png";
import banner5 from "assets/img/banners/5.png";
import banner6 from "assets/img/banners/6.png";
const Banner1 = (props: {
  img?: string;
  showButtons: boolean;
  onClickLink?: string;
}) => {
  const { img, showButtons, onClickLink } = props;

  const [bannerImgAndLink] = useState(Math.floor(Math.random() * 6) + 1);
  const bannerLinks = {
    "1": {
      link: "https://googleads.g.doubleclick.net/pcs/click?xai=AKAOjst4y-8-ioIiYYtMUsv0HtH9ZtYybQ-Uoh-Q9OCc1s_gNHx4EoZXZQ9QLsFP3khUSa6TtPi7EAyBtse0FO3v8aUFxf7SOCdsOrD10dKgPAw21w0LTiii3g2i8msSIPZH_Lsm69OjswxzSeF8veMQq33xMzpE0XkwcwnVikNA9oI0t1QiXPWwy4KweybC_YbKmSyhQ8ezVQ_kD_Zww9uhNsrEAPntxwbUvWWBd_fDQBab_mqO6R4djZ294dh2U1zxVJrVszvvSUXE9PBMAYdder5AB53N5VhPkFTbvTlHV916zeTETIa60pQv8Pu5JDV3npexILhzN9ytNHQn7KecXptkg_N7aQP_OypS6ee7tMoUmrlaHYM9lklGFWSrYXaQvH14TO0&sai=AMfl-YQH4G5th0pVGYZfPKyCMUqUraovpfTX4ctPgJJKwLZqNqtcPqZmhbD05wGU57V6rBU-se8tlEkGSExDBDjoOSvrmC61uimrEGetOrQ55wGYSIm0mretqQsnXPNZdX35-vJUg-I7&sig=Cg0ArKJSzEI-gTej4ma4&fbs_aeid=%5Bgw_fbsaeid%5D&adurl=https://tabletop.events/conventions/bgg-sea-2025-fjords&nm=2&nx=312&ny=-75&mb=2",
      img: banner1,
    },
    "2": {
      link: "https://googleads.g.doubleclick.net/pcs/click?xai=AKAOjsv811HY_a2SXzPjeel-jvWIJqK9LS5-9pbAoSiwhYJF55d4VRwYLqFXmYCDAbZXGs27gr9Bu5C5wo-dYmJOtzDuhkSmDn-qpIoPMIgFRl4m2fitWsCIfFh8Rm9IV9PHsMcOyCDrRE0dog87D9m1DvQibf9DcGe_ZrNlEhner3lOE6SzCv7x0KvvmU9N1dso48sDsRKFx9n6NW8GVCQI5l8VjmBx4npTCmqdPvOS_n6Xp-aP3GNbyapp-l-nC_4Ja3fIiRbWPh3hA5qcCUVMflcmDx6qNpPYjbtYAqham9NBRoaShm3rDEXvfbLYFDq5fXdjI0t4LjDiyWWcKiEdvxEumhGoV4c0plksB6S6IG0-R4UBBzUZaa_W8ujmy0T7zxrIhWNChsRCXw&sai=AMfl-YQVn6vqdhTH-SsDSfic5qaQrOm26Tl12VoGVPjg5Gli8Z8P0HWSC1ZQGZ2vYHFLiiOwEZp1Dpl6gBLzJedTVbyiOgVZhp46fn17svSYROEg3yN5MoKUR_z93Lp8YQR60zE_Cwaa&sig=Cg0ArKJSzIdKw5zbMIDA&fbs_aeid=%5Bgw_fbsaeid%5D&adurl=https://unexpectedgames.com/shop-mandalorian&nm=2&nx=506&ny=-78&mb=2",
      img: banner2,
    },
    "3": {
      link: "https://googleads.g.doubleclick.net/pcs/click?xai=AKAOjstr0Jf0saiVnREk74fsAqukx9_7SzEJKb5Q8bWapqbA4zvVwgnt4Kn0gc8220tWSiXrv7StM2-WBEYotANBymCyF37t1jk2XsexQeRU1x3_uf6BTrj96CK9tTOGJWRvNLIJa5F8ZtAOJ3LKbMgBS_0Orafv8O19og-wlAcJoRZfLwXTk97lfq0CT5OJ715pvV9fySyO_-5h8RTsgJHzTXqu1YJm74OW7Nd0V93DKrWXbxeGS_gGjRKKRdIqIaF0p1227m_reBjYQpixNJcjdmzIfRB5Mmp4RMThZpOf6dY4cCUTmyS_GFK8BUProucYqo8GepIPA4MnAwIZAuDFQgaTbyQ97xfJQUXqkdp3CQrE3m3SSkcS3uCIDGhWbNY7ODqsZkDpFQwjQA&sai=AMfl-YTbt129I2b0REH7hRWM302QE2jZ9Xtt64c-slrOs6wOHoLWlRmLttOvzT3jmq2KUs86FDLOAnrnnNkFaNH-0ZBSnpU0a9RsuRwOBtwISqB3dAv_OYrAuhYroFnpxQQSY7YnytLM&sig=Cg0ArKJSzEp0wJx9_94o&fbs_aeid=%5Bgw_fbsaeid%5D&adurl=https://www.target.com/p/buffalo-games-medical-mysteries/-/A-89243212&nm=2&nx=615&ny=-37&mb=2",
      img: banner3,
    },
    "4": {
      link: "https://googleads.g.doubleclick.net/pcs/click?xai=AKAOjssOYpTQ3tH66NgY9FQI5O_gTFCyfBUQfCVH0pHDd2PQ7az-87P32lCoTh-DLR3O2xKtKevpgBJkZoz1WjskYQbJ5T_8gEOhjF_b8lfj9nMfWWOJ8aqc-czfE-8G_LEYb1UVNzFyWwBmtrZy78eHQs76xWNymo0GUmZb1DBEf4g75eqBcNcuLNHcsVdRJRnOwBH9w5fOKQ7VjLblgNUi4TVN-FgHz8lmOLRvnrt9hDVdZs_BFd1HOcUx2MJsZ9dAqFLywFe_LN0jteBL0ryVcPNKbOoNNHAtCvD7S1p-QYxQisAoShz0iEkFlz0llcX7Ys_vDL5RwYBjk18tHi3zIODyZ5gyWgkuT4jGUTFezKwdcc9pzBkpJKyeedyy-4Cw0cxa4wo&sai=AMfl-YQrWf1BdA8L-iGg_DuGM1QTJfFmuSBK1sTYPhX4IQBTk3TqKEmwsWkE8qaupc7t96JIxtzRw2TfDojvjI6QDWOBEYgIFQUh9wMgN_-UK-hBOeOFni_XKoXXuTeU1t3jZwz9SLEr&sig=Cg0ArKJSzPsH1LrrbUy3&fbs_aeid=%5Bgw_fbsaeid%5D&adurl=https://boardgamegeekstore.com/collections/bgg-geekup-bit-sets%3Faff%3D7%26utm_source%3Dheroremnant%26utm_campaign%3Dgeekupbits&nm=2&nx=579&ny=-6&mb=2",
      img: banner4,
    },
    "5": {
      link: "https://googleads.g.doubleclick.net/pcs/click?xai=AKAOjsuoW0iZ0F3lJCJ3xSJxVlyYDw8mkvUszLW5_fK_RZFHOXvVImzPtWFdMsjqBEoorRvN99Y_wS1dsUPqCYus9j4vfwKeoSRLmVVvoEfkvBFDFFKYFAztr7yFwzAbc72kHL04jCPE05E1UMi9Vxib_IBT0b0OA9MrBqOHcvIVTlGB7lbDmm3WHnyJ2eu1n51o2FyC7xtwKMNA2DvL39D3TRDVWRO7EYurD_dxjW_tF9cxxZQHjR_-bZ5DBwmjXOd160hVjZPPjYqa7VijQY9fUS_UiIadvOMfrGHb2DB6p6rkuaOqjrgYKwf8yLmRTRJDCq5LfvyuajpGR_cY9VHfRYyTHuPQc56Fhk02ktuRr_bOL2CUKPn0GklT9gU9SGlUZXjr5J0&sai=AMfl-YRgcn1Qel6YlmlB87BVXX4OoaYYTJR-8phblFy0j_yALGRLrwGtLceEXqKf1gm7R7BD-pki2ZJb9bMh4miSoXgXpu8vg1HuhPxfgTyV5EiQtRWX-MlPjY71vfcNhZPEfvohqV9F&sig=Cg0ArKJSzAp8_pMt2OrI&fbs_aeid=%5Bgw_fbsaeid%5D&adurl=https://boardgamegeekstore.com/collections/artist-series%3Faff%3D7%26sort_by%3Dcreated-descending%26utm_source%3Dgoogleads%26utm_medium%3Drepeaterremnant%26utm_id%3Dartistseries8&nm=2&nx=502&ny=-54&mb=2",
      img: banner5,
    },
    "6": {
      link: "https://googleads.g.doubleclick.net/pcs/click?xai=AKAOjsutxrx33j-pVmkbefZmTHm7DRUhL12GXjW2vfZTMaPQwgXTG9TH7h-A1IWlLSzES7qSJFAf6ZYRy79I3s_ZNzE7RPchpLhG9oT77uiWLqSDoto-Uio9YtLG2Mfcy5gmZvIzuHU5cWgAmx1MoANtAZY24zFGHUM7hn147h7pi8qpjilaTtbFHtnkSLLN1fK7rTIy-A4bAdNIqDIeiePbOqzFFmY8eGOlliQqqDzSs9bRQ24K08YCS3g6_5DyYwqpaDIosJWBJjmVE1GaiRZU7x3W1NHRELKaDh59o8kjycW6tMC_sZ3qpDO2oh7Kr_u3CMfMr1RQNZ5f-5N_YjfNi2n2eyTUm7XYzh5v2elko8SjLE44q2t-rA3jXhTDkraw5vfPfwo&sai=AMfl-YTxtTBb3qt_EwqbCd3l4pWxgOIQbKG353uR0H0gi9vRfYughOI7FqYrfGSxKh2bJb_naTCzvHKzSUdZBAtCDPzdzTWAEJuAwlC6EYcgVH7HMBJQc_zZefmcMAVGSGQcb1VEK7Vv&sig=Cg0ArKJSzLixycPshXik&fbs_aeid=%5Bgw_fbsaeid%5D&adurl=https://tabletop.events/conventions/bgg.con-2024&nm=4&nx=835&ny=-61&mb=2",
      img: banner6,
    },
  };
  return (
    <a
      href={onClickLink ? onClickLink : bannerLinks[bannerImgAndLink].link}
      target="blank"
      className=" hover:cursor-pointer"
    >
      <div
        className="mt-5 flex h-full flex-col rounded-[20px] bg-cover px-[30px] py-[30px] md:w-full md:px-[64px] md:py-[56px]"
        style={{
          backgroundImage: `url(${
            img ? img : bannerLinks[bannerImgAndLink].img
          })`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        {showButtons && (
          <div className="w-full">
            <h4 className="mb-[14px] max-w-full text-xl font-bold text-white md:w-[64%] md:text-3xl md:leading-[42px] lg:w-[46%] xl:w-[85%] 2xl:w-[75%] 3xl:w-[52%]">
              Discover, collect, and sell extraordinary NFTs
            </h4>
            <p className="mb-[40px] max-w-full text-base font-medium text-[#E3DAFF] md:w-[64%] lg:w-[40%] xl:w-[72%] 2xl:w-[60%] 3xl:w-[45%]">
              Enter in this creative world. Discover now the latest NFTs or
              start creating your own!
            </p>

            <div className="mt-[36px] flex items-center justify-between gap-4 sm:justify-start 2xl:gap-10">
              <button className="text-black linear rounded-xl bg-white px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white/80 active:!bg-white/70">
                Discover now
              </button>
              <button className="text-base font-medium text-lightPrimary hover:text-lightPrimary 2xl:ml-2">
                Watch Video
              </button>
            </div>
          </div>
        )}
      </div>
    </a>
  );
};

export default Banner1;

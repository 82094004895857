export const getUniqueArrayWithElementsCountedComplexity = (array: any) => {
  const uniques = Object.values(
    array.reduce((a, { name, complexity }) => {
      let key = `${name}-${complexity}`;
      a[key] = a[key] || { name, complexity, count: 0 };
      a[key].count++;
      return a;
    }, {})
  );
  return uniques.sort((a: any, b: any) => a.complexity - b.complexity);
};

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const getUniqueArrayWithElementsCounted = (array: any) => {
  const uniques = Object.values(
    array.reduce((a, { name }) => {
      let key = `${name}`;
      a[key] = a[key] || { name, count: 0 };
      a[key].count++;
      return a;
    }, {})
  );

  return uniques;
};

export const getTimingString = (time: number) => {
  let timing;
  switch (true) {
    case time / 1 <= 30:
      timing = "< 30 min";
      break;
    case time > 30 && time / 1 <= 60:
      timing = "1 hr";
      break;
    case time > 60 && time / 1 <= 90:
      timing = "1.5 hrs";
      break;
    case time > 90 && time / 1 <= 120:
      timing = "2 hrs";
      break;
    case time > 120 && time / 1 <= 150:
      timing = "2.5 hrs";
      break;
    case time > 150 && time / 1 <= 180:
      timing = "3 hrs";
      break;
    case time > 180 && time / 1 <= 210:
      timing = "3.5 hrs";
      break;
    case time > 210:
      timing = "4 hrs +";
      break;
  }
  return timing;
};
//getMostRecentDateStringFromDatesArray

export const getLocalizedDateString = (date: string) => {
  let date_ = new Date(date);
  var locale = "es-mx";
  const dateFormated = date_.toLocaleDateString(locale, {
    month: "short",
    year: "2-digit",
    day: "numeric",
  });
  return dateFormated;
};

export const getMostRecentDateString = (dates: string[]) => {
  if (!dates || dates.length === 0) return "";
  let mostRecentDate = new Date(dates[0]);
  dates.forEach((date) => {
    let dateToCompare = new Date(date);
    if (dateToCompare > mostRecentDate) {
      mostRecentDate = dateToCompare;
    }
  });
  var locale = "es-mx";
  const dateFormated = mostRecentDate.toLocaleDateString(locale, {
    month: "short",
    year: "2-digit",
    day: "numeric",
  });
  return dateFormated;
};

export const getMostRecentDate = (dates: string[]) => {
  if (!dates || dates.length === 0) return null;
  let mostRecentDate = new Date(dates[0]);
  dates.forEach((date) => {
    let dateToCompare = new Date(date);
    if (dateToCompare > mostRecentDate) {
      mostRecentDate = dateToCompare;
    }
  });
  console.log("mostRecentDate", mostRecentDate);
  return mostRecentDate;
};

export const getDateString = (
  mostRecentDate: Date,
  weekStr,
  monthStr,
  threeMonhtsStr,
  sixMonthsOrMoreStr
) => {
  let timing = "";
  const today = new Date();
  const diffTime = Math.abs(today.getTime() - mostRecentDate.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  switch (true) {
    case diffDays <= 7:
      timing = weekStr;
      break;
    case diffDays > 7 && diffDays <= 30:
      timing = monthStr;
      break;
    case diffDays > 30 && diffDays <= 90:
      timing = threeMonhtsStr;
      break;
    case diffDays > 90 && diffDays <= 180:
      timing = sixMonthsOrMoreStr;
      break;
  }
  return timing;
};

export const isValidEmail = (email) => {
  const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
  return emailRegex.test(email);
};

export const getImgTagsInDescription = (description) => {
  const regex = /<img.*?src=['"](.*?)['"]/;
  //console.log(regex.exec(description)[1]);
  return regex.exec(description)[1];
};

export const getDateStringFromTimestamp = (timestamp) => {
  let date = new Date(timestamp);
  var locale = "es-mx";
  const dateFormated = date.toLocaleDateString(locale, {
    month: "short",
    year: "2-digit",
    day: "2-digit",
    minute: "numeric",
    hour: "numeric",
  });
  return dateFormated;
};

export const getIfDateIsMoreThanOneHourOld = (timestamp) => {
  const date = new Date(timestamp).getTime();
  const now = new Date().getTime();
  const diffTime = Math.abs(now - date);
  const diffMinutes = Math.ceil(diffTime / (1000 * 60));
  return diffMinutes > 60;
};

export const getIfDateIsMoreThanOneDayOld = (timestamp) => {
  const date = new Date(timestamp).getTime();
  const now = new Date().getTime();
  const diffTime = Math.abs(now - date);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays > 1;
};

export const findGamesIncludingSearchName = (games, searchName) => {
  return games.filter((game) => {
    if (!game.name) return false;
    const namePlusYear = `${game.name} (${game.yearpublished / 1})`;
    return namePlusYear.toLowerCase().includes(searchName.toLowerCase());
  });
};

export const jsonParseLocalStorageKey = (key) => {
  if (!localStorage.getItem(key)) return [];
  return JSON.parse(localStorage.getItem(key));
};
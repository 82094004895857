import InputField from "components/fields/InputField";
import TextareaField from "components/fields/TextAreaField";
import "../../../components/modalPopUp/modalPopUp.css";
import { useState } from "react";
import Popup from "reactjs-popup";
import { FEEDBACK_EMAIL } from "utils/constants/constants";
import useTranslation from "utils/hooks/useTranslation";
import addNewMailFromFeedback, {
  addNewFeedback,
} from "utils/firebase-services/addMailToFirebase";

export default function Feedback() {
  const { translation } = useTranslation();
  const [comments, setComments] = useState("");
  const [optionalEmail, setOptionalEmail] = useState("");

  const [openModal, setOpenModal] = useState(false);
  const closeModal = () => {
    if (comments !== "") {
      setOptionalEmail("");
    }
    setComments("");
    setOpenModal(false);
  };

  const handleSubmit = () => {
    setOpenModal(true);
    if (comments !== "") {
      const newMail = {
        from: "Cita de Juegos <citadejuegos@gmail.com>",
        to: FEEDBACK_EMAIL,
        message: {
          html:
            "Comments: " +
            comments +
            "<br/>By: " +
            (optionalEmail === "" ? "Anonimo" : optionalEmail) +
            "<br/>" +
            new Date().toLocaleString(),
          subject: "New Feedback from Cita de Juegos Website",
        },
      };
      const feedbackRow = {
        comments: comments,
        user: optionalEmail === "" ? "Anonimo" : optionalEmail,
        date: new Date().getTime(),
      };
      addNewMailFromFeedback(newMail);
      addNewFeedback(feedbackRow);
    }
  };
  return (
    <div className="mb-16 mt-1 grid h-full w-full grid-cols-2 items-start justify-start px-2 md:mx-0 md:px-0 lg:mb-1">
      <div className="col-span-2 mt-[5vh] w-full max-w-full flex-col items-center p-4 md:col-span-1">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          {translation.feedBackWelcomeMsg}
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          {translation.feedBackContentMsg}
        </p>

        <TextareaField
          value={comments}
          onChange={(e) => setComments(e.target.value)}
          variant="auth"
          extra=" mb-4"
          label={translation.feedBackLabelMsg}
          placeholder={translation.feedBackPlaceholderMsg}
          id="comments"
        />

        <InputField
          value={optionalEmail}
          onChange={(e) => setOptionalEmail(e.target.value)}
          variant="auth"
          extra="mb-3"
          label="Email"
          placeholder={translation.feedBackEmailPlaceholder}
          id="email"
          type="text"
        />

        <button
          onClick={handleSubmit}
          className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
        >
          {translation.feedBackSubmitMsg}
        </button>
        <Popup open={openModal} closeOnDocumentClick onClose={closeModal}>
          <div className="modal flex w-[250px] flex-col rounded-lg bg-navy-700 p-3">
            <div className="header border-grey-500 mb-2.5 flex w-full items-center justify-center border-b-2 p-5 text-2xl font-bold text-navy-700 dark:text-white">
              {comments !== ""
                ? translation.feedBackThanksMsg
                : translation.feedBackErrorMsg}
            </div>
            <div className="mb-1 w-full items-center justify-center p-4 text-base text-gray-600">
              {comments !== ""
                ? translation.feedBackThanksContentMsg
                : translation.feedBackEmptyCommentsMsg}
            </div>
            <div className="actions text-base text-gray-600">
              <button
                className="linear mt-1 w-2/3 rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                onClick={() => {
                  closeModal();
                }}
              >
                Close
              </button>
            </div>
          </div>
        </Popup>
      </div>
      <div className="col-span-2 mt-[5vh] w-full max-w-full flex-col items-center p-4 md:col-span-1">
        <div className=" w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[620px]">
          <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
            {translation.supportUsHeader}
          </h4>
          <p className="mb-3 ml-1 text-base text-gray-600">
            {translation.supportUsSubHeader}
          </p>
          <p className="mb-3 ml-1 text-base text-white">
            {translation.supportUsSubHeader2}
          </p>
          <p className="mb-3 ml-1 text-base text-gray-600">
            {translation.supportUsSubHeader3}
          </p>
          <p className="mb-3 ml-1 text-base text-white">
            {translation.supportUsSubHeader4}
          </p>
          <p className="mb-3 ml-1 text-base text-gray-600">
            {translation.supportUsListItemOne}
          </p>
          <p className="mb-3 ml-1 text-base text-gray-600">
            {translation.supportUsListItemTwo}
          </p>
          <p className="mb-3 ml-1 text-base text-white">
            {translation.supportUsListItemThree}
          </p>

          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.buymeacoffee.com/citadejuegos"
          >
            <img
              loading="lazy"
              alt="buyMeAnIcecream "
              src="https://img.buymeacoffee.com/button-api/?text=Buy me an Ice cream&emoji=🍦&slug=citadejuegos&button_colour=5F7FFF&font_colour=ffffff&font_family=Cookie&outline_colour=000000&coffee_colour=FFDD00"
            />
          </a>

          <p className="mb-3 ml-1 mt-5 text-base text-gray-600">
            {translation.supportUsThanksMsg}
          </p>
        </div>
      </div>
    </div>
  );
}

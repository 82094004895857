import { updateDoc, doc } from "firebase/firestore";
import { db } from "../../firebase/credenciales";

export const updateShareLinkCollectionInFirebase = async (userObj) => {
  let docId = userObj.key_firebase;
  const user = doc(db, "users", docId);
  await updateDoc(user, {
    collection: userObj.collection,
  });

  return true;
};
